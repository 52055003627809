import { createContext, useEffect, useMemo } from 'react'

import { useGetAcademyUserProfileQuery } from 'myopswat-web/src/api/academy'
import { AcademyProfile, AcademyProfileFields } from 'myopswat-web/src/api/academy/type'

import AcademyPage from '.'

import { useLazySystemValueListQuery } from 'myopswat-web/src/api/system'
import { SYSTEM_VALUE_KEYS } from 'myopswat-web/src/constants'
import './styles.css'

type AcademyContextProps = {
  isLoading?: boolean
  isError?: boolean
  isAcademyStudent: boolean
  academyProfile: AcademyProfile
  ctaConfig: AcademyCTA
}

export type CTAConfigItem = {
  label: string
  url: string
}

export type AcademyCTA = {
  learning: CTAConfigItem
  ongoing: CTAConfigItem
  completeOngoing: CTAConfigItem
  recommended: CTAConfigItem
  completed: CTAConfigItem
}

export type CTAConfig = Record<number, AcademyCTA>

const defaultAcademyProfile: AcademyProfile = {
  userType: 2,
  totalEnrolledCourses: 0,
  totalCompletedCourses: 0,
  totalAvailableCourses: 0,
  isAllRecommendCoursesCompleted: false,
  completedCourses: [],
  ongoingCourses: [],
  recommendCourses: []
}

const defaultCTAConfig: AcademyCTA = {
  learning: { label: '', url: '' },
  ongoing: { label: '', url: '' },
  completeOngoing: { label: '', url: '' },
  recommended: { label: '', url: '' },
  completed: { label: '', url: '' }
}

export const AcademyContext = createContext<AcademyContextProps>({
  isAcademyStudent: false,
  academyProfile: defaultAcademyProfile,
  ctaConfig: defaultCTAConfig
})

const academyStudentUserTypes = new Set([1, 2])

const requiredFieldsByUserType: Record<number, AcademyProfileFields[]> = {
  1: ['ongoingCourses', 'recommendCourses', 'completedCourses'],
  2: ['ongoingCourses', 'recommendCourses', 'completedCourses'],
  3: ['recommendCourses'],
  4: ['recommendCourses']
}

const getCtaConfig = (userType: number, configs: CTAConfig) => {
  return configs[userType] || {}
}

const validateAcademyProfile = (profile: AcademyProfile) => {
  const requiredFields = requiredFieldsByUserType[profile.userType] || []
  return requiredFields.every(field => field in profile && profile[field] != null)
}

const validateAcademyCtaConfig = (analytics: CTAConfig) => {
  return Object.values(analytics).every(config =>
    ['learning', 'ongoing', 'completeOngoing', 'recommended', 'completed'].every(key => key in config)
  )
}

const AcademyProvider = () => {
  const [getSystemValueList, { data: systemValueInfo, isError: systemValueError, isLoading: isSystemValueLoading }] =
    useLazySystemValueListQuery()

  const ctaConfigs: CTAConfig =
    systemValueInfo?.find((item: any) => item.key === SYSTEM_VALUE_KEYS.ACADEMY_CTA_CONFIG)?.data ?? defaultCTAConfig
  const { data: academyProfile, isLoading: isQueryLoading, isError: queryError } = useGetAcademyUserProfileQuery()

  const isAcademyStudent = academyStudentUserTypes.has(academyProfile?.userType ?? 0)
  const isError =
    queryError ||
    systemValueError ||
    !validateAcademyProfile(academyProfile || defaultAcademyProfile) ||
    !validateAcademyCtaConfig(ctaConfigs)
  const isLoading = isQueryLoading || isSystemValueLoading

  useEffect(() => {
    getSystemValueList([SYSTEM_VALUE_KEYS.ACADEMY_CTA_CONFIG])
  }, [])

  const contextValue = useMemo(
    () => ({
      isLoading,
      isError,
      isAcademyStudent,
      academyProfile: academyProfile || defaultAcademyProfile,
      ctaConfig: getCtaConfig(academyProfile?.userType ?? 0, ctaConfigs)
    }),
    [isLoading, isError, isAcademyStudent, academyProfile, ctaConfigs]
  )

  return (
    <AcademyContext.Provider value={contextValue}>
      <AcademyPage />
    </AcademyContext.Provider>
  )
}

export default AcademyProvider
