import { QueryPagination } from '../../types'

export enum FalseSubmissionCategoryEnum {
  false_positive = 'False Positive',
  false_negative = 'False Negative'
}

export enum FalseSubmissionInputTypeEnum {
  file = 'file',
  hash = 'hash'
}

export enum FalseSubmissionValidityEnum {
  valid = 'Valid',
  invalid = 'Invalid',
  none = 'To be determined'
}

export enum FalseSubmissionStatusEnum {
  IN_QUEUE = 'IN QUEUE',
  IN_PROGRESS = 'IN PROGRESS',
  CONFIRMED_MALICIOUS = 'CONFIRMED MALICIOUS',
  CONFIRMED_BENIGN = 'CONFIRMED BENIGN',
  INCONCLUSIVE = 'INCONCLUSIVE',
  REJECTED = 'REJECTED'
}

export const FALSE_SUBMISSION_STATUS_STYLE = {
  'IN_QUEUE': {
    backgroundColor: 'rgba(253, 189, 13, 1)',
    color: 'black'
  },
  'IN_PROGRESS': {
    backgroundColor: 'rgba(29, 107, 252, 1)',
    color: 'white'
  },
  'CONFIRMED_MALICIOUS': {
    backgroundColor: 'rgba(208, 3, 0, 1)',
    color: 'white'
  },
  'CONFIRMED_BENIGN': {
    backgroundColor: 'rgba(0, 138, 0, 1)',
    color: 'white'
  },
  'INCONCLUSIVE': {
    backgroundColor: 'rgba(245, 146, 9, 1)',
    color: 'black'
  },
  'REJECTED': {
    backgroundColor: 'rgba(188, 191, 195, 1)',
    color: 'black'
  }
}

export interface IFalseSubmissionCreateInput {
  note: string
  productIds: string[]
  category: FalseSubmissionCategoryEnum
  md5?: string
  sha1?: string
  sha256?: string
  sha384?: string
  sha512?: string
  fileOrigin: string
  fileOriginDetail?: string
  antivirusEngine: string
  detectionName: string
  purpose: string
  userConsent: boolean
}

export interface IFalseSubmissionFilter {
  q?: string
  categories: string[]
  validities: string[]
  cxStatuses: string[]
  inputTypes: string[]
  all?: boolean
}

export interface IFalseSubmissionFilterInput {
  filters: IFalseSubmissionFilter
  pageInfo: QueryPagination
}

export interface FalseSubmission {
  id: number
  createdAt: Date
  filename: string
  hash: string
  inputType: string
  md5: string
  sha1: string
  sha256: string
  size: number
  category: keyof typeof FalseSubmissionCategoryEnum
  validity: keyof typeof FalseSubmissionValidityEnum
  cxStatus: keyof typeof FalseSubmissionStatusEnum
  analystStatus: keyof typeof FalseSubmissionStatusEnum
  analystNote: string
  note: string
  products: string[]
  fileOrigin: string
  fileOriginDetail: string
  antivirusEngine: string
  detectionName: string
  purpose: string
  userConsent: boolean
  caseNumber?: string
  caseId: string
  publicId: string
}

export interface FalseSubmissionGetUploadUrlInput {
  fileName: string
}

export const FalseSubmissionInputTypes = [
  { name: 'File', value: 'file' },
  { name: 'Hash', value: 'hash' }
]

export const FalseSubmissionCategories = [
  { name: 'False Positive', value: 'false_positive' },
  { name: 'False Negative', value: 'false_negative' }
]

export const FalseSubmissionValidities = [
  { name: 'Valid', value: 'valid' },
  { name: 'Invalid', value: 'invalid' },
  { name: 'To be determined', value: 'none' }
]

export const FalseSubmissionStatuses = [
  { name: 'In Queue', value: 'IN_QUEUE' },
  { name: 'In Progress', value: 'IN_PROGRESS' },
  { name: 'Confirmed Malicious', value: 'CONFIRMED_MALICIOUS' },
  { name: 'Confirmed Benign', value: 'CONFIRMED_BENIGN' },
  { name: 'Inconclusive', value: 'INCONCLUSIVE' },
  { name: 'Rejected', value: 'REJECTED' }
]

export const FalseSubmissionFileOrigins = [
  { name: 'Downloaded from the Internet', value: 'INTERNET_DOWNLOAD' },
  { name: 'Internal file (from your own company)', value: 'INTERNAL_FILE' },
  { name: 'Attachment from unknown/untrusted source', value: 'ATTACHMENT_FROM_UNTRUSTED_SOURCE' },
  { name: 'Received from a partner or trusted vendor (emailed, transferred)', value: 'TRUSTED_VENDOR' }
]
