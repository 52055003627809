import { useContext } from 'react'

import { AcademyOngoingCourse, AcademySection, AcademySwiper, EmptyDataComponent } from '../../components'
import { AcademyContext } from '../../context'

const OngoingCoursesSection = () => {
  const { academyProfile, ctaConfig } = useContext(AcademyContext)
  const { ongoingCourses, totalCompletedCourses, totalEnrolledCourses } = academyProfile

  return (
    <AcademySection title="Ongoing Courses">
      {ongoingCourses.length === 0 ? (
        <EmptyDataComponent
          totalCompletedCourses={totalCompletedCourses}
          totalEnrolledCourses={totalEnrolledCourses}
          ctaConfigs={ctaConfig}
        />
      ) : (
        <AcademySwiper slidesToShow={2} data={ongoingCourses} component={AcademyOngoingCourse} />
      )}
    </AcademySection>
  )
}

export default OngoingCoursesSection
