import _get from 'lodash/get'

import { CSSProperties, FC } from 'react'

import { NotificationInformation } from '@opswat/react-icon'
import { Alert, AlertColor } from '@opswat/react-ui'

import { useDismissBannerMutation } from 'myopswat-web/src/api/account'
import { BannerType } from 'myopswat-web/src/api/account/types'
import {
  addDismissedBanners,
  selectDismissedBanners
} from 'myopswat-web/src/containers/HomeContainer/homeContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

interface IProps {
  type: BannerType
  message: React.ReactNode
  severity?: AlertColor
  hideCloseButton?: boolean
  customStyles?: CSSProperties
  customDisplayCondition?: boolean
  customDismissHandler?: () => void
  iconAlignment?: 'flex-start' | 'center' | 'flex-end'
}

const BoxBannerHome: FC<IProps> = ({
  type,
  message,
  severity,
  hideCloseButton,
  customStyles,
  customDismissHandler,
  customDisplayCondition = false,
  iconAlignment = 'center'
}) => {
  const dispatch = useAppDispatch()
  const dismissedBanners = useTypedSelector(selectDismissedBanners)
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const bannersData = _get(profileData, ['showBanners'], false)

  const showBanner = _get(bannersData, type, customDisplayCondition) && !dismissedBanners.includes(type)

  const [dismissBanner] = useDismissBannerMutation()

  const handleOnDismiss = () => {
    dispatch(addDismissedBanners(type))
    if (customDismissHandler) {
      customDismissHandler()
    } else {
      dismissBanner(type)
    }
  }

  const handleGetBannerColor = (severity: AlertColor) => {
    switch (severity) {
      case 'info':
        return { backgroundColor: '#E9ECF2', iconColor: '#A2B2D2' }
      case 'warning':
        return { backgroundColor: '#FEF7E6', iconColor: '#FDB00D' }
      default:
        return { backgroundColor: '#E9ECF2', iconColor: '#A2B2D2' }
    }
  }

  const { backgroundColor, iconColor } = handleGetBannerColor(severity ?? 'info')

  return (
    <Alert
      severity={severity ?? 'info'}
      onClose={hideCloseButton ? undefined : handleOnDismiss}
      icon={<NotificationInformation color={iconColor} />}
      sx={{
        backgroundColor,
        display: showBanner ? 'flex' : 'none',
        padding: '2px 8px',
        '& .MuiAlert-icon': {
          alignItems: iconAlignment,
          padding: '8px 0px'
        },
        '& .MuiAlert-action': {
          alignItems: iconAlignment,
          paddingY: ['flex-start', 'flex-end'].includes(iconAlignment) ? '8px' : '0px',
          margin: 0,
          paddingLeft: 1
        },
        '& .MuiAlert-message': {
          width: '100%'
        },
        ...customStyles
      }}
    >
      {message}
    </Alert>
  )
}

export default BoxBannerHome
