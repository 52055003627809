import { FC } from 'react'
import _get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { enqueueSnackbar } from 'notistack'

import { ButtonLoading, Grid, TextField, Typography } from '@opswat/react-ui'
import { REGEXS, TEXT_LENGTHS } from '@myopswat/common'
import { useUpdatePartnerNoteMutation } from 'myopswat-web/src/api/license'

interface IProps {
  licenseKey?: string
  onClose: () => void
  content: string
}

const NoteDialog: FC<IProps> = ({ licenseKey, content, onClose }) => {
  const { t } = useTranslation()
  const [updatePartnerNote, { isLoading }] = useUpdatePartnerNoteMutation()

  const schema = yup.object().shape({
    content: yup
      .string()
      .trim()
      .required('Note cannot be blank')
      .max(TEXT_LENGTHS.TEXT_LENGTH_256, 'Note is limited to 256 characters')
      .test('no-html', 'Please do not enter HTML formatted text.', value => !REGEXS.HTML_REGEX.test(value || ''))
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { content: content || '' }
  })

  const onSuccess = async (data: { content: string }) => {
    try {
      await updatePartnerNote({ id: licenseKey ?? '', content: data.content }).unwrap()
      enqueueSnackbar(t('updateLicensesNoteSuccess'), { variant: 'success' })
      onClose()
    } catch (error) {
      console.error(error)
      enqueueSnackbar(t('updateLicensesNoteFail'), { variant: 'error' })
    }
  }

  const handleKeyUp = (e: any) => {
    if (_get(e, 'keyCode') === 13) {
      handleSubmit(onSuccess)()
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          {...register('content')}
          minRows={4}
          maxRows={12}
          multiline
          fullWidth
          sx={{ '& textarea': { resize: 'vertical', overflowX: 'hidden' } }}
          placeholder="Type your note here..."
          size="small"
          error={!!errors.content}
          helperText={errors.content?.message}
          required
          onKeyUp={handleKeyUp}
        />
        <Typography color="textSecondary" variant="body2" sx={{ paddingTop: 2 }}>
          Please do not add any personal information or Confidential information here.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <ButtonLoading propsButton={{ variant: 'text', color: 'inherit', onClick: onClose }} isLoading={false}>
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item>
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSuccess),
                disabled: isLoading || !isDirty || !isValid
              }}
              isLoading={isLoading}
            >
              Save
            </ButtonLoading>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NoteDialog
