import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'
import { IFalseSubmissionCreateInput, IFalseSubmissionFilterInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    falseSubmissions: builder.query<any, IFalseSubmissionFilterInput>({
      query: input => ({
        document: gql`
          query falseSubmissions($filters: FalseSubmissionFiltersInput, $pageInfo: PageInfoType) {
            falseSubmissions(filters: $filters, pageInfo: $pageInfo) {
              totalCount
              results {
                createdAt
                updatedAt
                createdBy
                filename
                hash
                inputType
                md5
                sha1
                sha256
                size
                category
                validity
                cxStatus
                analystNote
                note
                productNames
                caseNumber
                caseId
                publicId
              }
            }
          }
        `,
        variables: input
      })
    }),
    falseSubmissionCreate: builder.mutation<any, IFalseSubmissionCreateInput>({
      query: input => ({
        document: gql`
          mutation ($input: FalseSubmissionCreateInput!) {
            falseSubmissionCreate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    falseDetectionAntivirusEngines: builder.query<any, void>({
      query: () => ({
        document: gql`
          query falseSubmissions {
            antivirusEngines {
              engineName
            }
          }
        `
      })
    }),
    falseSubmissionDetail: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            falseSubmissionDetail(id: "${id}") {
              publicId
              createdAt
              filename
              hash
              inputType
              url
              md5
              sha1
              sha256
              size
              createdByEmail
              category
              cxStatus
              analystNote
              validity
              note
              productNames
              antivirusEngine
              detectionName
              purpose
              userConsent
              updatedAt
              fileOrigin
              fileOriginDetail
              submissionScreenshotUrl
              submissionScreenshotName
            }
          }
        `
      })
    })
  })
})

export const {
  useFalseSubmissionsQuery,
  useFalseDetectionAntivirusEnginesQuery,
  useFalseSubmissionDetailQuery
} = api
