import { gql } from 'graphql-request'
import { apiGraphql } from 'myopswat-web/src/api/base-query'
import {
  INestedOrganizationQuery,
  IOrganizationCreateSubOrganizationInput,
  NestedOrganizationRoleFiltersInput,
  NestedOrganizationUserFilterInput,
  OrganizationAcceptInvitationInput,
  OrganizationActiveUsersInput,
  OrganizationCriticalAlertUserFilterInput,
  OrganizationDeactivateUsersInput,
  OrganizationMfaUpdateInput,
  OrganizationRemoveUserInput,
  OrganizationUpdateInput,
  OrganizationUserFilterInput,
  PortalOrganizationChangeUserRoleInput,
  PortalOrganizationInviteUserInput,
  PortalOrganizationRemoveUserInput,
  PortalTransferSuperAdminInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    usingOrganization: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            usingOrganization {
              id
              name
              ssoId
              ssoMeta
              creator {
                firstName
                lastName
              }
              website
              region
              subRegion
              channelPartner
              currency
              customerSince
              supportLevel
              billingAddress {
                streetAddress1
                streetAddress1
                city
                country
                state
                phone
                postalCode
              }
              shippingAddress {
                streetAddress1
                streetAddress1
                city
                country
                state
                phone
                postalCode
              }
              requiredMfa
              roles
            }
          }
        `
      })
    }),
    organization: builder.query<any, string>({
      query: organizationId => ({
        document: gql`
          query {
            organization(id: "${organizationId}") {
              id
              name
              ssoId
              ssoMeta
              creator {
                firstName
                lastName
              }
              website
              region
              subRegion
              channelPartner
              currency
              customerSince
              supportLevel
              industries
              note
              owner
              billingAddress {
                streetAddress1
                streetAddress2
                city
                country
                state
                phone
                postalCode
              }
              shippingAddress {
                streetAddress1
                streetAddress2
                city
                country
                state
                phone
                postalCode
              }
              requiredMfa
              roles
              isSuperadmin
              isManageSubOrganization
              rank
            }
          }
        `
      })
    }),
    organizationUsers: builder.query<any, OrganizationUserFilterInput>({
      query: organizationFilters => ({
        document: gql`
          query ($organizationFilters: OrganizationUserFiltersInput, $portalRolesFilter: PortalRoleFiltersInput) {
            organizationUsers(filters: $organizationFilters) {
              id
              userId
              userSsoId
              fullName
              email
              userEmail
              isActive
              isSuperadmin
              roleIds
              lastLogin
              applicationRoleIds
              portalRoleIds
            }
            portalRoles(filters: $portalRolesFilter) {
              organizationPortalRoles {
                id
                name
              }
            }
          }
        `,
        variables: {
          organizationFilters: organizationFilters,
          portalRolesFilter: { excludeSuperAdmin: false, checkPermission: false }
        }
      })
    }),
    nestedOrganizationUsers: builder.query<any, NestedOrganizationUserFilterInput>({
      query: nestedOrganizationFilters => ({
        document: gql`
          query (
            $nestedOrganizationFilters: NestedOrganizationUserFiltersInput
            $portalRolesFilter: PortalRoleFiltersInput
          ) {
            nestedOrganizationUsers(filters: $nestedOrganizationFilters) {
              id
              name
              filtered
              permission
              users {
                id
                userId
                userSsoId
                fullName
                email
                userEmail
                isActive
                lastLogin
                portalRoleIds
              }
              children
            }
            portalRoles(filters: $portalRolesFilter) {
              organizationPortalRoles {
                id
                name
              }
            }
          }
        `,
        variables: {
          nestedOrganizationFilters: nestedOrganizationFilters,
          portalRolesFilter: { excludeSuperAdmin: false, checkPermission: false }
        }
      })
    }),
    exportNestedOrganizationUsers: builder.query<any, NestedOrganizationUserFilterInput>({
      query: nestedOrganizationFilters => ({
        document: gql`
          query ($nestedOrganizationFilters: NestedOrganizationUserFiltersInput) {
            nestedOrganizationUsersExcel(filters: $nestedOrganizationFilters) {
              filename
              content
            }
          }
        `,
        variables: {
          nestedOrganizationFilters: nestedOrganizationFilters
        }
      })
    }),
    organizationAcceptInvitation: builder.mutation<any, OrganizationAcceptInvitationInput>({
      query: input => ({
        document: gql`
          mutation organizationAcceptInvitation($input: AcceptInvitationType!) {
            organizationAcceptInvitation(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    portalOrganizationRemoveUser: builder.mutation<any, PortalOrganizationRemoveUserInput>({
      query: input => ({
        document: gql`
          mutation portalOrganizationRemoveUser($input: PortalRemoveUserInput!) {
            portalOrganizationRemoveUser(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationSetSuperAdmin: builder.mutation<any, OrganizationRemoveUserInput>({
      query: input => ({
        document: gql`
          mutation organizationSetSuperAdmin($input: SetSuperAdminInput!) {
            organizationSetSuperAdmin(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationDeactivateUsers: builder.mutation<any, OrganizationDeactivateUsersInput>({
      query: input => ({
        document: gql`
          mutation organizationDeactivateUsers($input: DeactivateUserType!) {
            organizationDeactivateUsers(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationActiveUsers: builder.mutation<any, OrganizationActiveUsersInput>({
      query: input => ({
        document: gql`
          mutation organizationActiveUsers($input: ActiveUserType!) {
            organizationActiveUsers(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    portalOrganizationChangeUserRole: builder.mutation<any, PortalOrganizationChangeUserRoleInput>({
      query: input => ({
        document: gql`
          mutation portalOrganizationChangeUserRole($input: PortalChangeUserRoleInput!) {
            portalOrganizationChangeUserRole(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationUpdate: builder.mutation<any, OrganizationUpdateInput>({
      query: input => ({
        document: gql`
          mutation organizationUpdate($input: OrganizationUpdateInput!) {
            organizationUpdate(input: $input) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationMfaUpdate: builder.mutation<any, OrganizationMfaUpdateInput>({
      query: input => ({
        document: gql`
          mutation organizationMfaUpdate($input: OrganizationMfaUpdateInput!) {
            organizationMfaUpdate(input: $input) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizations: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            organizations {
              id
              name
              parentIds
            }
          }
        `
      })
    }),
    organizationUpdateUsing: builder.mutation<any, string>({
      query: organizationId => ({
        document: gql`
          mutation {
            organizationUpdateUsing(id: "${organizationId}") {
                success
                errors
            }
          }
        `
      })
    }),
    organizationCriticalAlertUsers: builder.query<any, OrganizationCriticalAlertUserFilterInput>({
      query: filters => ({
        document: gql`
          query ($filters: OrganizationCriticalAlertUserFiltersInput!) {
            organizationCriticalAlertUsers(filters: $filters) {
              id
              name
              email
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    }),
    portalOrganizationInviteUser: builder.mutation<any, PortalOrganizationInviteUserInput>({
      query: input => ({
        document: gql`
          mutation portalOrganizationInviteUser($input: PortalInviteUserType!) {
            portalOrganizationInviteUser(input: $input) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    portalOrganizationTransferSuperAdmin: builder.mutation<any, PortalTransferSuperAdminInput>({
      query: input => ({
        document: gql`
          mutation portalOrganizationTransferSuperAdmin($input: PortalTransferSuperAdminInput!) {
            portalOrganizationTransferSuperAdmin(input: $input) {
              success
              errors
              action
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    nestedOrganization: builder.query<INestedOrganizationQuery, string | null>({
      query: id => ({
        document: `
          query {
            nestedOrganization${id ? `(id: "${id}")` : ''} {
              id
              name
              children
            }
          }
        `
      })
    }),
    organizationPortalRoles: builder.query<any, NestedOrganizationRoleFiltersInput>({
      query: ({ filters, sortInfo }) => ({
        document: `
          query(
            $filters: OrganizationPortalRolesFiltersInput
            $sortInfo: SortInfoType
            $portalRolesFilter: PortalRoleFiltersInput) {
              organizationPortalRoles(filters: $filters, sortInfo: $sortInfo) {
                id
                name
                isUserBelonging
                isSelected
                portalRoles
                children
            }
              portalRoles(filters: $portalRolesFilter) {
                organizationPortalRoles {
                  id
                  name
                }
            }
        }
        `,
        variables: {
          filters: filters,
          sortInfo: sortInfo,
          portalRolesFilter: { excludeSuperAdmin: false, checkPermission: false }
        }
      })
    }),
    OrganizationCreateSubOrganization: builder.mutation<any, IOrganizationCreateSubOrganizationInput>({
      query: input => ({
        document: gql`
          mutation organizationCreateSubOrganization($input: OrganizationCreateSubOrganizationInput!) {
            organizationCreateSubOrganization(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    joinSubOrganization: builder.mutation<any, any>({
      query: input => ({
        document: gql`
          mutation portalJoinSubOrganization($input: PortalJoinSubOrganizationInput!) {
            portalJoinSubOrganization(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    })
  })
})

export const {
  useUsingOrganizationQuery,
  useLazyUsingOrganizationQuery,
  useOrganizationUsersQuery,
  useLazyOrganizationUsersQuery,
  useNestedOrganizationUsersQuery,
  useLazyNestedOrganizationUsersQuery,
  useOrganizationAcceptInvitationMutation,
  usePortalOrganizationRemoveUserMutation,
  useOrganizationSetSuperAdminMutation,
  useOrganizationDeactivateUsersMutation,
  useOrganizationActiveUsersMutation,
  usePortalOrganizationChangeUserRoleMutation,
  useOrganizationUpdateMutation,
  useOrganizationQuery,
  useOrganizationsQuery,
  useLazyOrganizationsQuery,
  useOrganizationUpdateUsingMutation,
  useOrganizationMfaUpdateMutation,
  useOrganizationCriticalAlertUsersQuery,
  useLazyOrganizationCriticalAlertUsersQuery,
  usePortalOrganizationInviteUserMutation,
  usePortalOrganizationTransferSuperAdminMutation,
  useLazyNestedOrganizationQuery,
  useOrganizationPortalRolesQuery,
  useLazyOrganizationPortalRolesQuery,
  useOrganizationCreateSubOrganizationMutation,
  useLazyExportNestedOrganizationUsersQuery,
  useJoinSubOrganizationMutation
} = api
