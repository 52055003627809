import { FC } from 'react'

import { Grid } from '@opswat/react-ui'

import SecurityMFA from './SecurityMFA'
import SecurityPassword from './SecurityPassword'
import SecurityPIN from './SecurityPIN'

const Security: FC<unknown> = () => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={10}>
        <SecurityPassword />
      </Grid>
      <Grid item xs={12} md={10}>
        <SecurityMFA />
      </Grid>
      <Grid item xs={12} md={10}>
        <SecurityPIN />
      </Grid>
    </Grid>
  )
}

export default Security
