import _get from 'lodash/get'

import { useCallback, useContext, useEffect, useMemo } from 'react'
import { ControllerRenderProps, useFormState, useWatch } from 'react-hook-form'

import { Autocomplete, Grid, TextField, Typography } from '@opswat/react-ui'

import { useLazySupportCaseProductLicenseKeysQuery } from 'myopswat-web/src/api/support/submit-case'

import SubmitCaseField from '../components/SubmitCaseField'
import { SupportSubmitCaseContext } from '../interface'

const SubmitCaseLicenseKeyStep: React.FC<any> = () => {
  const { control, setValue, trigger } = useContext(SupportSubmitCaseContext)

  const [getProductLicenseKeys, { data: productLicenseKeysData, isFetching: isFetchingProductLicenseKeys }] =
    useLazySupportCaseProductLicenseKeysQuery()

  const subject = useWatch({ control, name: 'subject' })
  const description = useWatch({ control, name: 'description' })
  const preferredPartnerId = useWatch({ control, name: 'preferredPartnerId' })
  const productId = useWatch({ control, name: 'productId' })
  const licenseKey = useWatch({ control, name: 'licenseKey' })
  const productVersion = useWatch({ control, name: 'productVersion' })

  const { errors } = useFormState({ control })

  const licenseKeyOptions = useMemo(
    () =>
      _get(productLicenseKeysData, 'licenseKeys', []).map((item: any) => ({
        label: item.status === 'expired' ? `${item.activationKey} (Expired)` : item.activationKey,
        value: item.activationKey
      })),
    [productLicenseKeysData]
  )

  const productVersionOptions = useMemo(
    () =>
      _get(productLicenseKeysData, 'productReleases', []).map((item: any) => ({
        label: item.semVer,
        value: item.semVer
      })),
    [productLicenseKeysData]
  )

  const renderTextInput = useCallback(
    (props: ControllerRenderProps) => (
      <TextField
        {...props}
        fullWidth
        size="small"
        variant="outlined"
        sx={{
          minHeight: 'auto'
        }}
      />
    ),
    []
  )

  const renderTextArea = useCallback(
    (props: ControllerRenderProps) => (
      <TextField
        {...props}
        fullWidth
        multiline
        minRows={12}
        maxRows={24}
        size="small"
        variant="outlined"
        sx={{
          minHeight: 'auto',
          '& .MuiInputBase-root': {
            padding: 0
          },
          '& .MuiInputBase-input': {
            padding: '8.5px 14px'
          },
          '& textarea': {
            resize: 'vertical',
            overflowX: 'hidden'
          }
        }}
      />
    ),
    []
  )

  const renderAutocomplete = useCallback(
    (props: ControllerRenderProps, options: any[], fieldName: string, placeholder?: string) => (
      <Autocomplete
        {...props}
        freeSolo
        options={options}
        getOptionLabel={(option: any) => _get(option, 'label', option)}
        renderInput={(params: any) => (
          <TextField
            {...params}
            placeholder={placeholder}
            size="small"
            variant="outlined"
            error={!!_get(errors, [fieldName, 'message'], '')}
            helperText={_get(errors, [fieldName, 'message'], '')}
            sx={{
              minHeight: 'auto'
            }}
            onBlur={e => {
              setValue(fieldName, e.target.value)
            }}
          />
        )}
        onChange={(_: any, newValue: any) => {
          props.onChange(newValue)
        }}
      />
    ),
    []
  )

  const LicenseKeyFieldMemo = useMemo(
    () => (
      <SubmitCaseField
        control={control}
        title={'License Key'}
        fieldName={'licenseKey'}
        gridProps={{
          xs: 12,
          md: 6
        }}
        Component={(props: ControllerRenderProps) =>
          renderAutocomplete(props, licenseKeyOptions, 'licenseKey', 'License Key')
        }
        isFetching={isFetchingProductLicenseKeys}
      />
    ),
    [control, licenseKeyOptions, isFetchingProductLicenseKeys]
  )

  const ProductVersionFieldMemo = useMemo(
    () => (
      <SubmitCaseField
        control={control}
        title={'Product Version'}
        fieldName={'productVersion'}
        gridProps={{
          xs: 12,
          md: 6
        }}
        Component={(props: ControllerRenderProps) =>
          renderAutocomplete(props, productVersionOptions, 'productVersion', 'Product Version')
        }
        isFetching={isFetchingProductLicenseKeys}
      />
    ),
    [control, productVersionOptions, isFetchingProductLicenseKeys]
  )

  const SubjectFieldMemo = useMemo(
    () => (
      <SubmitCaseField
        control={control}
        title={'Subject'}
        fieldName={'subject'}
        placeholder="Subject"
        rules={{
          required: 'Subject cannot be blank'
        }}
        Component={renderTextInput}
        maxCharacters={255}
        titleInfo={
          <Typography component="label" variant="caption" color="#707ea4">
            {subject?.trim().length}/255
          </Typography>
        }
      />
    ),
    [control, subject]
  )

  const DescriptionFieldMemo = useMemo(
    () => (
      <SubmitCaseField
        control={control}
        title={'Description'}
        fieldName={'description'}
        placeholder="Provide more details of your issue"
        rules={{
          required: 'Description cannot be blank'
        }}
        Component={renderTextArea}
        maxCharacters={32000}
        titleInfo={
          <Typography component="label" variant="caption" color="#707ea4">
            {description?.trim().length}/32000
          </Typography>
        }
      />
    ),
    [control, description]
  )

  useEffect(() => {
    const org = _get(preferredPartnerId, 'value', preferredPartnerId)
    const product = _get(productId, 'value', productId)

    getProductLicenseKeys({
      orgId: org,
      productId: product
    })
  }, [preferredPartnerId, productId])

  useEffect(() => {
    licenseKey && trigger('licenseKey')
  }, [licenseKey])
  
  useEffect(() => {
    productVersion && trigger('productVersion')
  }, [productVersion])

  return (
    <Grid container rowSpacing={3} columnSpacing={5}>
      {LicenseKeyFieldMemo}
      {ProductVersionFieldMemo}
      {SubjectFieldMemo}
      {DescriptionFieldMemo}
    </Grid>
  )
}

export default SubmitCaseLicenseKeyStep
