import { FC, useState } from 'react'

import _get from 'lodash/get'
import _merge from 'lodash/merge'

import { KEYS, SCHEMA_YUP, handleForceLogout } from '@myopswat/common'
import { EyeHideIcon, EyeIcon } from '@opswat/react-icon'
import { Box, ButtonLoading, Grid, TextField, TextMeter, Typography, TypographyDivider } from '@opswat/react-ui'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { useAccountChangePasswordMutation } from 'myopswat-web/src/api/account'
import { BannerType } from 'myopswat-web/src/api/account/types'
import { useTypedSelector } from 'myopswat-web/src/store'
import BoxBannerHome from '../../HomePage/BoxBannerHome'

interface IForm {
  oldPassword: string
  password: string
  rePassword: string
}

const SecurityPassword: FC<unknown> = () => {
  const isInIframe = typeof window !== 'undefined' && window.self !== window.top

  const { t: translate } = useTranslation()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const passwordRemainingDays = _get(profileData, ['passwordRemainingDays'])

  const [accountChangePassword, { isLoading: isLoadingChangePassword }] = useAccountChangePasswordMutation()

  const defaultValues = {
    oldPassword: '',
    password: '',
    rePassword: ''
  }

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false)
  const handleClickShowOldPassword = () => setShowOldPassword(showOldPassword => !showOldPassword)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const handleClickShowPassword = () => setShowPassword(show => !show)

  const [showRePassword, setShowRePassword] = useState<boolean>(false)
  const handleClickShowRePassword = () => setShowRePassword(showRePassword => !showRePassword)

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  const schema = yup.object().shape(_merge({}, ...[SCHEMA_YUP.oldPassword, SCHEMA_YUP.password, SCHEMA_YUP.rePassword]))

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    trigger
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = async (data: any) => {
    const payload = {
      email: _get(profileData, 'email') || '',
      password: data?.oldPassword || '',
      newPassword: data?.password || ''
    }

    const response = await accountChangePassword(payload).unwrap()

    if (response?.success) {
      enqueueSnackbar(translate('changePasswordSuccess'), {
        variant: 'success'
      })
      if (isInIframe) {
        // post a message for the iframe parent to show the session expired dialog
        window.parent.postMessage({ event: KEYS.IFRAME_USER_FORCE_LOGOUT_SSO }, process.env.REACT_APP_FRONT_END_HOST ?? '')
      } else {
        handleForceLogout()
      }
    } else {
      enqueueSnackbar(_get(response, 'errors.0.message') || translate('changePasswordFail'), {
        variant: 'error'
      })
    }
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  return (
    <Grid container spacing={1.25}>
      <Grid item xs={12}>
        <TypographyDivider label="Password" propsTypo={{ variant: 'subtitle1', color: '#1B273C' }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" color="#485161">
          To reset your password simply enter the current password and then the new password twice.
        </Typography>
      </Grid>

      <Grid item xs={12} md={10} lg={6}>
        <BoxBannerHome
          hideCloseButton
          severity="warning"
          iconAlignment="flex-start"
          type={BannerType.PASSWORD_EXPIRY}
          customDisplayCondition={passwordRemainingDays > 0}
          message={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography variant="body1" color="#3D4A68">
                Time to change your password.
              </Typography>
              <Typography variant="body2" color="#1B273C">
                Your password expires in {passwordRemainingDays} day{passwordRemainingDays > 1 ? 's' : ''}.
              </Typography>
            </Box>
          }
        />
      </Grid>
      <Grid item xs={0} md={2} lg={6}></Grid>

      <Grid item container xs={12} md={10} lg={6} spacing={1.25}>
        {/* current password */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" lineHeight="20px" paddingY={0.5}>
            Current Password
          </Typography>
          <Controller
            name="oldPassword"
            control={control}
            render={(cProps: any) => (
              <TextField
                size="small"
                fullWidth
                type={showOldPassword ? 'text' : 'password'}
                InputProps={{
                  autoComplete: 'off',
                  endAdornment: (
                    <Box
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                    >
                      {showOldPassword ? <EyeIcon /> : <EyeHideIcon />}
                    </Box>
                  )
                }}
                error={!!_get(errors, 'oldPassword', '')}
                helperText={_get(errors, 'oldPassword.message', '')}
                required
                value={cProps.field.value}
                onChange={(e: any) => cProps.field.onChange(e.target.value)}
                onKeyUp={(e: any) => {
                  if (_get(e, 'keyCode') === 13) {
                    handleSubmit(onSuccess, onFail)()
                  }
                  trigger('oldPassword')
                }}
                onBlur={() => {
                  trigger('oldPassword')
                }}
                sx={{
                  minHeight: 'auto'
                }}
              />
            )}
          />
        </Grid>

        {/* new password */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" lineHeight="20px" paddingY={0.5}>
              New Password
            </Typography>
            <TextMeter text={watch('password')} />
          </Box>
          <Controller
            name="password"
            control={control}
            render={(cProps: any) => (
              <TextField
                size="small"
                fullWidth
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  autoComplete: 'off',
                  endAdornment: (
                    <Box
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                    >
                      {showPassword ? <EyeIcon /> : <EyeHideIcon />}
                    </Box>
                  )
                }}
                error={!!_get(errors, 'password', '')}
                helperText={_get(errors, 'password.message', '')}
                required
                value={cProps.field.value}
                onChange={(e: any) => cProps.field.onChange(e.target.value)}
                onKeyUp={(e: any) => {
                  if (_get(e, 'keyCode') === 13) {
                    handleSubmit(onSuccess, onFail)()
                  }
                  trigger('password')
                }}
                onBlur={() => {
                  trigger('password')
                }}
                sx={{
                  minHeight: 'auto'
                }}
              />
            )}
          />
        </Grid>

        {/* confirm password */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" lineHeight="20px" paddingY={0.5}>
            Confirm Password
          </Typography>
          <Controller
            name="rePassword"
            control={control}
            render={(cProps: any) => (
              <TextField
                size="small"
                fullWidth
                type={showRePassword ? 'text' : 'password'}
                InputProps={{
                  autoComplete: 'off',
                  endAdornment: (
                    <Box
                      onClick={handleClickShowRePassword}
                      onMouseDown={handleMouseDownPassword}
                      sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                    >
                      {showRePassword ? <EyeIcon /> : <EyeHideIcon />}
                    </Box>
                  )
                }}
                error={!!_get(errors, 'rePassword', '')}
                helperText={_get(errors, 'rePassword.message', '')}
                required
                value={cProps.field.value}
                onChange={(e: any) => cProps.field.onChange(e.target.value)}
                onKeyUp={(e: any) => {
                  if (_get(e, 'keyCode') === 13) {
                    handleSubmit(onSuccess, onFail)()
                  }
                  trigger('rePassword')
                }}
                onBlur={() => {
                  trigger('rePassword')
                }}
                sx={{
                  minHeight: 'auto'
                }}
              />
            )}
          />
        </Grid>

        {/* submit button */}
        <Grid item xs={12}>
          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              onClick: handleSubmit(onSuccess, onFail),
              disabled:
                (!watch('oldPassword') && !watch('password') && !watch('rePassword')) ||
                isLoadingChangePassword ||
                !isValid,
              disableElevation: true
            }}
            propsLoading={{ color: 'inherit' }}
            isLoading={isLoadingChangePassword}
          >
            Submit
          </ButtonLoading>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SecurityPassword
