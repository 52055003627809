import { CSSProperties, FC } from 'react'

import { Tooltip, Typography, TypographyLineClamp } from '@opswat/react-ui'

interface IProps {
  mainLabel: string
  subLabel: string
  sx?: CSSProperties
}

const TooltipSubOrgLabel: FC<IProps> = ({ mainLabel, subLabel, sx }) => {
  return mainLabel ? (
    <Tooltip title={`${mainLabel} (${subLabel})`}>
      <Typography
        style={{
          wordBreak: 'break-all',
          display: '-webkit-box',
          WebkitLineClamp: 1,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...sx
        }}
      >
        {mainLabel}
        <span>{` (${subLabel?.substring(0, 5)}...)`}</span>
      </Typography>
    </Tooltip>
  ) : (
    <TypographyLineClamp line={1} variant="body2" tooltipValue={subLabel} tooltipPlacement="top" sx={sx}>
      {subLabel}
    </TypographyLineClamp>
  )
}

export default TooltipSubOrgLabel
