import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import _get from 'lodash/get'

import { ClockIcon, ForwardIcon } from '@opswat/react-icon'
import { Box, Button, DialogAdvanced, Grid, Typography } from '@opswat/react-ui'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { supportServicesSubmitCasePageURL } from 'myopswat-web/src/routes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import { DialogSupportEtaConfig, DialogSupportInitResponse } from './interface'
import { openInNewTab } from 'myopswat-web/src/utils'
import { CHATBOT_EFFICIENCY_LEVEL, OPEN_SUPPORT_PLAN_URL } from 'myopswat-web/src/pages/SupportServicesPage/contants'

interface DialogSupportETAProps {
  supportLevel?: string
  supportInitResponse: DialogSupportInitResponse
  supportEtaConfig: DialogSupportEtaConfig
  handleOpenChatbot?: () => void
}

const DialogSupportETA: FC<DialogSupportETAProps> = ({
  supportInitResponse,
  supportEtaConfig,
  supportLevel,
  handleOpenChatbot
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const dialogType = useTypedSelector(selectDialogs)
  const openSurveyDialog = _get(dialogType, DIALOGS_WEB.SUPPORT_ETA, false)

  const openSupportPlanUrl = _get(supportEtaConfig, 'opswat_support_plan_url') ?? OPEN_SUPPORT_PLAN_URL
  const chatbotEfficiencyLevel = _get(supportEtaConfig, 'chatbot_efficiency_level') ?? CHATBOT_EFFICIENCY_LEVEL

  const handleCloseDialog = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS_WEB.SUPPORT_ETA]: false
      })
    )
  }

  const handleOnClickChatbot = () => {
    handleCloseDialog()
    handleOpenChatbot?.()
  }

  const handleGoToSupportUrl = () => {
    handleCloseDialog()
    openInNewTab(openSupportPlanUrl)
  }

  const handleGoToSubmitCase = () => {
    handleCloseDialog()
    navigate(supportServicesSubmitCasePageURL)
  }

  const renderContent = () => {
    return (
      <Grid container py={2.5} minHeight={'250px'}>
        <Box>
          {/* Init Response */}
          <Box sx={{ display: 'flex', gap: 1, marginBottom: '20px' }}>
            <ClockIcon />

            <Box>
              <Typography
                mb={1}
                sx={{
                  fontSize: '14px',
                  lineHeight: '22px',
                  letterSpacing: '0px'
                }}
              >
                If you choose to Submit a case, here is the
                <span style={{ fontWeight: 'bold' }}> Initial Response ETA </span>
                base on your <span style={{ fontWeight: 'bold' }}> {supportLevel || 'Standard'} </span> Plan:
              </Typography>

              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '22px',
                  letterSpacing: '0px'
                }}
              >
                Severity 1: {supportInitResponse.severity_1}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '22px',
                  letterSpacing: '0px'
                }}
              >
                Severity 2: {supportInitResponse.severity_2}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '22px',
                  letterSpacing: '0px'
                }}
              >
                Severity 3: {supportInitResponse.severity_3}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '22px',
                  letterSpacing: '0px'
                }}
              >
                Severity 4: {supportInitResponse.severity_4}
              </Typography>
            </Box>
          </Box>

          {/* Some words */}
          <Box>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '22px',
                letterSpacing: '0px'
              }}
            >
              Experience quicker response time by upgrading your service plan.
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '22px',
                letterSpacing: '0px'
              }}
            >
              Or <span style={{ fontWeight: 'bold' }}> {chatbotEfficiencyLevel}</span>
            </Typography>
          </Box>
        </Box>
      </Grid>
    )
  }

  const renderActions = () => {
    return (
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Button variant="text" color="inherit" onClick={handleGoToSupportUrl} sx={{ fontWeight: 600 }}>
          Learn about Support Plans <ForwardIcon />
        </Button>

        <Box sx={{ width: 'fit-content' }}>
          <Button
            variant="outlined"
            onClick={handleGoToSubmitCase}
            sx={{
              borderColor: '#1B273C',
              '&:hover': {
                borderColor: '#1B273C'
              }
            }}
          >
            <Typography color="#1B273C" textTransform="none">
              Continue to Submit a Case
            </Typography>
          </Button>

          <Button variant="contained" color="primary" onClick={handleOnClickChatbot} sx={{ marginLeft: '10px' }}>
            <Typography color="#fff" textTransform="none">
              Chat with AI Assistant
            </Typography>
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <DialogAdvanced
      open={openSurveyDialog}
      onClose={handleCloseDialog}
      title={
        <Typography
          variant="body2"
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0px'
          }}
        >
          Response ETA Notice
        </Typography>
      }
      content={renderContent()}
      actions={renderActions()}
      dialogProps={{
        maxWidth: 'sm'
      }}
      dialogContentProps={{
        sx: {
          paddingBottom: '0px',
          paddingX: '30px',
          // hide scrollbars
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          },

          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '22px',
          letterSpacing: '0px'
        }
      }}
      dialogActionsProps={{
        sx: {
          paddingX: '20px',
          paddingY: '0px',
          marginY: '16px',

          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '22px',
          letterSpacing: '0px'
        }
      }}
    />
  )
}

export default DialogSupportETA
