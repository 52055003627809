export interface ILoginInput {
  email: string
  password: string
  captchaToken?: string
  totp?: string
  recovery?: string
}

// export interface IRegisterInput {
//   email: string
//   password: string
//   firstName: string
//   lastName: string
//   companyName?: string
//   country?: string
//   captchaToken: string
//   customHost?: string
//   agreed?: boolean
//   consented?: boolean
// }

export interface IVerifyTokenInput {
  token: string
}

export interface IRefreshTokenInput {
  refreshToken: string
  csrfToken: string
}

export interface IChangePasswordInput {
  email: string
  password: string
  newPassword: string
}

export interface IAddress {
  id?: string
  streetAddress1?: string
  streetAddress2?: string
  city?: string
  state?: string
  phone?: string
  country?: string
  postalCode?: string
}

export interface IProfileInput {
  id?: string
  firstName?: string
  lastName?: string
  email?: string
  companyName?: string
  title?: string
  userTypes?: string[] | string
  ssoProfile?: object
  ssoUser?: object
  timezone?: string
  note?: string
  usingOrganizationId?: string
  currentOrganizationId?: string
  accountInfo?: any
  streetAddress1?: string
  streetAddress2?: string
  city?: string
  state?: string
  phone?: string
  country?: string
  postalCode?: string
}

// export interface IResetPasswordInput {
//   email: string
//   customHost?: string
// }

export interface ISubscribedProducts {
  productId: number
  subscribed: boolean
}

export interface ISubscribedEmail {
  consented: boolean
  unsubscribedAll: boolean
  newsletter: boolean | null
  blog: boolean | null
  update: boolean | null
  salesOutreach: boolean | null
  productRelease: boolean | null
  sendAllPurchasedProducts: boolean | null
  academyNewsletter: boolean | null
}

export interface IAccountSettingsUpdateInput {
  languageCode?: string
  timezone?: string
  subscribedProducts?: ISubscribedProducts[]
  subscribedEmail?: ISubscribedEmail
  enabledAi?: boolean
  showWarningSnackbarAt?: string
  isIdaasConsent?: boolean
  showSocSurvey?: boolean
  solutionIds?: number[]
}

// export interface IResetPasswordSubmitInput {
//   email: string
//   code: string
//   password: string
// }

export interface IAccountActivateInput {
  email: string
  code: string
}

export interface IAccountResendInput {
  email: string
  customHost: string
}

export interface IFeedbackInput {
  feedback: string
  recommendProductScore: number | null
  recommendCompanyScore?: number | null
}

export interface IUsingOrganizationInput {
  id: string
  name: string
}

export enum BannerType {
  SECURITY_MFA_HOME = 'security-mfa-home',
  SECURITY_MFA_ORG = 'security-mfa-org',
  PASSWORD_EXPIRY = 'password-expiry'
}

export interface IResetPasswordForm {
  oldPassword: string
  password: string
  rePassword: string
}
