import FalseSubmissionDetailPage from 'myopswat-web/src/pages/ReportFalseDetectionPage/FalseSubmissionDetailPage'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

export default function ReportFalseDetectionDetailContainer() {
  const { submissionId = '' } = useParams()

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {'False Submission Detail'}
        </title>
      </Helmet>
      <FalseSubmissionDetailPage submissionId={submissionId} />
    </>
  )
}
