import { Box, Button, ButtonLoading, Grid, Tooltip } from '@opswat/react-ui'

interface IProps {
  hasExportPermission: boolean
  hasCreateOrganizationPermission: boolean
  hasInviteUserPermission: boolean
  isDisabledCreateSubOrg: boolean
  isFetchingExcelData?: boolean
  onExportCasesToExcel?: () => void
  onCreateSubOrganization: () => void
  onInviteUser: () => void
}

const Actions = ({
  hasExportPermission,
  hasCreateOrganizationPermission,
  hasInviteUserPermission,
  isDisabledCreateSubOrg,
  isFetchingExcelData,
  onExportCasesToExcel,
  onCreateSubOrganization,
  onInviteUser
}: IProps) => {
  return (
    <>
      {hasExportPermission && (
        <Grid item>
          <ButtonLoading
            isLoading={isFetchingExcelData}
            propsButton={{
              variant: 'text',
              color: 'inherit',
              onClick: onExportCasesToExcel,
              disabled: isFetchingExcelData
            }}
          >
            Export Filtered List
          </ButtonLoading>
        </Grid>
      )}

      {hasCreateOrganizationPermission && (
        <Grid item>
          <Tooltip
            title={
              isDisabledCreateSubOrg
                ? 'You can not create any more sub-organizations at this level.To create a new sub-organization, you will need to go up a level.'
                : ''
            }
            disableHoverListener={!isDisabledCreateSubOrg}
          >
            <Box>
              <Button
                variant="outlined"
                color="primary"
                disabled={isDisabledCreateSubOrg}
                onClick={onCreateSubOrganization}
              >
                Create Sub Organization
              </Button>
            </Box>
          </Tooltip>
        </Grid>
      )}

      {hasInviteUserPermission && (
        <Grid item>
          <Button onClick={onInviteUser} variant="contained" color="primary">
            Invite User
          </Button>
        </Grid>
      )}
    </>
  )
}

export default Actions
