import _template from 'lodash/template'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@opswat/react-ui'

import { supportServicesSubmitCasePageURL } from 'myopswat-web/src/routes'

import './chatbot.css'
import { SUPPORT_CHATBOT_HELPER_TEXT } from './constants'

interface IProps {
  defaultShowMessage?: boolean
  submitCaseText?: {
    template: string
    value: string
  }
}

const CustomChatbotHeader: React.FC<IProps> = ({ defaultShowMessage = false, submitCaseText }) => {
  const navigate = useNavigate()

  const [showMessage, setShowMessage] = useState<boolean>(defaultShowMessage)

  const handleRenderMessage = () => {
    const submitCaseTextObject = submitCaseText ?? SUPPORT_CHATBOT_HELPER_TEXT.SUBMIT_CASE
    const compiled = _template('<%= submitCase %>')
    const submitCase = compiled({ submitCase: submitCaseTextObject.value })

    const texts = submitCaseTextObject.template.split('<%= submitCase %>').filter((text: string) => text !== '')
    texts.splice(1, 0, submitCase)

    return (
      <>
        {texts.map(text => {
          if (text !== submitCase) {
            return text
          } else {
            return (
              <Typography
                key={text}
                component="span"
                color="#1D6BFC"
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
                onClick={() => navigate(supportServicesSubmitCasePageURL, { state: { fromChatbot: true } })}
              >
                {submitCase}
              </Typography>
            )
          }
        })}
      </>
    )
  }

  useEffect(() => {
    setShowMessage(defaultShowMessage)
  }, [defaultShowMessage])

  return (
    <Box className={`bpChatbotHeader bpChatbotHeader${showMessage ? 'Expanded' : 'Collapsed'}`}>
      <Typography
        sx={{
          opacity: showMessage ? 1 : 0,
          maxWidth: '85%'
        }}
      >
        {handleRenderMessage()}
      </Typography>
    </Box>
  )
}

export default CustomChatbotHeader
