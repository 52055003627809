import { useEffect, useMemo } from 'react'

import _get from 'lodash/get'

import { Box, Grid, TemplateSection, TypographyDivider } from '@opswat/react-ui'

import { useLazyOrganizationSupportInfoQuery, useLazyTopProductsByTicketQuery } from 'myopswat-web/src/api/support'
import { useLazySystemValueListQuery } from 'myopswat-web/src/api/system'
import { SYSTEM_VALUE_KEYS } from 'myopswat-web/src/constants'
import { useTypedSelector } from 'myopswat-web/src/store'

import { SubscribeAlerts } from './alert'
import { Cases } from './cases'
import { ChatbotSearch } from './chatbot-search'
import { FalseDetection } from './false-detection'
import { SupportLevel } from './level'
import { OtherResources } from './other-resources'
import { PhoneSettings, PhoneSupport } from './phone'
import { TopProduct } from './product'
import { SUPPORT_LEVEL } from './contants'

const SupportServicesPage = () => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [getOrganizationSupportInfo, { data: organizationSupportInfo, isLoading: isLoadingSupportInfo }] =
    useLazyOrganizationSupportInfoQuery()

  const [getTopProductsByTicket, { data: topProducts, isFetching: isFetchingProducts }] =
    useLazyTopProductsByTicketQuery()
  const [getSystemValueList, { data: systemValueInfo }] = useLazySystemValueListQuery()

  useEffect(() => {
    getSystemValueList([SYSTEM_VALUE_KEYS.SUPPORT_SERVICES_PHONE, SYSTEM_VALUE_KEYS.SUPPORT_SUBMIT_CASE_PRODUCTS])
    getTopProductsByTicket(null)
  }, [])

  const phoneSupportList: PhoneSettings | undefined = useMemo(() => {
    return Array.isArray(systemValueInfo)
      ? _get(
          systemValueInfo?.find((item: any) => item.key === SYSTEM_VALUE_KEYS.SUPPORT_SERVICES_PHONE),
          'data'
        )
      : undefined
  }, [systemValueInfo])

  const { currentOrgId, usingOrgId, userEmail } = useMemo(() => {
    return {
      currentOrgId: _get(profileData, 'currentOrganizationId', ''),
      usingOrgId: _get(profileData, 'usingOrganizationId', ''),
      userEmail: _get(profileData, 'email', '')
    }
  }, [profileData])

  const { criticalAlertUsers, supportLevel } = useMemo(() => {
    return {
      criticalAlertUsers: _get(organizationSupportInfo, 'criticalAlertUsers', []),
      supportLevel: _get(organizationSupportInfo, 'supportInfo.support_level') || ''
    }
  }, [organizationSupportInfo])

  const isShowSubmitButton = useMemo(() => {
    // If user does not belong to any organization, do not show the submit button
    if (!_get(profileData, 'currentOrganizationId')) return false

    // If Organization Support Info is not available, do not show the submit button
    if (!organizationSupportInfo) return false

    // If current org has license, show the submit button
    const currentOrgHasLicense = _get(profileData, 'currentOrgHasLicense', false)
    if (currentOrgHasLicense) return true

    // If there is no support level or support level is standard, do not show the submit button
    const supportLevel = _get(organizationSupportInfo, 'supportInfo.support_level')
    if (!supportLevel || supportLevel === SUPPORT_LEVEL.STANDARD) return false

    return true
  }, [profileData, organizationSupportInfo])

  useEffect(() => {
    if (currentOrgId) {
      getOrganizationSupportInfo(currentOrgId)
    }
  }, [currentOrgId])

  return (
    <TemplateSection>
      <Box>
        <TypographyDivider label="Support" />
      </Box>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginY: 2
          }}
        >
          <ChatbotSearch isShowSubmitButton={isShowSubmitButton} orgSupportInfo={organizationSupportInfo} />
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Cases />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sx={{ flex: 1 }}>
                  <TopProduct data={topProducts} isLoading={isFetchingProducts} />
                </Grid>

                <Grid item xs={12} md={8} sx={{ flex: 1 }}>
                  <PhoneSupport
                    supportLevel={supportLevel}
                    isLoading={isLoadingSupportInfo}
                    phoneSettings={phoneSupportList}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SupportLevel
                isLoading={isLoadingSupportInfo}
                profileData={profileData}
                organizationSupportInfo={organizationSupportInfo}
              />
            </Grid>

            <Grid item xs={12}>
              <SubscribeAlerts
                criticalAlertUsers={criticalAlertUsers}
                isLoading={isLoadingSupportInfo}
                orgId={currentOrgId}
                userEmail={userEmail}
                hasOrg={Boolean(currentOrgId || usingOrgId)}
              />
            </Grid>

            <Grid item xs={12}>
              <OtherResources />
            </Grid>

            <Grid item xs={12}>
              <FalseDetection />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default SupportServicesPage
