import { useModal } from '@ebay/nice-modal-react'
import { useEffect, useMemo, useState } from 'react'

import { Box, Skeleton, Switch, Typography } from '@opswat/react-ui'

import { BannerType } from 'myopswat-web/src/api/account/types'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { addDismissedBanners } from 'myopswat-web/src/containers/HomeContainer/homeContainerSlice'
import { toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch } from 'myopswat-web/src/store'

import useHasPermissions from '../../../hooks/useHasPermissions'
import BoxBannerHome from '../../HomePage/BoxBannerHome'
import ConfirmModal from './MFA/ConfirmModal'

interface IProps {
  isLoading: boolean
  organization: any
  afterSave: () => void
  permissions: string[]
}

const OrganizationSecurity = ({ isLoading, organization, afterSave, permissions }: IProps) => {
  const [checkedState, setCheckedState] = useState<boolean>(false)
  const [pendingState, setPendingState] = useState<boolean>(checkedState)

  const hasFullOrgSecurityPerm = useHasPermissions({
    targetPerms: ['full_my_organizations_security'],
    userPerms: permissions
  })

  const dispatch = useAppDispatch()

  // If the user does not have permission to enable MFA,
  // The property 'requiredMfa' will be null, otherwise it be a boolean
  const hasMfaPerm = useMemo(() => organization?.requiredMfa !== null, [organization])

  const mfaConfirmModal = useModal(ConfirmModal)

  const handleChange = (checked: boolean) => {
    setPendingState(!checkedState)
    mfaConfirmModal
      .show({
        organization: organization,
        isEnable: checked
      })
      .then(() => {
        afterSave()
        mfaConfirmModal.hide()
        setCheckedState(pendingState)
        if (checked) {
          dispatch(addDismissedBanners(BannerType.SECURITY_MFA_HOME))
        }
      })
  }

  useEffect(() => {
    // Show permission denied dialog if user does not have permission to enable MFA
    if (!hasMfaPerm) {
      dispatch(
        toggleDialogs({
          [DIALOGS_WEB.PERMISSION_DENIED]: true,
          [DIALOGS_WEB.NPS_NOTIFICATION]: false,
          [DIALOGS_WEB.NPS_SURVEY]: false
        })
      )
    }
  }, [hasMfaPerm])

  useEffect(() => {
    setCheckedState(organization?.requiredMfa || false)
  }, [organization])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5
      }}
    >
      {hasMfaPerm && !organization?.requiredMfa && !isLoading && (
        <BoxBannerHome
          hideCloseButton
          type={BannerType.SECURITY_MFA_ORG}
          message={
            <Typography component="span" variant="body1" color="#3D4A68">
              Prevent 99.9% of account breaches by adding another layer of protection to your organization.
            </Typography>
          }
        />
      )}
      {hasMfaPerm && (
        <Switch
          checked={checkedState}
          disabled={!hasFullOrgSecurityPerm || isLoading}
          label={
            isLoading ? (
              <Skeleton animation="wave" variant="text" width="300px" />
            ) : (
              'Require Multi-factor Authentication for Users'
            )
          }
          handleChange={handleChange}
          sx={{
            marginLeft: 0.25,
            '& .MuiSwitch-track': {
              width: '40px !important',
              height: '20px !important',
              backgroundColor: `${checkedState ? '#1D6BFC' : '#bdbdbd'} !important`,
              opacity: '1 !important',
              borderRadius: '50px !important',
              border: '0px !important',
              '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '40%',
                transform: 'translateY(-50%)',
                width: '12px',
                height: '12px',
                color: '#FFFFF'
              },
              '&::before': {
                left: '12px',
                top: '40%',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`
              },
              '&::after': {
                right: '16px',
                top: '42%',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 16 16"><path xmlns="http://www.w3.org/2000/svg" d="M5 11L11 5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path xmlns="http://www.w3.org/2000/svg" d="M11 11L5 5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>')`
              }
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              width: '15px',
              height: '15px',
              margin: '1px 2px 0px 2px',
              borderRadius: '50px',
              backgroundColor: 'white !important'
            }
          }}
        />
      )}
    </Box>
  )
}

export default OrganizationSecurity
