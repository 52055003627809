import { FC, useCallback, useEffect, useMemo } from 'react'

import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _startsWith from 'lodash/startsWith'

import { useModal } from '@ebay/nice-modal-react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

import {
  AcademyIcon,
  DashboardEngineIcon,
  DownloadIcon,
  LifeSaverIcon,
  OrganizationsIcon,
  PoliciesIcon,
  ScreenIcon,
  SoftwareScriptIcon,
  SolidRightIcon,
  UserConnectionIcon
} from '@opswat/react-icon'
import { AppMenu, Box, LeftMenu, LeftMenuBanner, TemplateDashboard } from '@opswat/react-ui'

import {
  selectPopupMessage,
  setDialogMessage,
  toggleDialogs
} from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import {
  academyPageURL,
  homePageURL,
  licensedProductsMyOrganizationPageUrl,
  licensedProductsPageURL,
  myInformationPageURL,
  myOrganizationCriticalAlertUsersPageURL,
  myOrganizationEventHistoryPageURL,
  myOrganizationGeneralInfoPageURL,
  myOrganizationPageURL,
  myOrganizationRolesPageURL,
  myOrganizationSecurityPageURL,
  myOrganizationUsersPageURL,
  oemPortalURL,
  productDownloadsPageURL
} from 'myopswat-web/src/routes'

import MessageModal from 'myopswat-web/src/components/Dialog/MessageModal'
import { supportServicesPageURL } from 'myopswat-web/src/routes/supportServicesRoutes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import { useLazyCheckShowDialogQuery } from 'myopswat-web/src/api/account'
import BannerHeader from 'myopswat-web/src/components/Banner/BannerHeader'
import DialogSystemNotification from 'myopswat-web/src/components/Dialog/DialogSystemNotification'
import { isEnabledBanner, USER_ACTIVITY } from 'myopswat-web/src/constants'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { useSubmitCaseBlocker } from 'myopswat-web/src/hooks/useSubmitCaseBlocker'
import useUserActivityHook from 'myopswat-web/src/hooks/useUserActivityHook'

import useHasPermissions from '../../hooks/useHasPermissions'
import AllForm from './AllForm'
import LeftMenuInfo from './LeftMenuInfo'
import LogoutLayout from './LogoutLayout'
import RightMenu from './RightMenu'

interface IProps {
  type?: string
}

const LayoutPage: FC<IProps> = ({ type }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const userTypes: string[] = _get(profileData, 'userTypes', [])
  const isChannelPartner = userTypes.includes('CP')

  const isOemCustomer = _get(profileData, 'accountInfo.isOemCustomer', false)
  const isCm8Customer = _get(profileData, 'isCm8Customer', false)

  const message = useTypedSelector(selectPopupMessage)

  const { createUserActivity } = useUserActivityHook()
  const { handleCheckSubmitCase } = useSubmitCaseBlocker()

  const dispatch = useAppDispatch()

  const messageModal = useModal(MessageModal)

  const handleSelectMyOrgPage = () => {
    const defaultPageURL = myOrganizationGeneralInfoPageURL
    const userPortalPermissions = _get(profileData, 'portalPermissions', [])
    const hasViewMyOrgPerm = useHasPermissions({
      targetPerms: ['view_my_organizations', 'full_my_organizations'],
      userPerms: userPortalPermissions
    })
    const hasViewOrgUsersPerm = useHasPermissions({
      targetPerms: ['view_my_organizations_users', 'full_my_organizations_users'],
      userPerms: userPortalPermissions
    })
    const hasViewOrgRolesPerm = useHasPermissions({
      targetPerms: ['view_my_organizations_role', 'full_my_organizations_role'],
      userPerms: userPortalPermissions
    })
    const hasViewOrgSecurityPerm = useHasPermissions({
      targetPerms: ['view_my_organizations_security', 'full_my_organizations_security'],
      userPerms: userPortalPermissions
    })
    const hasViewOrgEventHistoryPerm = useHasPermissions({
      targetPerms: ['view_my_organizations_eventhistory', 'full_my_organizations_eventhistory'],
      userPerms: userPortalPermissions
    })
    const hasViewOrgCritiCalAlertPerm = useHasPermissions({
      targetPerms: ['view_my_organizations_criticalalert', 'full_my_organizations_criticalalert'],
      userPerms: userPortalPermissions
    })
    if (hasViewMyOrgPerm) {
      return defaultPageURL
    } else if (hasViewOrgUsersPerm) {
      return myOrganizationUsersPageURL
    } else if (hasViewOrgRolesPerm) {
      return myOrganizationRolesPageURL
    } else if (hasViewOrgSecurityPerm) {
      return myOrganizationSecurityPageURL
    } else if (hasViewOrgEventHistoryPerm) {
      return myOrganizationEventHistoryPageURL
    } else if (hasViewOrgCritiCalAlertPerm) {
      return myOrganizationCriticalAlertUsersPageURL
    }
    return defaultPageURL
  }

  const [checkShowDialog] = useLazyCheckShowDialogQuery()

  const handleSelectPage = (data: string) => {
    if (!data) return false
    if (location.pathname === oemPortalURL) return data === oemPortalURL
    if (_includes(location.pathname, `${data}`) && _startsWith(location.pathname, `${data}`)) return true
    return false
  }

  const handleRedirectAllbound = useCallback(() => {
    // Record user history
    createUserActivity(USER_ACTIVITY.redirect, { system: 'Allbound' })
    window.open(process.env.REACT_APP_OPSWAT_ALLBOUND_URL, '_blank')
  }, [])

  const handleOnClickOCM = useCallback(async () => {
    await checkShowDialog('ocm_no_org_message').then((response: any) => {
      if (response.data) {
        dispatch(
          toggleDialogs({
            [DIALOGS_WEB.OCM_NO_ORGANIZATION]: true
          })
        )
      } else {
        window.location.href = process.env.REACT_APP_OPSWAT_OC_URL || ''
      }
    })
  }, [])

  const excludePaddingBaseUrls = [homePageURL, myInformationPageURL]

  const excludePaddingExactUrls = [
    academyPageURL,
    myOrganizationGeneralInfoPageURL,
    myOrganizationSecurityPageURL,
    supportServicesPageURL
  ]

  const hasBottomPadding =
    excludePaddingBaseUrls.some((item: string) => location.pathname.startsWith(item)) ||
    excludePaddingExactUrls.includes(location.pathname)

  const arrayData = [
    {
      label: 'Overview',
      path: homePageURL,
      icon: (
        <Box
          sx={{
            width: '18px',
            height: '18px',
            marginLeft: '2px',
            '& svg': {
              width: '18px',
              height: '18px'
            }
          }}
        >
          <ScreenIcon />
        </Box>
      ),
      propListItem: {
        selected: handleSelectPage(homePageURL),
        component: Link,
        to: homePageURL,
        onClick: (e: any) => {
          e.preventDefault()
          handleCheckSubmitCase(() => navigate(homePageURL))
        }
      }
    },
    {
      isHiding: !isCm8Customer,
      label: 'Central Management',
      icon: (
        <Box
          sx={{
            width: '18px',
            height: '18px',
            marginLeft: '2px',
            '& svg': {
              width: '18px !important',
              height: '18px !important'
            }
          }}
        >
          <DashboardEngineIcon />
        </Box>
      ),
      expandIcon: <SolidRightIcon />,
      infoIcon: <LeftMenuInfo title="Central Management Legacy (CM8)" />,
      propListItem: {
        selected: false,
        onClick: () => {
          handleOnClickOCM()
        }
      }
    },
    {
      label: 'Product Downloads',
      path: productDownloadsPageURL,
      icon: <DownloadIcon />,
      propListItem: {
        selected: handleSelectPage(productDownloadsPageURL),
        component: Link,
        to: productDownloadsPageURL,
        onClick: (e: any) => {
          e.preventDefault()
          handleCheckSubmitCase(() => navigate(productDownloadsPageURL))
        }
      }
    },
    {
      label: 'License Management',
      path: licensedProductsMyOrganizationPageUrl,
      icon: <PoliciesIcon />,
      propListItem: {
        selected: handleSelectPage(licensedProductsPageURL),
        component: Link,
        to: licensedProductsMyOrganizationPageUrl,
        onClick: (e: any) => {
          e.preventDefault()
          handleCheckSubmitCase(() => navigate(licensedProductsMyOrganizationPageUrl))
        }
      }
    },
    {
      label: 'OPSWAT Academy',
      path: academyPageURL,
      icon: <AcademyIcon />,
      propListItem: {
        selected: handleSelectPage(academyPageURL),
        component: Link,
        to: academyPageURL,
        onClick: (e: any) => {
          e.preventDefault()
          handleCheckSubmitCase(() => navigate(academyPageURL))
        }
      }
    },
    ...(_get(profileData, 'currentOrganizationId') || _get(profileData, 'usingOrganizationId')
      ? [
          {
            label: 'My Organization',
            path: myOrganizationGeneralInfoPageURL,
            icon: <OrganizationsIcon />,
            propListItem: {
              selected: handleSelectPage(myOrganizationPageURL),
              component: Link,
              to: handleSelectMyOrgPage(),
              onClick: (e: any) => {
                e.preventDefault()
                handleCheckSubmitCase(() => navigate(handleSelectMyOrgPage()))
              }
            }
          }
        ]
      : []),
    {
      label: 'Support',
      path: supportServicesPageURL,
      icon: <LifeSaverIcon />,
      propListItem: {
        selected: handleSelectPage(supportServicesPageURL),
        component: Link,
        to: supportServicesPageURL,
        onClick: (e: any) => {
          e.preventDefault()
          handleCheckSubmitCase(() => navigate(supportServicesPageURL))
        }
      }
    },
    {
      isHiding: !isChannelPartner,
      label: 'OPSWAT Partner Portal',
      icon: (
        <Box
          sx={{
            width: '16px',
            height: '16px',
            marginLeft: '4px',
            '& svg': {
              width: '16px !important',
              height: '16px !important'
            }
          }}
        >
          <UserConnectionIcon size={16} />
        </Box>
      ),
      propListItem: {
        selected: false,
        component: Link,
        to: process.env.REACT_APP_OPSWAT_ALLBOUND_URL,
        onClick: handleRedirectAllbound
      }
    },
    {
      isHiding: !isOemCustomer,
      label: 'OPSWAT OEM Portal',
      icon: (
        <Box
          sx={{
            width: '18px',
            height: '18px',
            marginLeft: '2px',
            '& svg': {
              width: '18px !important',
              height: '18px !important'
            }
          }}
        >
          <SoftwareScriptIcon color="#3D4A68" />
        </Box>
      ),
      propListItem: {
        selected: handleSelectPage(oemPortalURL),
        component: Link,
        to: oemPortalURL,
        onClick: (e: any) => {
          e.preventDefault()
          handleCheckSubmitCase(() => navigate(oemPortalURL))
        }
      }
    }
  ]

  const menuList = useMemo(() => _filter(arrayData, item => !item?.isHiding), [arrayData])

  const handleRenderLayout = () => {
    if (isEnabledBanner)
      return (
        <TemplateDashboard
          top={
            <>
              <Box
                sx={{
                  position: 'fixed',
                  zIndex: 1201,
                  top: 0,
                  p: 0,
                  width: '100%'
                }}
              >
                <BannerHeader />
              </Box>

              <AppMenu
                sx={{
                  top: 50
                }}
              >
                <RightMenu />
              </AppMenu>
            </>
          }
          left={
            <LeftMenuBanner
              isLogo
              isIconCollapse
              menuList={menuList}
              content={
                <Box
                  sx={{
                    marginBottom: hasBottomPadding ? 0 : 9
                  }}
                >
                  <Outlet />
                </Box>
              }
            />
          }
        />
      )

    return (
      <TemplateDashboard
        top={
          <AppMenu>
            <RightMenu />
          </AppMenu>
        }
        left={
          <LeftMenu
            isLogo
            isIconCollapse
            menuList={menuList}
            content={
              <Box sx={{ marginBottom: hasBottomPadding ? 0 : 9 }}>
                <Outlet />
              </Box>
            }
          />
        }
      />
    )
  }

  const handleRenderFormLayout = () => {
    if (type === 'LOGOUT') {
      return <LogoutLayout />
    } else {
      return <AllForm />
    }
  }

  useEffect(() => {
    if (message) {
      messageModal.show({ message: message })
      dispatch(setDialogMessage(''))
    }
  }, [message])

  const systemNotification = useMemo(() => {
    return _get(profileData, 'systemNotification')
  }, [profileData])

  return (
    <>
      {handleRenderLayout()}
      {handleRenderFormLayout()}
      {systemNotification != null && <DialogSystemNotification data={systemNotification} />}
    </>
  )
}

export default LayoutPage
