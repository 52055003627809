import { useCallback } from 'react'

import { Button, Grid, Typography } from '@opswat/react-ui'

import { useUserProfile } from 'myopswat-web/src/hooks'
import { openInNewTab } from 'myopswat-web/src/utils'

import { backgroundSvg } from '../../constants'

interface WelcomeProps {
  url?: string
  label?: string
}

const Welcome = ({ url = 'https://learn.opswatacademy.com/login', label = 'Start Learning' }: WelcomeProps) => {
  const { getProfileProperty } = useUserProfile()
  const firstName = getProfileProperty('firstName', '')

  const handleButtonClick = useCallback(() => {
    openInNewTab(url)
  }, [])

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: '100%',
        minHeight: '150px',
        backgroundImage: `url("data:image/svg+xml,${backgroundSvg}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '30px 20px'
      }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <Typography
          color={'#FFF'}
          sx={{
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '24px',
            textTransform: 'capitalize'
          }}
        >
          {`Welcome ${firstName}`}
        </Typography>
        <Typography variant="body2" color={'#FFF'} textAlign="center">
          Invest in you training today!
        </Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={handleButtonClick}>
          {label}
        </Button>
      </Grid>
    </Grid>
  )
}

export default Welcome
