import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import { DownloadIcon, ExternalLinkIcon, LinkedIcon } from '@opswat/react-icon'
import { Button, TableCell } from '@opswat/react-ui'

import { LEARN_MORE_PRODUCT_IDS, PRODUCT_LINKS } from 'myopswat-web/src/constants/product-ids'
import { homePageURL } from 'myopswat-web/src/routes'

interface ActionCellProps {
  data: any
  level: number
  onClick: () => void
}

interface ButtonProps {
  startIcon: JSX.Element | null
  variant: 'text' | 'outlined' | 'contained'
  ariaLabel: string
  text: string
  textColor?: string
  border?: string
}

const BUTTON_PROPS_MAP: Record<ButtonType, ButtonProps> = {
  link: {
    startIcon: <ExternalLinkIcon color="#1D6BFC" />,
    variant: 'text',
    ariaLabel: 'Open link',
    text: 'View Link'
  },
  learnMore: {
    startIcon: <LinkedIcon color="#3D4A68" />,
    variant: 'outlined',
    ariaLabel: 'Learn more',
    text: 'Learn more',
    textColor: '#3D4A68',
    border: '1px solid #3D4A68'
  },
  download: {
    startIcon: <DownloadIcon color="#FFFFFF" />,
    variant: 'contained',
    ariaLabel: 'Download',
    text: 'Download'
  }
}

type ButtonType = 'link' | 'learnMore' | 'download'

const getButtonType = (data: any): ButtonType => {
  if (data.id in PRODUCT_LINKS) return 'link'
  if (LEARN_MORE_PRODUCT_IDS.includes(data.id)) return 'learnMore'
  return 'download'
}

const handleButtonClick = (buttonType: ButtonType, onClick: () => void, data: any, navigate: any) => {
  switch (buttonType) {
    case 'link':
      window.open(PRODUCT_LINKS[data.id], '_blank')
      break
    case 'learnMore':
      navigate(`${homePageURL}/${data.slug}`)
      break
    case 'download':
      onClick()
      break
    default:
      break
  }
}

export const ActionCell = ({ data, level, onClick }: ActionCellProps) => {
  const navigate = useNavigate()

  const buttonType = getButtonType(data)
  const buttonProps = BUTTON_PROPS_MAP[buttonType]

  const handleClick = useCallback(() => {
    handleButtonClick(buttonType, onClick, data, navigate)
  }, [buttonType, onClick, data, navigate])

  return (
    <TableCell
      sx={{
        padding: 0.25,
        minWidth: 150,
        textAlign: 'right'
      }}
    >
      {level === 0 ? (
        <Button
          startIcon={buttonProps.startIcon}
          variant={buttonProps.variant}
          color="primary"
          onClick={handleClick}
          aria-label={buttonProps.ariaLabel}
          sx={{
            minWidth: 128,
            maxHeight: 4,
            color: buttonProps.textColor,
            border: buttonProps.border,
            justifyContent: 'flex-start',
            padding: '5px 15px'
          }}
        >
          {buttonProps.text}
        </Button>
      ) : (
        <Button variant="contained" disabled sx={{ visibility: 'hidden' }} aria-hidden="true">
          Hidden
        </Button>
      )}
    </TableCell>
  )
}
