import _get from 'lodash/get'
import _merge from 'lodash/merge'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { KEYS, SCHEMA_YUP, handleForceLogout } from '@myopswat/common'
import { Box, ButtonLoading, DialogAdvanced, Grid, Typography, TypographyLineClamp } from '@opswat/react-ui'

import { useAccountChangePasswordMutation } from 'myopswat-web/src/api/account'
import { IResetPasswordForm } from 'myopswat-web/src/api/account/types'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { selectDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useTypedSelector } from 'myopswat-web/src/store'

import ResetPasswordForm from '../Form/ResetPasswordForm'

const DialogResetPassword = () => {
  const isInIframe = typeof window !== 'undefined' && window.self !== window.top

  const { t: translate } = useTranslation()
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const dialogType = useTypedSelector(selectDialogs)

  const [accountChangePassword, { isLoading: isChangingPassword }] = useAccountChangePasswordMutation()

  const defaultValues = {
    oldPassword: '',
    password: '',
    rePassword: ''
  }

  const schema = yup.object().shape(_merge({}, ...[SCHEMA_YUP.oldPassword, SCHEMA_YUP.password, SCHEMA_YUP.rePassword]))

  const formRef = useForm<IResetPasswordForm>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = async (data: any) => {
    const payload = {
      email: _get(profileData, 'email') || '',
      password: data?.oldPassword || '',
      newPassword: data?.password || ''
    }

    accountChangePassword(payload)
      .unwrap()
      .then((response: any) => {
        if (response?.success) {
          enqueueSnackbar(translate('changePasswordSuccess'), {
            variant: 'success'
          })
          if (isInIframe) {
            // post a message for the iframe parent to show the session expired dialog
            window.parent.postMessage(
              { event: KEYS.IFRAME_USER_FORCE_LOGOUT_SSO },
              process.env.REACT_APP_FRONT_END_HOST ?? ''
            )
          } else {
            handleForceLogout()
          }
        } else {
          enqueueSnackbar(_get(response, 'errors.0.message') || translate('changePasswordFail'), {
            variant: 'error'
          })
        }
      })
      .catch(() => {
        enqueueSnackbar(translate('changePasswordFail'), {
          variant: 'error'
        })
      })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS_WEB.FORCE_RESET_PASSWORD, false)}
      title={
        <TypographyLineClamp line={1} sx={{ fontSize: '16px', lineHeight: '20px', fontWeight: 500 }}>
          Change Password
        </TypographyLineClamp>
      }
      content={
        <Grid container spacing={2.5}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Your password has expired. Please change your password and sign in again.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ResetPasswordForm formRef={formRef} onSuccess={onSuccess} onFail={onFail} />
          </Grid>
        </Grid>
      }
      actions={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              disableElevation: true,
              disabled: !formRef.formState.isValid || isChangingPassword,
              onClick: formRef.handleSubmit(onSuccess, onFail)
            }}
            isLoading={isChangingPassword}
            propsLoading={{ color: 'inherit' }}
          >
            Change Password
          </ButtonLoading>
        </Box>
      }
      dialogProps={{
        sx: {
          '& .MuiPaper-root': {
            maxWidth: '500px'
          }
        }
      }}
      dialogTitleProps={{
        sx: {
          marginInline: '24px',
          paddingY: '20px',
          paddingX: '0px'
        }
      }}
      dialogContentProps={{
        sx: {
          padding: '0px 24px'
        }
      }}
    />
  )
}

export default DialogResetPassword
