import { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import _get from 'lodash/get'

import { EyeHideIcon, EyeIcon } from '@opswat/react-icon'
import { Box, Grid, TextField, TextMeter, Typography } from '@opswat/react-ui'

import { IResetPasswordForm } from 'myopswat-web/src/api/account/types'

interface IProps {
  formRef: UseFormReturn<IResetPasswordForm>
  onSuccess: (data: IResetPasswordForm) => void
  onFail: (e: any) => void
}

const ResetPasswordForm = ({ formRef, onSuccess, onFail }: IProps) => {
  const {
    control,
    watch,
    trigger,
    handleSubmit,
    formState: { errors }
  } = formRef

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false)
  const handleClickShowOldPassword = () => setShowOldPassword(showOldPassword => !showOldPassword)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const handleClickShowPassword = () => setShowPassword(show => !show)

  const [showRePassword, setShowRePassword] = useState<boolean>(false)
  const handleClickShowRePassword = () => setShowRePassword(showRePassword => !showRePassword)

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  return (
    <Grid item container xs={12} spacing={2.5}>
      {/* current password */}
      <Grid item xs={12}>
        <Typography variant="subtitle1" lineHeight="20px" paddingY={0.5}>
          Current Password
        </Typography>
        <Controller
          name="oldPassword"
          control={control}
          render={(cProps: any) => (
            <TextField
              size="small"
              fullWidth
              type={showOldPassword ? 'text' : 'password'}
              InputProps={{
                autoComplete: 'off',
                endAdornment: (
                  <Box
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                  >
                    {showOldPassword ? <EyeIcon /> : <EyeHideIcon />}
                  </Box>
                )
              }}
              error={!!_get(errors, 'oldPassword', '')}
              helperText={_get(errors, 'oldPassword.message', '')}
              required
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onKeyUp={(e: any) => {
                if (_get(e, 'keyCode') === 13) {
                  handleSubmit(onSuccess, onFail)()
                }
                trigger('oldPassword')
              }}
              onBlur={() => {
                trigger('oldPassword')
              }}
              sx={{
                minHeight: 'auto'
              }}
            />
          )}
        />
      </Grid>

      {/* new password */}
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" lineHeight="20px" paddingY={0.5}>
            New Password
          </Typography>
          <TextMeter text={watch('password')} />
        </Box>
        <Controller
          name="password"
          control={control}
          render={(cProps: any) => (
            <TextField
              size="small"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                autoComplete: 'off',
                endAdornment: (
                  <Box
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                  >
                    {showPassword ? <EyeIcon /> : <EyeHideIcon />}
                  </Box>
                )
              }}
              error={!!_get(errors, 'password', '')}
              helperText={_get(errors, 'password.message', '')}
              required
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onKeyUp={(e: any) => {
                if (_get(e, 'keyCode') === 13) {
                  handleSubmit(onSuccess, onFail)()
                }
                trigger('password')
              }}
              onBlur={() => {
                trigger('password')
              }}
              sx={{
                minHeight: 'auto'
              }}
            />
          )}
        />
      </Grid>

      {/* confirm password */}
      <Grid item xs={12}>
        <Typography variant="subtitle1" lineHeight="20px" paddingY={0.5}>
          Confirm Password
        </Typography>
        <Controller
          name="rePassword"
          control={control}
          render={(cProps: any) => (
            <TextField
              size="small"
              fullWidth
              type={showRePassword ? 'text' : 'password'}
              InputProps={{
                autoComplete: 'off',
                endAdornment: (
                  <Box
                    onClick={handleClickShowRePassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                  >
                    {showRePassword ? <EyeIcon /> : <EyeHideIcon />}
                  </Box>
                )
              }}
              error={!!_get(errors, 'rePassword', '')}
              helperText={_get(errors, 'rePassword.message', '')}
              required
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onKeyUp={(e: any) => {
                if (_get(e, 'keyCode') === 13) {
                  handleSubmit(onSuccess, onFail)()
                }
                trigger('rePassword')
              }}
              onBlur={() => {
                trigger('rePassword')
              }}
              sx={{
                minHeight: 'auto'
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default ResetPasswordForm
