import { useCallback, useEffect, useMemo, useState } from 'react'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { ExternalLinkIcon, StarIcon } from '@opswat/react-icon'
import {
  Box,
  Button,
  Grid,
  TableAdvanced,
  Typography,
  TypographyLineClamp,
  TypographyLinkClamp
} from '@opswat/react-ui'
import _get from 'lodash/get'
import _range from 'lodash/range'

import { useLazyOrganizationCasesV2Query } from 'myopswat-web/src/api/support'
import { ISupportCaseV2Filters } from 'myopswat-web/src/api/support/types'
import CircularLoader from 'myopswat-web/src/assets/images/circular-loader.svg'
import DialogCSAT from 'myopswat-web/src/components/Dialog/DialogCSAT'
import { PAGE_DEFAULT, PAGE_SIZES_DEFAULT } from 'myopswat-web/src/constants'
import { useDialog } from 'myopswat-web/src/hooks/useDialog'
import { useUserProfile } from 'myopswat-web/src/hooks/useProfileData'
import { supportServicesPageAllCasesURL } from 'myopswat-web/src/routes'
import { useNavigate } from 'react-router-dom'
import { SupportCaseTypes } from '../constant'
import DialogFalseSubmissionDetails from '../dialogs/DialogFalseSubmissionDetail'

interface IProps {
  isMiniTable?: boolean
  activeTab: number
  filters: ISupportCaseV2Filters
}

const SupportServiceCaseTable = ({ isMiniTable, activeTab, filters }: IProps) => {
  const navigate = useNavigate()
  const { getProfileProperty } = useUserProfile()
  const userContactId = getProfileProperty('sfdcContactId', '')

  const { isOpen, openDialog, closeDialog } = useDialog()
  const [selectedCase, setSelectedCase] = useState<any>({})
  const [selectedFalseDetection, setSelectedFalseDetection] = useState<any>(undefined)
  const [openDetailDialog, setOpenDetailDialog] = useState<boolean>(false)


  const [getSupportCases, { data: casesData, isFetching }] = useLazyOrganizationCasesV2Query()

  const DEFAULT_PAGE_INFO = useMemo(() => {
    return {
      page: 0,
      pageSize: isMiniTable ? 5 : 25
    }
  }, [isMiniTable])

  const [pageInfo, setPageInfo] = useState(DEFAULT_PAGE_INFO)

  const caseType = useMemo(() => _get(filters, 'caseType'), [filters])

  const handleGetSupportCases = async () => {
    try {
      if (!_get(filters, 'caseType')) return

      await getSupportCases({
        pageInfo,
        filters
      }).unwrap()
    } catch (err) {
      console.error(err)
    }
  }

  const handlePagination = useCallback(
    (pageNumber: number, pageSize: number) => {
      if (pageSize !== pageInfo.pageSize) {
        pageNumber = PAGE_DEFAULT
      }
      setPageInfo({ page: pageNumber, pageSize })
    },
    [pageInfo]
  )

  const getColumnStyles = useCallback((widthPercent: number) => {
    return {
      minWidth: `${widthPercent}vw`,
      width: `${widthPercent}vw`
    }
  }, [])

  const getColumnValueAsLink = useCallback(
    (data: any, field: string) => (
      <TypographyLinkClamp
        line={1}
        color="primary"
        fontWeight="400"
        underline="hover"
        href={`${process.env.REACT_APP_OPSWAT_GO_URL}/s/case/${data.id}`}
        typographyProps={{
          tooltipValue: _get(data, field, '--'),
          tooltipPlacement: 'top'
        }}
      >
        {_get(data, field, '--')}
      </TypographyLinkClamp>
    ),
    []
  )

  const getColumnValue = useCallback((data: any, field: string) => {
    const value = _get(data, field) || '--'
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={value} tooltipPlacement="top">
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const getDateColumn = useCallback((data: any, field: string) => {
    const value = _get(data, field)
    if (!value) return <Typography>{'--'}</Typography>

    const dateValue = value ? formatDatetime(value, DATE_FORMATS.DATE) : '--'
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={dateValue}>
        {dateValue}
      </TypographyLineClamp>
    )
  }, [])

  const getRatingColumn = useCallback(
    (data: any, addProps: any = {}) => {
      const handleClick = () => {
        setSelectedCase(data)
        openDialog()
      }

      const isMyCase = _get(data, 'contactId') === userContactId || activeTab === 0
      const status = _get(data, 'status')
      if (status !== 'Closed') {
        return <Typography textAlign="right" sx={{ ... addProps}}>{'--'}</Typography>
      }

      const ratingValue = _get(data, 'satisfactoryNumber')
      if (ratingValue === null) {
        return isMyCase ? (
          <Typography textAlign="right" sx={{ textDecoration: 'underline', cursor: 'pointer', ...addProps}} onClick={handleClick}>
            {'Rate'}
          </Typography>
        ) : (
          <Typography textAlign="right" sx={{ ... addProps}}>{'--'}</Typography>
        )
      }

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '23px',
            cursor: isMyCase ? 'pointer' : 'default',
            ...addProps
          }}
          onClick={isMyCase ? handleClick : undefined}
        >
          <StarIcon color="#FDBD0D" fill="#FDBD0D" />
          <Typography textAlign="center" sx={{ textDecoration: isMyCase ? 'underline' : 'unset', minWidth: '24px' }}>
            {ratingValue}
          </Typography>
        </Box>
      )
    },
    [activeTab, userContactId, setSelectedCase, openDialog]
  )

  const viewDetailFalseDetection = useCallback(
    (data: any) => {
      const fsPublicId = _get(data, 'fsPublicId', '')
      const handleViewDetail = () => {
        setSelectedFalseDetection(fsPublicId)
        setOpenDetailDialog(true)
      }

      return fsPublicId ? (
        <Typography variant="body2" color={'#1D6BFC'}  textAlign="right" sx={{ cursor: 'pointer'}} onClick={handleViewDetail}>
          {'View Detection'}
        </Typography>
      ) : (<></>)
    },
    [setOpenDetailDialog]
  )

  const getMyCasesColumns = useCallback(() => {
    if (!isMiniTable) {
      return [
        {
          header: 'Case No.',
          body: (data: any) => getColumnValueAsLink(data, 'caseNumber'),
          style: getColumnStyles(6)
        },
        {
          header: 'Product',
          body: (data: any) => getColumnValue(data, 'subCategory'),
          style: getColumnStyles(8)
        },
        {
          header: 'Subject',
          body: (data: any) => getColumnValue(data, 'subject'),
          style: getColumnStyles(20)
        },
        {
          header: 'Severity Level',
          body: (data: any) => getColumnValue(data, 'severity'),
          style: getColumnStyles(17)
        },
        {
          header: 'Created Date',
          body: (data: any) => getDateColumn(data, 'createdDate'),
          style: getColumnStyles(8)
        },
        {
          header: 'Last Updated',
          body: (data: any) => getDateColumn(data, 'lastModifiedDate'),
          style: getColumnStyles(8)
        },
        {
          header: 'Status',
          body: (data: any) => getColumnValue(data, 'status'),
          style: getColumnStyles(10)
        },
        {
          header: 'Satisfaction',
          body: getRatingColumn,
          style: { minWidth: 'auto', width: '100%', textAlign: 'right' }
        }
      ]
    }

    return [
      {
        header: 'Case No.',
        body: (data: any) => getColumnValueAsLink(data, 'caseNumber'),
        style: getColumnStyles(6)
      },
      {
        header: 'Product',
        body: (data: any) => getColumnValue(data, 'subCategory'),
        style: getColumnStyles(10)
      },
      {
        header: 'Subject',
        body: (data: any) => getColumnValue(data, 'subject'),
        style: getColumnStyles(12)
      },
      {
        header: 'Last Updated',
        body: (data: any) => getDateColumn(data, 'lastModifiedDate'),
        style: getColumnStyles(6)
      },
      {
        header: 'Status',
        body: (data: any) => getColumnValue(data, 'status'),
        style: getColumnStyles(8)
      },
      {
        header: 'Satisfaction',
        body: getRatingColumn,
        style: { minWidth: 'auto', width: '100%', textAlign: 'right' }
      }
    ]
  }, [isMiniTable])

  const getMyFalseSubmissionColumns = useCallback(() => {
    if (!isMiniTable) {
      return [
        {
          header: 'Case No.',
          body: (data: any) => getColumnValueAsLink(data, 'caseNumber'),
          style: getColumnStyles(6)
        },
        {
          header: 'File Name',
          body: (data: any) => getColumnValue(data, 'fsFileName'),
          style: getColumnStyles(15)
        },
        {
          header: 'Subject',
          body: (data: any) => getColumnValue(data, 'subject'),
          style: getColumnStyles(17)
        },
        {
          header: 'Severity Level',
          body: (data: any) => getColumnValue(data, 'severity'),
          style: getColumnStyles(13)
        },
        {
          header: 'Created Date',
          body: (data: any) => getDateColumn(data, 'createdDate'),
          style: getColumnStyles(8)
        },
        {
          header: 'Last Updated',
          body: (data: any) => getDateColumn(data, 'lastModifiedDate'),
          style: getColumnStyles(8)
        },
        {
          header: 'Status',
          body: (data: any) => getColumnValue(data, 'status'),
          style: getColumnStyles(10)
        },
        {
          header: 'Satisfaction',
          body: getRatingColumn,
          style: { minWidth: 'auto', width: '100%', textAlign: 'right' }
        }
      ]
    }

    return [
      {
        header: 'Case No.',
        body: (data: any) => getColumnValueAsLink(data, 'caseNumber'),
        style: getColumnStyles(6)
      },
      {
        header: 'File Name',
        body: (data: any) => getColumnValue(data, 'fsFileName'),
        style: getColumnStyles(8)
      },
      {
        header: 'Subject',
        body: (data: any) => getColumnValue(data, 'subject'),
        style: getColumnStyles(8)
      },
      {
        header: 'Last Updated',
        body: (data: any) => getDateColumn(data, 'lastModifiedDate'),
        style: getColumnStyles(6)
      },
      {
        header: 'Status',
        body: (data: any) => getColumnValue(data, 'status'),
        style: getColumnStyles(8)
      },
      {
        header: 'Satisfaction',
        body: (data: any) => getRatingColumn(data, { pr: '20px'}),
        style: getColumnStyles(5)
      },
      {
        header: '',
        body: viewDetailFalseDetection,
        style: { minWidth: 'auto', width: '100%', textAlign: 'right' }
      }
    ]
  }, [isMiniTable])

  const getOtherCasesColumns = useCallback(() => {
    const additionalCol = {
      header: caseType === SupportCaseTypes.ORG_CASES ? 'Contact Name' : 'Customer',
      body: (data: any) =>
        getColumnValue(data, caseType === SupportCaseTypes.ORG_CASES ? 'contactName' : 'endCustomerName')
    }

    if (!isMiniTable) {
      return [
        {
          header: 'Case No.',
          body: (data: any) => getColumnValueAsLink(data, 'caseNumber'),
          style: getColumnStyles(6)
        },
        {
          header: additionalCol.header,
          body: additionalCol.body,
          style: getColumnStyles(10)
        },
        {
          header: 'Product',
          body: (data: any) => getColumnValue(data, 'subCategory'),
          style: getColumnStyles(12)
        },
        {
          header: 'Subject',
          body: (data: any) => getColumnValue(data, 'subject'),
          style: getColumnStyles(16)
        },
        {
          header: 'Severity Level',
          body: (data: any) => getColumnValue(data, 'severity'),
          style: getColumnStyles(14)
        },
        {
          header: 'Created Date',
          body: (data: any) => getDateColumn(data, 'createdDate'),
          style: getColumnStyles(6)
        },
        {
          header: 'Last Updated',
          body: (data: any) => getDateColumn(data, 'lastModifiedDate'),
          style: getColumnStyles(6)
        },
        {
          header: 'Status',
          body: (data: any) => getColumnValue(data, 'status'),
          style: getColumnStyles(8)
        },
        {
          header: 'Satisfaction',
          body: getRatingColumn,
          style: { minWidth: 'auto', width: '100%', textAlign: 'right' }
        }
      ]
    }

    return [
      {
        header: 'Case No.',
        body: (data: any) => getColumnValueAsLink(data, 'caseNumber'),
        style: getColumnStyles(6)
      },
      {
        header: additionalCol.header,
        body: additionalCol.body,
        style: getColumnStyles(8)
      },
      {
        header: 'Product',
        body: (data: any) => getColumnValue(data, 'subCategory'),
        style: getColumnStyles(8)
      },
      {
        header: 'Subject',
        body: (data: any) => getColumnValue(data, 'subject'),
        style: getColumnStyles(12)
      },
      {
        header: 'Last Updated',
        body: (data: any) => getDateColumn(data, 'lastModifiedDate'),
        style: getColumnStyles(6)
      },
      {
        header: 'Status',
        body: (data: any) => getColumnValue(data, 'status'),
        style: getColumnStyles(8)
      },
      {
        header: 'Satisfaction',
        body: getRatingColumn,
        style: { minWidth: 'auto', width: '100%', textAlign: 'right' }
      }
    ]
  }, [isMiniTable, caseType])

  const tableColumns = useMemo(() => {
    switch (caseType) {
      case SupportCaseTypes.MY_CASES:
        return getMyCasesColumns()
      case SupportCaseTypes.ORG_CASES:
      case SupportCaseTypes.CUSTOMER_CASES:
        return getOtherCasesColumns()
      case SupportCaseTypes.MY_SUBMISSION_CASES:
        return getMyFalseSubmissionColumns()
      default:
        return []
    }
  }, [caseType, getRatingColumn, getColumnStyles])

  useEffect(() => {
    setPageInfo(DEFAULT_PAGE_INFO)
  }, [activeTab])

  useEffect(() => {
    handleGetSupportCases()
  }, [pageInfo])

  useEffect(() => {
    if (pageInfo.page !== 0) setPageInfo({ ...pageInfo, page: 0 })
    else handleGetSupportCases()
  }, [filters])

  return (
    <>
      <Grid item xs={12}>
        {userContactId ? (
          <TableAdvanced
            isPagination
            disableBackground
            columns={tableColumns}
            isLoading={Boolean(isFetching)}
            data={_get(casesData, 'data', [])}
            total={_get(casesData, 'total', 0)}
            page={pageInfo.page}
            pageSize={pageInfo.pageSize}
            pageSizes={isMiniTable ? [5] : PAGE_SIZES_DEFAULT}
            onPageChange={handlePagination}
            noDataTextProps={{ sx: { my: 0 } }}
            boxMinHeight={isMiniTable ? '200px' : undefined}
            boxMaxHeight={isMiniTable ? '400px' : undefined}
            loadingArray={isMiniTable ? _range(4) : _range(15)}
            customActionComponent={
              isMiniTable ? (
                <Button
                  sx={{ color: '#000', gap: 0.5, height: 'fit-content' }}
                  onClick={() => navigate(supportServicesPageAllCasesURL)}
                >
                  View all
                  <ExternalLinkIcon />
                </Button>
              ) : undefined
            }
          />
        ) : (
          <Box minHeight="400px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box sx={{ marginY: 2 }}>
              <img src={CircularLoader} width={50} height={50} className="loading-spinner" alt="" />
            </Box>
            <Typography variant="body2">Your data is being initialized and will take around 4-5 hours.</Typography>
            <Typography variant="body2">Please try signing in later to check if it's ready.</Typography>
            <Typography variant="body2">Thank you for your patience!</Typography>
          </Box>
        )}
      </Grid>
      <DialogCSAT
        caseInfo={selectedCase}
        openDialog={isOpen}
        handleCloseDialog={closeDialog}
        handleSearchOnSubmit={() => {
          handleGetSupportCases()
        }}
      />
      {selectedFalseDetection && (
        <DialogFalseSubmissionDetails
          submissionId={selectedFalseDetection}
          openDialog={openDetailDialog}
          onClose={() => {
            setOpenDetailDialog(false)
            setSelectedFalseDetection(undefined)
          }}
        />)
      }
    </>
  )
}

export default SupportServiceCaseTable
