import React, { FC, useMemo, useState } from 'react'

import _every from 'lodash/every'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _intersection from 'lodash/intersection'
import _isEmpty from 'lodash/isEmpty'
import _join from 'lodash/join'
import _map from 'lodash/map'
import _size from 'lodash/size'

import { StarIcon, StarYellowIcon } from '@opswat/react-icon'
import {
  Box,
  BoxImageVideo,
  Button,
  ButtonLoading,
  Grid,
  IconButton,
  TabAdvanced,
  TextGrid,
  Tooltip,
  Typography,
  TypographyLineClamp,
  TypographyLink
} from '@opswat/react-ui'

import Parser from 'html-react-parser'
import { useNavigate } from 'react-router-dom'

import { IDownloadedProductInput } from 'myopswat-web/src/api/product/types'
import { licensedProductsMyOrganizationPageUrl } from 'myopswat-web/src/routes'

import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import {
  CLOUDBASE,
  ONPREMISE_OEM_CLOUDBASE_TAGS,
  ONPREMISE_OEM_UTILITY_TAGS,
  UNDOWNLOADABLE,
  UTILITY
} from 'myopswat-web/src/constants/product-type'

import { DATE_FORMATS } from '@myopswat/common'
import { createURL, formatDatetime } from '@opswat/react-core'
import defaultImage from 'myopswat-web/src/assets/images/default-bg.png'
import DialogDownloadVersion from 'myopswat-web/src/components/Dialog/DialogDownloadVersion'
import { useTypedSelector } from 'myopswat-web/src/store'

interface IProps {
  productDetailData: any
  handleDownloadedProductAdd: (data: IDownloadedProductInput) => void
  tagType: string[]
  licensedProductsData: any
  isFetchingLicensedProducts: boolean
  icapIntegrationsData: any
  handleProductInterestedAdd: (data: string) => void
  handleProductInterestedRemove: (data: string) => void
  solutionsData: any
}
const BoxDetailInfo: FC<IProps> = ({
  productDetailData,
  handleDownloadedProductAdd,
  tagType,
  licensedProductsData,
  isFetchingLicensedProducts,
  icapIntegrationsData,
  handleProductInterestedAdd,
  handleProductInterestedRemove,
  solutionsData
}) => {
  const navigate = useNavigate()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data) || {}

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)

  const handleCheckShowEvaluate = () => {
    const isParent =
      _get(productDetailData, 'downloadable', false) && !_isEmpty(_get(productDetailData, 'releases', []))
    const isAllChildren = !_every(_get(productDetailData, 'children', []), item => _get(item, 'downloadable') === false)
    const isAllUtility = !_every(_get(productDetailData, 'utilities', []), item => _get(item, 'downloadable') === false)

    if (isParent || isAllChildren || isAllUtility) return true
    return false
  }

  const handleRenderTag = (data: any) => {
    if (!_isEmpty(data)) return _join(data, ', ')
    return 'Hardware'
  }

  const detailData = [
    {
      label: 'Type',
      value: handleRenderTag(_get(productDetailData, 'type'))
    },
    ...(_size(solutionsData) > 0
      ? [
          {
            label: 'Solutions',
            value: solutionsData?.toString()?.replace(/,/g, ', ') || ''
          }
        ]
      : [])
  ]

  const handleToggleInterested = (productId: string) => {
    if (!productId) return null

    if (productDetailData?.interested) {
      handleProductInterestedRemove(productId)
    } else {
      handleProductInterestedAdd(productId)
    }
  }

  const productUrls = useMemo(() => {
    // Initialize plaftform name mapping
    const platformList = _get(productDetailData, 'releaseMetadata.platforms', [])
    const platformMap = platformList.reduce(
      (obj: any, plaftform: { id: number; name: string }) => ((obj[plaftform.id] = plaftform.name), obj),
      {}
    )
    const results: any[] = []
    const productName = _get(productDetailData, 'name')
    const productReleases = _get(productDetailData, 'releases', [])
    if (!_isEmpty(productReleases)) {
      productReleases.forEach((release: any) => {
        const assets = _get(release, 'assets', [])
        assets.forEach((asset: any) => {
          let urlName = `${productName}`
          if (asset.platformId in platformMap) {
            urlName += ` - ${platformMap[asset.platformId]}`
          }

          results.push({
            name: urlName,
            link: asset.link,
            version: asset.displayName,
            releaseDate: formatDatetime(release.epochTime, DATE_FORMATS.DATE, _get(profileData, 'timezone') || '')
          })
        })
      })
    }

    return results
  }, [productDetailData])

  const BoxContentInfo = () => {
    return (
      <>
        {_intersection(tagType, ONPREMISE_OEM_CLOUDBASE_TAGS).length > 0 && (
          <Grid item xs={12}>
            {_map(detailData, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography>{_get(item, 'label', '--')}</Typography>}
                  value={<Typography variant="body2">{_get(item, 'value', '--')}</Typography>}
                  labelColProps={{ xs: 12, md: 3, lg: 2 }}
                  boxProps={{
                    minHeight: 'auto'
                  }}
                />
              </React.Fragment>
            ))}
          </Grid>
        )}
        {_includes(tagType, CLOUDBASE) && !_isEmpty(productUrls) && (
          <>
            <Grid item xs={12}>
              <Typography>Product URL</Typography>
            </Grid>

            <Grid item xs={12} sx={{ mb: 2 }}>
              {_map(productUrls, (item, idx) => (
                <Grid container key={idx.toString()}>
                  <Grid item xs={12} md={8}>
                    <TypographyLink href={createURL(_get(item, 'link'))}>
                      <TypographyLineClamp
                        line={1}
                        variant="body2"
                        sx={{
                          cursor: 'pointer',
                          textDecoration: 'underline'
                        }}
                      >
                        {_get(item, 'name')}
                      </TypographyLineClamp>
                    </TypographyLink>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Typography component="div">
            {_get(productDetailData, 'documentation') ? Parser(_get(productDetailData, 'documentation')) : ''}
          </Typography>
        </Grid>
      </>
    )
  }

  const tabEndpointSecuritySDKArray = [
    {
      label: 'Detail',
      content: <BoxContentInfo />
    },
    {
      label: 'Video',
      content: (
        <>
          {_get(productDetailData, 'resources.0.link') ? (
            <Grid container>
              <Grid item xs={12} md={7}>
                <iframe
                  width="100%"
                  height="315"
                  src={_get(productDetailData, 'resources.0.link')}
                  title="Product name Playlist"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                />
              </Grid>
            </Grid>
          ) : null}
        </>
      )
    }
  ]

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sx={{
            position: 'relative'
          }}
        >
          {!_includes(_get(productDetailData, 'tags'), UTILITY) &&
            !_get(productDetailData, 'parents')?.length &&
            _get(productDetailData, 'id') !== PRODUCT_IDS.ENDPOINT_SECURITY_SDK && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '40px',
                  left: '24px'
                }}
              >
                {productDetailData?.interested ? (
                  <Tooltip title="Remove from Tagged Products" arrow>
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleToggleInterested(`${_get(productDetailData, 'id')}`)
                      }}
                    >
                      <StarYellowIcon size={24} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Add to Tagged Products" arrow>
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleToggleInterested(`${_get(productDetailData, 'id')}`)
                      }}
                    >
                      <StarIcon size={24} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BoxImageVideo
            fitMedia
            src={_get(productDetailData, 'avatar', defaultImage)}
            propsImageVideo={{
              style: {
                objectFit: 'scale-down',
                width: '',
                height: '',
                maxWidth: '100%',
                maxHeight: '100%',
                margin: 'auto',
                OObjectFit: 'cover',
                OObjectPosition: 'center',
                objectPosition: 'center',
                position: 'absolute',
                top: 0,
                left: 0
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body2" component="div">
                {_get(productDetailData, 'description')
                  ? Parser(_get(productDetailData, 'description').replace(/Overview/g, ''))
                  : '--'}
              </Typography>
            </Grid>

            {_get(productDetailData, 'resources.0.link') ? (
              <TabAdvanced tabs={tabEndpointSecuritySDKArray} />
            ) : (
              <BoxContentInfo />
            )}

            <Grid item xs={12} sx={{ my: 2 }}>
              {_intersection(tagType, ONPREMISE_OEM_UTILITY_TAGS).length > 0 && (
                <Grid container spacing={2}>
                  {handleCheckShowEvaluate() && (
                    <Grid item xs="auto">
                      <Button variant="contained" color="primary" onClick={() => setIsOpenDialog(true)}>
                        {_includes(tagType, UNDOWNLOADABLE) ? 'View Details' : 'Download'}
                      </Button>
                    </Grid>
                  )}

                  {!_isEmpty(_get(licensedProductsData, '0.licenses', [])) &&
                    !_isEmpty(_get(productDetailData, 'asIds')) && (
                      <Grid item xs="auto">
                        <ButtonLoading
                          propsButton={{
                            variant: 'outlined',
                            color: 'inherit',
                            onClick: () => {
                              navigate(`${licensedProductsMyOrganizationPageUrl}?q=${_get(productDetailData, 'name')}`)
                            },
                            disabled: isFetchingLicensedProducts,
                            fullWidth: true
                          }}
                          propsLoading={{ color: 'primary' }}
                          isLoading={false}
                        >
                          View Licenses
                        </ButtonLoading>
                      </Grid>
                    )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {_intersection(tagType, ONPREMISE_OEM_UTILITY_TAGS).length > 0 && (
        <DialogDownloadVersion
          isOpenPVersion={isOpenDialog}
          setIsOpenPVersion={setIsOpenDialog}
          productDetailData={productDetailData}
          handleDownloadedProductAdd={handleDownloadedProductAdd}
          icapIntegrationsData={icapIntegrationsData}
        />
      )}
    </>
  )
}

export default BoxDetailInfo
