import _get from 'lodash/get'
import _startCase from 'lodash/startCase'

import { enqueueSnackbar } from 'notistack'
import { CSSProperties, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { PinIcon } from '@opswat/react-icon'
import { ActionButton, Box, Grid, Typography, TypographyDivider, TypographyLineClamp } from '@opswat/react-ui'

import {
  useLazyCustomerCloudProductsQuery,
  usePinUnpinCustomerCloudProductsMutation
} from 'myopswat-web/src/api/license'
import { homePageURL } from 'myopswat-web/src/routes'

import CustomerTooltipSubOrgLabel from '../../../MyLicensesPage/components/CustomerTooltipSubOrgLabel'
import DialogCloudLicenseDetail from '../../../MyLicensesPage/dialogs/DialogCloudLicenseDetail'
import NestedCustomerOrgTable from '../../components/NestedCustomerOrgTable'
import { CustomerLicensesContext, NO_CLOUD_LICENSES_MESSAGE } from '../../interface'

import { LICENSE_STATUS_OPTIONS } from '../../../MyLicensesPage/constants'
import CustomerContainerTitle from '../../components/CustomerContainerTitle'
import CustomerCloudServicesFilter from './searchFilter'

interface IProps {
  expiringDuration?: string
  expiringFrom?: string
  expiringTo?: string
  forViewAllModal?: boolean
}

const CustomerCloudServicesSection: FC<IProps> = ({ forViewAllModal, expiringDuration, expiringFrom, expiringTo }) => {
  const { controlProduct: control, controlCloud, setValueCloud } = useContext(CustomerLicensesContext)

  const customerOrgIds = useWatch({ control, name: 'customerOrgIds' })
  const cloudFilters = useWatch({ control: controlCloud })

  const [getCloudProducts] = useLazyCustomerCloudProductsQuery()
  const [pinUnpinCloudLicense] = usePinUnpinCustomerCloudProductsMutation()

  // dialog states
  const [chosenItem, setChosenItem] = useState<any>()
  const [openDetailDialog, setOpenDetailDialog] = useState<boolean>(false)

  const [mappedCloudData, setMappedCloudData] = useState<any[]>([])
  const [mappedPinnedData, setMappedPinnedData] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isFetchingPinned, setIsFetchingPinned] = useState<boolean>(true)
  const [totalPinned, setTotalPinned] = useState<number>(0)

  const handleMapChildrenData = useCallback((item: any, index: number) => {
    const cloudServices = _get(item, 'cloudServices') ?? []
    const subOrgName = `SUB-ORGANIZATION ${index + 1}`
    if (cloudServices.length === 0) {
      return { name: item.name, subOrgName, apiKey: NO_CLOUD_LICENSES_MESSAGE, sfdc_data: item.sfdc_data }
    }
    return {
      ...item,
      level: 1,
      subOrgName,
      cloudServices: cloudServices.map((service: any) => ({ ...service, isCloudService: true }))
    }
  }, [])

  const handleMapCloudLicense = useCallback(
    (item: any): any => {
      const cloudServices: any[] = _get(item, 'cloudServices') ?? []
      const children: any[] = _get(item, 'children') ?? []

      const isCustomerParent = children.length > 0
      const isChosenOrg = customerOrgIds.some((org: any) => org.value === item.id)
      const parentNoLicense = cloudServices.length === 0 && !isCustomerParent

      const mappedCloudServices = cloudServices.map((service: any) => ({
        ...service,
        isCloudService: true
      }))
      const mappedChildren = children.map((child: any, index: number) => handleMapChildrenData(child, index))
      const mappedParentCustomer = {
        ...item,
        level: 1,
        cloudServices: mappedCloudServices,
        children: [],
        apiKey: cloudServices.length === 0 ? NO_CLOUD_LICENSES_MESSAGE : ''
      }

      return {
        ...item,
        level: 0,
        isCustomerParent,
        cloudServices: [],
        children: isChosenOrg && !parentNoLicense ? [mappedParentCustomer, ...mappedChildren] : mappedChildren,
        apiKey: parentNoLicense ? NO_CLOUD_LICENSES_MESSAGE : ''
      }
    },
    [customerOrgIds]
  )

  const handleCheckEmptyCustomer = useCallback((item: any) => {
    const cloudServices = _get(item, 'cloudServices') ?? []
    const children = _get(item, 'children') ?? []
    const checkEmptyChildren = children.every((child: any) => handleCheckEmptyCustomer(child))
    return cloudServices.length === 0 && checkEmptyChildren
  }, [])

  const handleCheckEmptyData = useCallback((data: any[]) => {
    return data?.every((item: any) => handleCheckEmptyCustomer(item))
  }, [])

  // handle get data from API
  const handleGetData = useCallback(
    async (filters: any) => {
      // set loading state for skeleton
      setIsFetching(true)
      const { filters: _filters } = filters
      await getCloudProducts({
        ...filters,
        customerOrgIds: customerOrgIds.map((item: any) => item.value),
        filters: !forViewAllModal
          ? { ..._filters }
          : {
              expiringDuration: expiringDuration !== 'custom' ? Number(expiringDuration) : undefined,
              expiringFrom: expiringDuration === 'custom' ? expiringFrom : undefined,
              expiringTo: expiringDuration === 'custom' ? expiringTo : undefined
            }
      })
        .unwrap()
        .then((response: any) => {
          setIsFetching(false)
          const orgData = _get(response, 'orgs') ?? []
          setMappedCloudData(orgData.map(handleMapCloudLicense))
        })
        .catch((error: any) => {
          console.error(error)
          setIsFetching(false)
        })
    },
    [customerOrgIds]
  )

  const handleGetPinnedData = useCallback(async () => {
    setIsFetchingPinned(true)
    await getCloudProducts({
      filters: {
        licenseStatuses: [],
        pinnedOnly: true
      },
      customerOrgIds: customerOrgIds.map((item: any) => item.value)
    })
      .unwrap()
      .then((response: any) => {
        setIsFetchingPinned(false)
        const orgData = _get(response, 'orgs') ?? []
        const totalKeys = _get(response, 'totalKeys') ?? 0
        setMappedPinnedData(orgData.map(handleMapCloudLicense))
        setTotalPinned(totalKeys)
      })
      .catch((error: any) => {
        setIsFetchingPinned(false)
        console.error(error)
      })
  }, [customerOrgIds])

  const handleViewDetails = useCallback((item: any) => {
    setChosenItem(item)
    setOpenDetailDialog(true)
  }, [])

  const handlePinLicense = useCallback(async (data: any, isPinned?: boolean) => {
    await pinUnpinCloudLicense({ orgId: data.organization_id, pin: isPinned ?? true })
      .unwrap()
      .then((response: any) => {
        if (response.success) {
          enqueueSnackbar(`${isPinned ? 'Pinned' : 'Unpinned'} license successfully`, {
            variant: 'success'
          })
          handleGetPinnedData()
        }
      })
      .catch(() => {
        enqueueSnackbar(
          `${isPinned ? 'Pinning' : 'Unpinning'} license has failed. Please give the system a moment then try again.`,
          {
            variant: 'error'
          }
        )
      })
  }, [])

  const renderFilterText = useCallback(
    (key: string, label: string, options: any[]) => {
      const values = _get(cloudFilters, key)

      return values?.length > 0
        ? `${label} = ${values
            ?.map((item: any) => options.find((option: any) => option.value === item)?.label)
            .join(', ')}`
        : ''
    },
    [cloudFilters]
  )

  const renderAllFilterText = useCallback(() => {
    const statusFilter = renderFilterText('licenseStatuses', 'Status', LICENSE_STATUS_OPTIONS)
    return statusFilter
  }, [renderFilterText])

  const renderCell = useCallback((data: any, key: string, sx?: CSSProperties, hideEmpty?: boolean) => {
    const value = data[key]
    const emptyValue = hideEmpty ? '' : '--'
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipPlacement="top" tooltipValue={value} sx={sx}>
        {value || emptyValue}
      </TypographyLineClamp>
    )
  }, [])

  const renderDateCell = useCallback((data: any, key: string) => {
    if (!_get(data, 'isCloudService')) {
      return <></>
    }
    const value = formatDatetime(data[key], DATE_FORMATS.DATE)
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={value} tooltipPlacement="top">
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderOrgNameCell = useCallback((data: any, sx?: CSSProperties) => {
    const orgName = _get(data, 'name')
    const subOrgName = _get(data, 'subOrgName')
    const level = _get(data, 'level')
    const sfdcData = _get(data, 'sfdc_data')
    let color = '#3D4A68'

    if (level === 0) {
      // godfather organizations
      color = '#154FBA'
    } else if (_get(data, 'isCloudService')) {
      // product parent
      color = '#1D6DFC'
    }

    return (
      <CustomerTooltipSubOrgLabel mainLabel={subOrgName} subLabel={orgName} sfdcData={sfdcData} sx={{ ...sx, color }} />
    )
  }, [])

  const renderTypeCell = useCallback((data: any) => {
    if (!_get(data, 'isCloudService')) {
      return <></>
    }
    const value = _startCase(_get(data, 'mdCloudLicenseType')) || '--'
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={value} tooltipPlacement="top">
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderStatusCell = useCallback((data: any) => {
    if (!_get(data, 'isCloudService')) {
      return <></>
    }
    const value = _get(data, 'status') ?? '--'
    const daysToExpire = _get(data, 'days_to_expire', 0)

    let color = '#008A00'
    if (value === 'expired') {
      color = '#D00300'
    } else if (value === 'expiring') {
      if (daysToExpire < 90 && daysToExpire >= 30) {
        color = '#FDBD0D'
      } else {
        color = '#D00300'
      }
    }

    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        textTransform="capitalize"
        style={{ color }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderActionCell = useCallback(
    (data: any) => {
      if (!_get(data, 'isCloudService') || forViewAllModal) {
        return <></>
      }
      const childItems = [
        {
          label: 'Pin',
          onClick: () => {
            handlePinLicense(data, true)
          }
        },
        {
          label: 'View Details',
          onClick: () => {
            handleViewDetails(data)
          }
        }
      ]

      return (
        <ActionButton
          items={childItems}
          paperStyles={{
            minWidth: '150px'
          }}
          buttonStyles={{
            padding: '0px'
          }}
        />
      )
    },
    [forViewAllModal]
  )

  const renderPinActionCell = useCallback(
    (data: any) => {
      if (forViewAllModal) {
        return <></>
      }

      const childItems = [
        {
          label: 'Unpin',
          onClick: () => {
            handlePinLicense(data, false)
          }
        },
        {
          label: 'View Details',
          onClick: () => {
            handleViewDetails(data)
          }
        }
      ]

      return (
        <ActionButton
          items={childItems}
          paperStyles={{
            minWidth: '150px'
          }}
          buttonStyles={{
            padding: '0px'
          }}
        />
      )
    },
    [forViewAllModal]
  )

  const cloudColumns = useMemo(() => {
    const defaultColumns = [
      {
        header: 'Service Name',
        style: { minWidth: '24vw', width: '24vw' },
        body: (data: any) =>
          renderOrgNameCell(data, {
            color: _get(data, 'isCloudService') ? '#1d6dfc' : undefined,
            fontWeight: _get(data, 'isCloudService') ? 400 : 500
          }),
        highlightLevels: [0, 1],
        linkLevels: (data: any) => {
          const link = _get(data, 'isCloudService') ? `${homePageURL}/metadefender-cloud` : ''
          return ['', link, link]
        }
      },
      {
        header: 'API Key',
        style: { minWidth: '18vw', width: '18vw' },
        body: (data: any) => renderCell(data, 'apiKey', {}, true)
      },
      {
        header: 'Status',
        style: {
          minWidth: '8vw',
          width: '8vw',
          textAlign: 'center',
          sx: {
            justifyContent: 'center'
          }
        },
        body: renderStatusCell,
        hiddenLevels: [0]
      },
      {
        header: 'Expiration Date',
        style: { minWidth: '10vw', width: '10vw' },
        body: (data: any) => renderDateCell(data, 'expired_date'),
        hiddenLevels: [0]
      },
      {
        header: 'Type',
        style: { minWidth: '10vw', width: '10vw' },
        body: renderTypeCell,
        hiddenLevels: [0]
      },
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderActionCell,
        hiddenLevels: [0, 1]
      }
    ]

    if (forViewAllModal) {
      return defaultColumns.filter(c => c.header != 'Status')
    } else return defaultColumns
  }, [forViewAllModal])

  const pinnedColumns = useMemo(() => {
    const columns = [...cloudColumns]
    columns.pop()
    return [
      ...columns,
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderPinActionCell,
        hiddenLevels: [0, 1]
      }
    ]
  }, [cloudColumns])

  const TableMemo = useMemo(
    () => (
      <NestedCustomerOrgTable
        data={handleCheckEmptyData(mappedCloudData) ? [] : mappedCloudData}
        isLoading={isFetching}
        columns={cloudColumns}
        childrenKeys={[['children'], ['cloudServices'], ['']]}
        noDataText={NO_CLOUD_LICENSES_MESSAGE}
      />
    ),
    [mappedCloudData, isFetching, cloudColumns]
  )

  const PinnedTableMemo = useMemo(
    () => (
      <NestedCustomerOrgTable
        noHeader
        data={handleCheckEmptyData(mappedPinnedData) ? [] : mappedPinnedData}
        isLoading={isFetchingPinned}
        columns={pinnedColumns}
        childrenKeys={[['children'], ['cloudServices'], ['']]}
        noDataText={NO_CLOUD_LICENSES_MESSAGE}
      />
    ),
    [mappedPinnedData, isFetchingPinned, pinnedColumns]
  )

  useEffect(() => {
    handleGetData({ filters: cloudFilters })
    !forViewAllModal && handleGetPinnedData()
  }, [cloudFilters])

  return (
    <Grid item container xs={12} id="customer-cloud-services-section">
      <Grid item container xs={12} sx={{ border: '1px solid #BCBFC3' }}>
        <Grid item xs={12}>
          <CustomerContainerTitle title={'Cloud Services'} />
        </Grid>
        {!forViewAllModal && (
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: '15px' }}>
            <TypographyDivider
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: '20px' }}>
                  <PinIcon size={16} />
                  <Typography variant="body1" marginTop="2px">
                    Pinned ({totalPinned})
                  </Typography>
                </Box>
              }
              propsTypo={{
                component: 'label',
                variant: 'body1'
              }}
            />
            {PinnedTableMemo}
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: '0px 15px'
          }}
        >
          <TypographyDivider
            isDivider={false}
            label=""
            propsTypo={{
              component: 'label',
              variant: 'body1'
            }}
            actions={
              !forViewAllModal ? (
                <CustomerCloudServicesFilter
                  currentFilters={cloudFilters}
                  setCurrentFilters={(data: any) => {
                    Object.entries(data).forEach(([key, value]: any) => {
                      setValueCloud(key, value)
                    })
                    handleGetData({
                      filters: data,
                      customerOrgIds: customerOrgIds.map((item: any) => item.value)
                    })
                  }}
                />
              ) : (
                <></>
              )
            }
          />
          {renderAllFilterText() && (
            <TypographyLineClamp
              line={1}
              variant="body1"
              tooltipValue={renderAllFilterText()}
              tooltipPlacement="top"
              sx={{
                minHeight: '20px'
              }}
            >
              {!forViewAllModal && (
                <span>
                  Filter:{' '}
                  <Typography component="span" variant="body2">
                    {renderAllFilterText()}
                  </Typography>
                </span>
              )}
            </TypographyLineClamp>
          )}
          {TableMemo}
        </Grid>
      </Grid>
      {chosenItem && (
        <DialogCloudLicenseDetail
          item={chosenItem}
          type={_get(chosenItem, 'service')}
          openDialog={openDetailDialog}
          onClose={() => setOpenDetailDialog(false)}
        />
      )}
    </Grid>
  )
}

export default CustomerCloudServicesSection
