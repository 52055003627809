import { FC, useMemo, useState } from 'react'

import { DataTable, TableLoading, TemplateSection, TypographyLineClamp } from '@opswat/react-ui'

import { formatDatetime, toApiDateTime } from '@opswat/react-core'

import _subDays from 'date-fns/subDays'
import _subHours from 'date-fns/subHours'
import _subYears from 'date-fns/subYears'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import Parser from 'html-react-parser'
import { useEventsHistoriesQuery } from 'myopswat-web/src/api/event'
import { IEventHistoryInput } from 'myopswat-web/src/api/event/types'
import { PAGE_DEFAULT, PAGE_SIZES_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-web/src/constants'
import { useTypedSelector } from 'myopswat-web/src/store'
import { ORGANIZATION_EVENT_ACTIONS, ORGANIZATION_EVENT_ACTION_TYPES } from './constants'
import EventHistoryFilter from './EventHistoryFilter'
import { EventHistoryFilterForm, TimeOptions } from './types'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

const COLUMNS = [
  {
    accessorKey: 'user',
    header: 'User',
    size: 150,
    Cell: ({ cell }: { cell: any }) => (
      <TypographyLineClamp style={{ fontWeight: 'normal' }} line={1} tooltipValue={cell.getValue()}>
        {cell.getValue()}
      </TypographyLineClamp>
    )
  },
  {
    accessorKey: 'action',
    header: 'Action',
    size: 50
  },
  {
    accessorKey: 'impacted',
    header: 'Impacted',
    size: 150,
    Cell: ({ cell }: { cell: any }) => (
      <TypographyLineClamp style={{ fontWeight: 'normal' }} line={1} tooltipValue={cell.getValue()}>
        {cell.getValue()}
      </TypographyLineClamp>
    )
  },
  {
    accessorKey: 'oldValue',
    header: 'Old Value',
    size: 150
  },
  {
    accessorKey: 'newValue',
    header: 'New Value',
    size: 150
  },
  {
    accessorKey: 'time',
    header: 'Time',
    size: 150
  }
]

const EventHistory: FC<IProps> = () => {
  const timeOptions: TimeOptions = useMemo(() => {
    const currentDate = new Date()

    return {
      last24Hours: {
        fromDatetime: toApiDateTime(_subHours(currentDate, 24)),
        toDatetime: toApiDateTime(currentDate)
      },
      last7Days: {
        fromDatetime: toApiDateTime(_subDays(currentDate, 7)),
        toDatetime: toApiDateTime(currentDate)
      },
      last30Days: {
        fromDatetime: toApiDateTime(_subDays(currentDate, 30)),
        toDatetime: toApiDateTime(currentDate)
      },
      last12Months: {
        fromDatetime: toApiDateTime(_subYears(currentDate, 1)),
        toDatetime: toApiDateTime(currentDate)
      }
    }
  }, [])
  const profile = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const [filterForm, setFilterForm] = useState<EventHistoryFilterForm>({
    q: '',
    actions: [],
    time: ''
  })
  const [query, setQuery] = useState<IEventHistoryInput>({
    filters: {
      q: '',
      actions: [],
      fromDatetime: '',
      toDatetime: ''
    },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })
  const { data, isFetching } = useEventsHistoriesQuery(query, {
    refetchOnMountOrArgChange: true
  })
  const handleSearch = (searchData: EventHistoryFilterForm) => {
    const filters = {
      q: searchData?.q,
      actions: searchData?.actions,
      fromDatetime: timeOptions[searchData.time as keyof TimeOptions]?.fromDatetime,
      toDatetime: timeOptions[searchData.time as keyof TimeOptions]?.toDatetime
    }
    setFilterForm(searchData)
    setQuery(statePrev => Object.assign({}, statePrev, { filters: filters }))
  }

  const getValue = (item: any) => {
    if (!item) return ''

    const { type, license_key, value, customer_id } = item

    const templates: { [key: string]: () => string } = {
      license_partner_note_changed: () => {
        const customerName = data?.customerOrganizations.find((i: any) => i.id === customer_id)?.name ?? ''
        return `Customer name: ${customerName}<br>License Key: ${license_key}<br>Note: ${value}`
      },
      license_note_changed: () => `License Key: ${license_key}<br>Note: ${value}`
    }

    const content = templates[type]?.() || (value ?? '')

    return Parser(content)
  }

  const tableData = useMemo(() => {
    if (_isEmpty(data?.eventsHistories)) {
      return []
    }
    return data!.eventsHistories.results?.map((event: any) => ({
      user: event?.user ? event.user.email : '-',
      action: ORGANIZATION_EVENT_ACTION_TYPES[ORGANIZATION_EVENT_ACTIONS[event.type]] || '',
      impacted: event.impacted || '',
      oldValue: getValue(event.oldValue),
      newValue: getValue(event.newValue),
      time: formatDatetime(event.createdAt, undefined, _get(profile, 'timezone'))
    }))
  }, [data])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page: page, pageSize: pageSize } }))
  }

  return (
    <TemplateSection spacing={2}>
      <EventHistoryFilter filters={filterForm} onSearch={handleSearch} />
      {isFetching ? (
        <TableLoading />
      ) : (
        <DataTable
          columns={COLUMNS}
          data={tableData}
          enableEditing={false}
          enableRowActions={false}
          enablePagination={true}
          total={data?.eventsHistories.totalCount}
          page={query.pageInfo.page}
          pageSize={query.pageInfo.pageSize}
          pageSizes={PAGE_SIZES_DEFAULT}
          isPaganization
          onChangePage={handlePaginationOnChange}
        />
      )}
    </TemplateSection>
  )
}

export default EventHistory
