import { FC } from 'react'

import _get from 'lodash/get'

import { BLOB_FILE_TYPES, handleCreateFile } from '@myopswat/common'
import { ButtonLoading, Grid, Typography, TypographyDivider } from '@opswat/react-ui'

import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useLazyProfileQuery } from 'myopswat-web/src/api/account'
import { useAccountMfaDisableMutation, useAccountMfaResetMutation } from 'myopswat-web/src/api/mfa'
import useConfirmationDialog from 'myopswat-web/src/hooks/useConfirmationDialog'
import { mfaPageURL } from 'myopswat-web/src/routes'
import { useTypedSelector } from 'myopswat-web/src/store'

const SecurityMFA: FC<unknown> = () => {
  const navigate = useNavigate()
  const { t: translate } = useTranslation()
  const { openConfirmation } = useConfirmationDialog()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [getProfile] = useLazyProfileQuery()
  const [accountMfaReset, { isLoading: isLoadingResetMFA }] = useAccountMfaResetMutation()
  const [accountMfaDisable] = useAccountMfaDisableMutation()

  const handleResetMFA = async () => {
    try {
      const response = await accountMfaReset().unwrap()

      if (response?.success) {
        enqueueSnackbar(translate('resetCodeMFASuccess'), {
          variant: 'success'
        })
        handleCreateFile('opswat-recovery-codes', 'txt', _get(response, 'recoveryCodes', ''), {
          type: BLOB_FILE_TYPES.PLAIN
        })
      } else {
        enqueueSnackbar(translate('resetCodeMFAFail'), {
          variant: 'error'
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDisableMFA = async () => {
    const confirmDiscard = await openConfirmation({
      content: 'Are you sure you want to disable Multi-factor Authentication?',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    })
    if (confirmDiscard) {
      accountMfaDisable()
        .unwrap()
        .then((response: any) => {
          if (response?.success) {
            enqueueSnackbar(translate('disableMFASuccess'), {
              variant: 'success'
            })
            getProfile()
          } else {
            enqueueSnackbar(translate('disableMFAFail'), {
              variant: 'error'
            })
          }
        })
        .catch(error => {
          console.error(error)
          enqueueSnackbar(translate('disableMFAFail'), {
            variant: 'error'
          })
        })
    }
  }

  if (_get(profileData, 'accountInfo.isMfaEnabled'))
    return (
      <Grid container spacing={1.25}>
        <Grid item xs={12}>
          <TypographyDivider
            label="Multi-factor Authentication"
            propsTypo={{ variant: 'subtitle1', color: '#1B273C' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="#485161">
            Your MFA is activated.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Multi-factor authentication creates strong user verification - it is recommended to maintain high-standards
            of security for access to resources.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!_get(profileData, 'organizationForceMfa', false) && (
            <ButtonLoading
              propsButton={{
                variant: 'outlined',
                color: 'inherit',
                onClick: handleDisableMFA,
                sx: { mr: 1 }
              }}
              propsLoading={{ color: 'inherit' }}
            >
              Disable MFA
            </ButtonLoading>
          )}
          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              disableElevation: true,
              onClick: handleResetMFA
            }}
            propsLoading={{ color: 'inherit' }}
            isLoading={isLoadingResetMFA}
          >
            Reset Code
          </ButtonLoading>
        </Grid>
      </Grid>
    )

  return (
    <Grid container spacing={1.25}>
      <Grid item xs={12}>
        <TypographyDivider label="Multi-factor Authentication" propsTypo={{ variant: 'subtitle1', color: '#1B273C' }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" color="#485161">
          Your MFA hasn't been activated yet.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          Multi-factor authentication creates strong user verification - it is recommended to maintain high-standards of
          security for access to resources.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            disableElevation: true,
            onClick: () => navigate(mfaPageURL)
          }}
          propsLoading={{ color: 'inherit' }}
          isLoading={false}
        >
          Setup MFA
        </ButtonLoading>
      </Grid>
    </Grid>
  )
}

export default SecurityMFA
