export enum CaseStatus {
  New = 'New',
  WaitingOnMe = 'Waiting on Me',
  WaitingOnOPSWAT = 'Waiting on OPSWAT',
  Open = 'Open',
  Closed = 'Closed'
}

export enum SupportCaseTypes {
  MY_CASES = 'myCases',
  ORG_CASES = 'orgCases',
  CUSTOMER_CASES = 'customerCases',
  MY_SUBMISSION_CASES = "myFalseSubmissionCases"
}

export const DEFAULT_SEARCH_HINT = 'Search by case number, product or subject'

export const MY_SUBMISSION_CASES_TAB = {
  label: 'False Detection Submission',
  value: SupportCaseTypes.MY_SUBMISSION_CASES,
  pageTitle: 'View My False Submission Cases (Support)',
  searchingHint: 'Search by case number, subject'
}

export const CASE_TABLE_TABS = [
  {
    label: 'My Cases',
    value: SupportCaseTypes.MY_CASES,
    pageTitle: 'View My Cases (Support)',
  },
  {
    label: 'Organization Cases',
    value: SupportCaseTypes.ORG_CASES,
    pageTitle: 'View Organization Cases (Support)',
  },
  {
    label: 'Customer Cases',
    value: SupportCaseTypes.CUSTOMER_CASES,
    pageTitle: 'View Customer Cases (Support)',
  },
  MY_SUBMISSION_CASES_TAB
]

export const MY_CASES_TAB = {
  label: 'My Cases',
  value: SupportCaseTypes.MY_CASES,
  pageTitle: 'View My Cases (Support)'
}
