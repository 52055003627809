import React, { FC } from 'react'

import { OpswatIcon } from '@opswat/react-icon'
import { Box, Button, Grid, Typography } from '@opswat/react-ui'
import { handleForceLogout, KEYS } from '@myopswat/common'

const SessionTimeoutForm: FC<unknown> = () => {
  const isInIframe = typeof window !== 'undefined' && window.self !== window.top

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <OpswatIcon />
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>Session Timeout</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Your session has expired due to inactivity. Please sign in to restart a session.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (isInIframe) {
                // post a message for the iframe parent to show the session expired dialog
                window.parent.postMessage(
                  { event: KEYS.IFRAME_USER_LOGOUT_SSO },
                  process.env.REACT_APP_FRONT_END_HOST ?? ''
                )
              } else {
                handleForceLogout()
              }
            }}
          >
            Sign In
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SessionTimeoutForm
