import React, { FC } from 'react'

import { InformationIcon } from '@opswat/react-icon'
import { Tooltip } from '@opswat/react-ui'

interface IProps {
  title: string
  placement?: 'top' | 'bottom'
}

const LeftMenuInfo: FC<IProps> = ({ title, placement = 'top' }) => {
  return (
    <Tooltip
      disableInteractive
      title={title}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#1B273C',
            color: '#FFFFFF'
          }
        },
        arrow: {
          sx: {
            '&::before': {
              backgroundColor: '#1B273C'
            }
          }
        }
      }}
    >
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '4px',
          marginBottom: '1px'
        }}
      >
        <InformationIcon size={16} color="#485161" />
      </span>
    </Tooltip>
  )
}

export default React.memo(LeftMenuInfo)
