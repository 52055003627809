import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  Box,
  ButtonLoading,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  TextFieldSearch,
  Typography,
  TypographyLineClamp,
  useTheme
} from '@opswat/react-ui'
import _debounce from 'lodash/debounce'
import _uniq from 'lodash/uniq'
import { useLazyPortalRolesQuery, usePortalRoleDeleteMutation } from 'myopswat-web/src/api/role'
import { IPageInfoInput, IPortalRole, IPortalRolesFiltersInput } from 'myopswat-web/src/api/role/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT, PORTAL_ROLES_DEFAULT } from 'myopswat-web/src/constants'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  selectedPortalRole: IPortalRole
}

const defaultRolesFilter = { q: '' }

const defaultPageInfo = {
  page: PAGE_DEFAULT,
  pageSize: PAGE_SIZE_DEFAULT
}

const DeleteRoleModal = NiceModal.create(({ selectedPortalRole }: IProps) => {
  const theme = useTheme()
  const { t: translate } = useTranslation()
  const modal = useModal()
  const [getPortalRoles, { data, isFetching }] = useLazyPortalRolesQuery()
  const [portalRoleDelete, { isLoading: isDeleting }] = usePortalRoleDeleteMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [alternativeRoleId, setAlternativeRoleId] = useState<string>()
  const [rolesFilter, setRolesFilter] = useState<IPortalRolesFiltersInput>(defaultRolesFilter)
  const [pageInfo, setPageInfo] = useState<IPageInfoInput>(defaultPageInfo)
  const [lazyRoles, setLazyRoles] = useState<IPortalRole[]>([])

  const onSubmit = async (formData: IPortalRole) => {
    try {
      const res = await portalRoleDelete({ id: formData.id, alternativeRoleId: alternativeRoleId }).unwrap()
      if (res.success) {
        enqueueSnackbar(translate('deletePortalRoleSuccess'), { variant: 'success' })
        modal.resolve()
        modal.hide()
      } else {
        const errorMessages = res.errors.map((e: any) => e.message)
        if (errorMessages.length > 0) enqueueSnackbar(errorMessages.join(', '), { variant: 'error' })
        else enqueueSnackbar(translate('deletePortalRoleFail'), { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const onCancel = () => {
    modal.hide()
  }

  const isLoading = useMemo(() => {
    return isDeleting || isFetching
  }, [isDeleting, isFetching])

  const handleListItemClick = (roleId: string) => {
    setAlternativeRoleId(roleId)
  }

  useEffect(() => {
    if (selectedPortalRole?.numberOfUsers > 0) {
      const handleSearch = _debounce(() => {
        getPortalRoles({ filters: rolesFilter, pageInfo: pageInfo, sortInfo: { order: 'desc', orderBy: 'isDefault' } })
      }, 500)

      handleSearch()

      return () => {
        handleSearch.cancel()
      }
    }
  }, [rolesFilter, pageInfo, selectedPortalRole])

  useEffect(() => {
    if (!isFetching && data) {
      const userRole = data.organizationPortalRoles.find(r => r.name === PORTAL_ROLES_DEFAULT.USER)
      if (userRole) setAlternativeRoleId(userRole.id)
      setLazyRoles(prev => _uniq([...prev, ...data.organizationPortalRoles]))
    }
  }, [data, isFetching])

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const bottom = (e.target as any).scrollHeight - (e.target as any).scrollTop === (e.target as any).clientHeight
    if (!isFetching && bottom && data && lazyRoles.length < data?.totalCount) {
      setPageInfo(prev => {
        return { ...prev, page: prev.page + 1 }
      })
    }
  }

  const handleTextInput = (searchValue: string) => {
    setPageInfo(defaultPageInfo)
    setLazyRoles([])
    setRolesFilter(prev => {
      return { ...prev, q: searchValue }
    })
  }

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove()
      }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle component="div" display="flex">
        <Typography variant="h3">Delete Role:&nbsp;</Typography>
        <TypographyLineClamp
          line={1}
          variant="h3"
          sx={{
            maxWidth: 280
          }}
          style={{ display: '-webkit-inline-box' }}
          tooltipValue={selectedPortalRole.name}
        >
          {selectedPortalRole.name}
        </TypographyLineClamp>
      </DialogTitle>
      <DialogContent sx={{ fontWeight: 'normal' }}>
        <div>Are you sure you want to delete this role</div>
        {selectedPortalRole && selectedPortalRole.numberOfUsers > 0 && (
          <Box display="flex">
            Select a role to transfer users from '
            {
              <TypographyLineClamp
                line={1}
                sx={{
                  fontWeight: 'normal',
                  maxWidth: 150
                }}
                style={{ display: '-webkit-inline-box' }}
                tooltipValue={selectedPortalRole.name}
              >
                {selectedPortalRole.name}
              </TypographyLineClamp>
            }
            ' to
          </Box>
        )}
      </DialogContent>
      {selectedPortalRole && selectedPortalRole.numberOfUsers > 0 && (
        <DialogContent>
          <TextFieldSearch
            value={rolesFilter.q}
            placeholder={translate('filterRoleNameDescription') || ''}
            onChange={(e: any) => {
              if (e?.target) {
                handleTextInput(e.target.value)
              }
            }}
            onClearText={() => {
              handleTextInput('')
            }}
          />
          <List
            sx={{
              overflow: 'auto',
              maxHeight: 300
            }}
            onScroll={handleScroll}
          >
            {lazyRoles
              .filter(role => role.id !== selectedPortalRole.id)
              .map(role => {
                return (
                  <ListItem
                    key={role.id}
                    disablePadding
                    sx={{
                      backgroundColor: `${theme.palette.divider}`
                    }}
                  >
                    <ListItemButton
                      selected={alternativeRoleId === role.id}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: `${theme.palette.primary.main}`,
                          color: 'white',
                          '&:hover': {
                            backgroundColor: `${theme.palette.primary.main}`,
                            color: 'white'
                          }
                        },
                        '&:hover': {
                          backgroundColor: `${theme.palette.primary.main}`,
                          color: 'white'
                        }
                      }}
                      onClick={() => handleListItemClick(role.id)}
                    >
                      <TypographyLineClamp
                        style={{ color: 'unset', fontWeight: 'normal' }}
                        line={1}
                        tooltipValue={role.name}
                      >
                        {role.name}
                      </TypographyLineClamp>
                    </ListItemButton>
                  </ListItem>
                )
              })}
          </List>
        </DialogContent>
      )}
      <DialogActions sx={{ p: '1rem' }}>
        <ButtonLoading
          propsButton={{
            variant: 'text',
            color: 'inherit',
            onClick: onCancel,
            disabled: isLoading
          }}
        >
          Cancel
        </ButtonLoading>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: () => onSubmit(selectedPortalRole),
            disabled: isLoading
          }}
          isLoading={isLoading}
        >
          Yes
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  )
})

export default DeleteRoleModal
