import { CSSProperties, FC } from 'react'

import { Box, Tooltip, Typography, TypographyLineClamp } from '@opswat/react-ui'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import _get from 'lodash/get'

interface IProps {
  mainLabel: string
  subLabel: string
  sfdcData: any
  sx?: CSSProperties
  itemData?: any
  isTopLevelOrgLabel?: boolean
}

const ResellerNameLabel: FC<{ name: string }> = ({ name }) => {
  return (
    <TypographyLineClamp line={1} variant="body2" tooltipValue={name} tooltipPlacement="top">
      Reseller: {name}
    </TypographyLineClamp>
  )
}

const CustomerTooltipSubOrgLabel: FC<IProps> = ({
  mainLabel,
  subLabel,
  sfdcData,
  itemData,
  sx,
  isTopLevelOrgLabel
}) => {
  const oppCloseDate = _get(sfdcData, 'CloseDate')
  const oppType = _get(sfdcData, 'Type__c', '--')
  const formattedDate = formatDatetime(oppCloseDate, DATE_FORMATS.DATE)
  const tooltipTitle = `${mainLabel}
    ${formattedDate} - ${oppType}
    Organization Name: ${subLabel}
  `

  const isShowReseller = _get(itemData, 'is_show_reseller') || _get(itemData, 'isShowReseller')
  const resellerName = _get(itemData, 'reseller_name') || _get(itemData, 'resellerName')

  return mainLabel ? (
    <Box>
      <Tooltip title={tooltipTitle}>
        <Typography
          style={{
            wordBreak: 'break-all',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...sx
          }}
        >
          {mainLabel}
          <span>{` (${formattedDate} - ${oppType} - ${subLabel?.substring(0, 5)}...)`}</span>
        </Typography>
      </Tooltip>

      {!isTopLevelOrgLabel && isShowReseller && resellerName && <ResellerNameLabel name={resellerName} />}
    </Box>
  ) : (
    <Box>
      <TypographyLineClamp line={1} variant="body2" tooltipValue={subLabel} tooltipPlacement="top" sx={sx}>
        {subLabel}
      </TypographyLineClamp>

      {!isTopLevelOrgLabel && isShowReseller && resellerName && <ResellerNameLabel name={resellerName} />}
    </Box>
  )
}

export default CustomerTooltipSubOrgLabel
