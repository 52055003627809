import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _debounce from 'lodash/debounce'

import { PERMISSIONS } from '@myopswat/common'
import { Button, Grid, TextFieldSearch } from '@opswat/react-ui'

import { IPortalRolesFiltersInput } from 'myopswat-web/src/api/role/types'
import { useCheckPermission } from 'myopswat-web/src/hooks'

interface IProps {
  children?: React.ReactNode
  setRolesFilter: Dispatch<SetStateAction<IPortalRolesFiltersInput>>
  onClickNew: () => void
}

const BoxRolesFilter: FC<IProps> = ({ setRolesFilter, onClickNew }) => {
  const { t: translate } = useTranslation()
  const { hasPermission } = useCheckPermission()
  const [keyword, setKeyword] = useState<string>('')
  const hasFullOrgRolePerm = hasPermission(PERMISSIONS.FULL_MY_ORGANIZATIONS_ROLE)

  useEffect(() => {
    if (keyword || keyword === '') {
      const handleSearch = _debounce(() => {
        setRolesFilter((prev: IPortalRolesFiltersInput) => {
          return {
            ...prev,
            q: keyword
          }
        })
      }, 500)

      handleSearch()

      return () => {
        handleSearch.cancel()
      }
    }
  }, [keyword])

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={4}>
        <TextFieldSearch
          value={keyword}
          placeholder={translate('filterRoleNameDescription') || ''}
          onChange={(e: any) => {
            if (e?.target) {
              setKeyword(e.target.value)
            }
          }}
          onClearText={() => setKeyword('')}
        />
      </Grid>

      {hasFullOrgRolePerm && (
        <Grid item xs="auto">
          <Button variant="contained" onClick={onClickNew} color="primary">
            Add Role
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default BoxRolesFilter
