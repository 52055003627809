import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _map from 'lodash/map'

import { SolidDownIcon } from '@opswat/react-icon'
import {
  Button,
  CheckboxWithLabel,
  Divider,
  DropdownFilter,
  Grid,
  TextFieldSearch,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'

import {
  ALL_VALUE_OPTION,
  LICENSE_AVAILABILITY_OPTIONS,
  LICENSE_NEAR_CAP_OPTIONS,
  LICENSE_STATUS_OPTIONS,
  LICENSE_TYPE_OPTIONS
} from '../../../MyLicensesPage/constants'

interface IProps {
  productOptions: any[]
  currentFilters: any
  setCurrentFilters: (data: any) => void
  handleSearchKeyword: (keyword: string) => void
}

const CustomerLicensedProductsFilter: FC<IProps> = ({
  currentFilters,
  productOptions,
  setCurrentFilters,
  handleSearchKeyword
}) => {
  const [keyword, setKeyword] = useState<string>()
  const [productKeyword, setProductKeyword] = useState<string>()

  const { control, handleSubmit, reset, setValue } = useForm<any>({
    defaultValues: {
      q: '',
      productIds: [],
      licenseStatuses: [],
      licenseType: [],
      licenseAvailability: [],
      nearCapacity: []
    }
  })

  const searchParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search])

  const filteredProducts = useMemo(() => {
    return productOptions
      ?.filter((product: any) => product.name.toLowerCase().includes(productKeyword?.toLowerCase() ?? ''))
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
  }, [productOptions, productKeyword])

  const handleSetCurrentFilters = useCallback((filters: any) => {
    Object.entries(filters).forEach(([key, value]: any) => {
      if (key === 'licenseType') {
        if (value === ALL_VALUE_OPTION) {
          // default option, no options are checked
          setValue(key, [])
        } else {
          // chosen options, all options are checked
          setValue(key, value ? [value] : LICENSE_TYPE_OPTIONS.map((option: any) => option.value))
        }
      } else if (key === 'nearCapacity') {
        setValue(key, value ? ['near_cap'] : [])
      } else {
        setValue(key, value)
      }
    })
  }, [])

  const handleMapCheckbox = useCallback((checked: boolean, currentValue: any[], item: any) => {
    return checked ? [...currentValue, item.value] : currentValue.filter((i: any) => i !== item.value)
  }, [])

  const handleGetLicenseType = useCallback((licenseType: string[]) => {
    const length = licenseType.length
    if (length === LICENSE_TYPE_OPTIONS.length) {
      return ''
    } else if (length === 0) {
      return ALL_VALUE_OPTION
    }
    return licenseType.at(0)
  }, [])

  const onSuccess = (data: any) => {
    setCurrentFilters({
      ...data,
      licenseType: handleGetLicenseType(data.licenseType),
      nearCapacity: data.nearCapacity.includes('near_cap')
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  const handleOnChangeCheckbox = useCallback((onChange: any, e: any, value: any, item: any) => {
    onChange(e.target.checked ? [...value, item.id] : value.filter((i: any) => i !== item.id))
  }, [])

  const renderProductCheckbox = useCallback((item: any, idx: number) => {
    return (
      <Grid item xs={6} key={idx.toString()}>
        <Controller
          name="productIds"
          control={control}
          render={({ field: { value, onChange } }: any) => (
            <CheckboxWithLabel
              label={
                <Typography variant="subtitle2" marginTop="2px">
                  {item.name}
                </Typography>
              }
              checkboxProps={{
                checked: value.includes(item.id),
                onChange: (e: any) => {
                  handleOnChangeCheckbox(onChange, e, value, item)
                }
              }}
              restProps={{
                sx: {
                  marginLeft: '-6px',
                  alignItems: 'flex-start'
                }
              }}
            />
          )}
        />
      </Grid>
    )
  }, [])

  const renderStatusCheckbox = useCallback(
    (item: any, idx: number) => {
      return (
        <Grid item xs={4} key={idx.toString()}>
          <Controller
            name="licenseStatuses"
            control={control}
            render={({ field: { value, onChange } }: any) => (
              <CheckboxWithLabel
                label={
                  <Typography variant="subtitle2" marginTop="2px">
                    {item.label}
                  </Typography>
                }
                checkboxProps={{
                  checked: value.includes(item.value),
                  onChange: (e: any) => {
                    onChange(handleMapCheckbox(e.target.checked, value, item))
                  }
                }}
                restProps={{
                  sx: {
                    marginLeft: '-6px'
                  }
                }}
              />
            )}
          />
        </Grid>
      )
    },
    [handleMapCheckbox]
  )

  const renderTypeCheckbox = useCallback(
    (item: any, idx: number) => {
      return (
        <Grid item xs={4} key={idx.toString()}>
          <Controller
            name="licenseType"
            control={control}
            render={({ field: { value, onChange } }: any) => (
              <CheckboxWithLabel
                label={
                  <Typography variant="subtitle2" marginTop="2px">
                    {item.label}
                  </Typography>
                }
                checkboxProps={{
                  checked: value.includes(item.value),
                  onChange: (e: any) => {
                    onChange(handleMapCheckbox(e.target.checked, value, item))
                  }
                }}
                restProps={{
                  sx: {
                    marginLeft: '-6px'
                  }
                }}
              />
            )}
          />
        </Grid>
      )
    },
    [handleMapCheckbox]
  )

  const renderAvailabilityCheckbox = useCallback(
    (item: any, idx: number) => {
      return (
        <Grid item xs={4} key={idx.toString()}>
          <Controller
            name="licenseAvailability"
            control={control}
            render={({ field: { value, onChange } }: any) => (
              <CheckboxWithLabel
                label={
                  <Typography variant="subtitle2" marginTop="2px">
                    {item.label}
                  </Typography>
                }
                checkboxProps={{
                  checked: value.includes(item.value),
                  onChange: (e: any) => {
                    onChange(handleMapCheckbox(e.target.checked, value, item))
                  }
                }}
                restProps={{
                  sx: {
                    marginLeft: '-6px'
                  }
                }}
              />
            )}
          />
        </Grid>
      )
    },
    [handleMapCheckbox]
  )

  const renderCapacityCheckbox = useCallback(
    (item: any, idx: number) => {
      return (
        <Grid item xs={4} key={idx.toString()}>
          <Controller
            name="nearCapacity"
            control={control}
            render={({ field: { value, onChange } }: any) => (
              <CheckboxWithLabel
                label={
                  <Typography variant="subtitle2" marginTop="2px">
                    {item.label}
                  </Typography>
                }
                checkboxProps={{
                  checked: value.includes(item.value),
                  onChange: (e: any) => {
                    onChange(handleMapCheckbox(e.target.checked, value, item))
                  }
                }}
                restProps={{
                  sx: {
                    marginLeft: '-6px'
                  }
                }}
              />
            )}
          />
        </Grid>
      )
    },
    [handleMapCheckbox]
  )

  const renderFilterText = useCallback(
    (key: string, label: string, options: any[]) => {
      const values = _get(currentFilters, key, [])

      if (key === 'licenseType') {
        if (values === '') {
          return `${label} = ${options.map((item: any) => item.label).join(', ')}`
        }
        if (values === ALL_VALUE_OPTION) {
          return ''
        }
        return `${label} = ${options.find((item: any) => item.value === values)?.label}`
      }
      if (key === 'nearCapacity') {
        return values ? `${label} = > 80%` : ''
      }

      return values?.length > 0
        ? `${label} = ${values
            ?.map((item: any) => options.find((option: any) => option.value === item)?.label)
            .join(', ')}`
        : ''
    },
    [currentFilters]
  )

  const renderAllFilterText = useCallback(() => {
    const productFilter = renderFilterText(
      'productIds',
      'Products',
      productOptions ? productOptions.map((item: any) => ({ label: item.name, value: item.id })) : []
    )
    const statusFilter = renderFilterText('licenseStatuses', 'Status', LICENSE_STATUS_OPTIONS)
    const typeFilter = renderFilterText('licenseType', 'Type', LICENSE_TYPE_OPTIONS)
    const availabilityFilter = renderFilterText('licenseAvailability', 'Availability', LICENSE_AVAILABILITY_OPTIONS)
    const capacityFilter = renderFilterText('nearCapacity', 'Capacity', LICENSE_NEAR_CAP_OPTIONS)

    return [productFilter, statusFilter, typeFilter, availabilityFilter, capacityFilter]
      .filter((item: any) => item)
      .join('; ')
  }, [productOptions, renderFilterText])

  useEffect(() => {
    const productQuery = searchParams.get('q')
    productQuery && setKeyword(productQuery)
  }, [searchParams])

  useEffect(() => {
    if (keyword || keyword === '') {
      const handleSearch = _debounce(() => {
        handleSearchKeyword(keyword)
      }, 500)

      handleSearch()

      return () => {
        handleSearch.cancel()
      }
    }
  }, [keyword])

  useEffect(() => {
    handleSetCurrentFilters(currentFilters)
  }, [currentFilters])

  return (
    <Grid item container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={10} md={6} lg={4}>
        <TextFieldSearch
          value={keyword}
          placeholder={'Search by product name, license key...'}
          onChange={(e: any) => {
            setKeyword(e.target.value)
          }}
          onClearText={() => setKeyword('')}
          sx={{
            minHeight: 'auto'
          }}
        />
      </Grid>
      <Grid item container xs="auto" alignItems="center">
        <Divider
          sx={{
            height: '20px',
            width: '1px',
            backgroundColor: '#E9ECF2',
            marginRight: '8px'
          }}
        />
        <DropdownFilter
          resetOnSubmit={false}
          propsPopper={{
            placement: 'bottom-start'
          }}
          propsPaper={{
            style: {
              width: '600px'
            }
          }}
          button={
            <Button color="inherit" variant="text" endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <Grid container spacing={2}>
              {/* product filter */}
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12} spacing={1} alignItems="center">
                  <Grid item xs={1.5}>
                    <Typography variant="subtitle1">Product</Typography>
                  </Grid>
                  <Grid item xs={6.5} gap={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextFieldSearch
                      value={productKeyword}
                      placeholder={'Search products...'}
                      onChange={(e: any) => {
                        if (_get(e, 'target')) {
                          setProductKeyword(e.target.value)
                        }
                      }}
                      onClearText={() => setProductKeyword('')}
                      sx={{
                        minHeight: 'auto'
                      }}
                    />
                    <Divider
                      sx={{
                        height: '20px',
                        width: '1px',
                        backgroundColor: '#D2D4D6'
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="text"
                      color="inherit"
                      onClick={() => {
                        setValue('productIds', [])
                      }}
                    >
                      Clear Selected Products
                    </Button>
                  </Grid>
                </Grid>
                {_map(filteredProducts, (item: any, idx: any) => renderProductCheckbox(item, idx))}
                <Grid item xs={12}>
                  <Divider sx={{ borderColor: '#D2D4D6' }} />
                </Grid>
              </Grid>
              {/* status filter */}
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Status</Typography>
                </Grid>
                {_map(LICENSE_STATUS_OPTIONS, (item: any, idx: any) => renderStatusCheckbox(item, idx))}
                <Grid item xs={12}>
                  <Divider sx={{ borderColor: '#D2D4D6' }} />
                </Grid>
              </Grid>
              {/* type filter */}
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Type</Typography>
                </Grid>
                {_map(LICENSE_TYPE_OPTIONS, (item: any, idx: any) => renderTypeCheckbox(item, idx))}
                <Grid item xs={12}>
                  <Divider sx={{ borderColor: '#D2D4D6' }} />
                </Grid>
              </Grid>
              {/* availability filter */}
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Availability</Typography>
                </Grid>
                {_map(LICENSE_AVAILABILITY_OPTIONS, (item: any, idx: any) => renderAvailabilityCheckbox(item, idx))}
                <Grid item xs={12}>
                  <Divider sx={{ borderColor: '#D2D4D6' }} />
                </Grid>
              </Grid>
              {/* near cap filter */}
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Capacity</Typography>
                </Grid>
                {_map(LICENSE_NEAR_CAP_OPTIONS, (item: any, idx: any) => renderCapacityCheckbox(item, idx))}
                <Grid item xs={12}>
                  <Divider sx={{ borderColor: '#D2D4D6' }} />
                </Grid>
              </Grid>
            </Grid>
          }
          onResetFilter={() => {
            reset({
              q: keyword?.trim(),
              productIds: [],
              licenseStatuses: [],
              licenseType: [],
              licenseAvailability: [],
              nearCapacity: []
            })
          }}
          onResetChange={() => {
            handleSetCurrentFilters(currentFilters)
          }}
          onApply={() => {
            handleSubmit(onSuccess, onFail)()
          }}
        />
      </Grid>
      {(keyword || renderAllFilterText()) && (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {keyword && (
            <Typography variant="body1">
              Search:{' '}
              <Typography component="span" variant="body2">
                {keyword}
              </Typography>
            </Typography>
          )}
          {renderAllFilterText() && (
            <TypographyLineClamp line={1} variant="body1" tooltipValue={renderAllFilterText()} tooltipPlacement="top">
              <span>
                Filter:{' '}
                <Typography component="span" variant="body2">
                  {renderAllFilterText()}
                </Typography>
              </span>
            </TypographyLineClamp>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default CustomerLicensedProductsFilter
