import { apiGraphql } from 'myopswat-web/src/api/base-query'
import { AcademyProfile } from './type'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    getAcademyUserProfile: builder.query<AcademyProfile, void>({
      query: () => ({
        document: `
            query {
              academySummary {
                userType
                totalEnrolledCourses
                totalCompletedCourses
                totalAvailableCourses
                isAllRecommendCoursesCompleted
                completedCourses {
                  courseName
                  courseIconBadgeUrl
                  courseUrl
                  expiresAt
                }
                ongoingCourses {
                  courseName
                  totalLesson
                  totalLessonComplete
                  percentComplete
                  courseIconBadgeUrl
                  courseUrl
                }
                recommendCourses {
                  courseName
                  lessons
                  duration
                  description
                  courseUrl
                  imageCourseUrl
                  isEnroll
                }
                academyAnalytics {
                  registeredUserCount
                  totalUserEnrolled
                  totalUserCompleted
                  enterpriseAcademyPurchases
                }
              }
            }
          `,
        variables: {}
      })
    })
  })
})

export const { useGetAcademyUserProfileQuery } = api
