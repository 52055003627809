export function executeTimedCondition(
  handleCheckCondition: () => boolean,
  callback: () => void,
  timeout = 10000, // 10s
  interval = 1000 // 1s
) {
  const startTime = Date.now()

  const checkInterval = () => {
    if (handleCheckCondition()) {
      return
    }

    const elapsed = Date.now() - startTime
    if (elapsed >= timeout) {
      callback()
    } else {
      setTimeout(checkInterval, interval)
    }
  }

  setTimeout(checkInterval, interval)
}
