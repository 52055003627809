import { PERMISSIONS } from '@myopswat/common'
import { Grid, TypographyLineClamp } from '@opswat/react-ui'

import { useCheckPermission } from 'myopswat-web/src/hooks'

import UserAction from '../../actions'
import { OrganizationUser, UserActionProps } from '../type'

interface UserRowProps extends UserActionProps {
  user: OrganizationUser
  level: number
}

const UserRow = ({ user, level, onChangeRole, onRemoveUser, onSetSuperAdmin }: UserRowProps) => {
  const { hasPermission } = useCheckPermission()

  const hasFullOrgUsersPerm = hasPermission(PERMISSIONS.FULL_MY_ORGANIZATIONS_USERS)
  const handleChangeRole = () => onChangeRole(user)
  const handleRemoveUser = () => onRemoveUser(user)
  const handleSetSuperAdmin = () => onSetSuperAdmin?.(user)

  return (
    <Grid container sx={{ padding: '4px', alignItems: 'center', borderBottom: '1px solid #E9EAEB', minHeight: '40px' }}>
      <Grid item xs sx={{ paddingLeft: `${level * 20 + 20}px` }}>
        <TypographyLineClamp
          variant="body2"
          line={1}
          tooltipValue={user.name}
          tooltipPlacement="top"
          textTransform="capitalize"
        >
          {user.name}
        </TypographyLineClamp>
      </Grid>
      <Grid item xs={2}>
        <TypographyLineClamp variant="body2" line={1} tooltipValue={user.email} tooltipPlacement="top">
          {user.email}
        </TypographyLineClamp>
      </Grid>
      <Grid item xs={1}>
        <TypographyLineClamp
          variant="body2"
          line={1}
          tooltipValue={user.isActive ? 'Active' : 'Pending'}
          tooltipPlacement="top"
          sx={{
            color: user.isActive ? 'success.dark' : 'warning.dark'
          }}
        >
          {user.isActive ? 'Active' : 'Pending'}
        </TypographyLineClamp>
      </Grid>
      <Grid item xs={1}>
        <TypographyLineClamp variant="body2" line={1} tooltipValue={user.role} tooltipPlacement="top">
          {user.role}
        </TypographyLineClamp>
      </Grid>
      <Grid item xs={2}>
        <TypographyLineClamp variant="body2" line={1} tooltipValue={user.lastLogin} tooltipPlacement="top">
          {user.lastLogin}
        </TypographyLineClamp>
      </Grid>
      <Grid item xs={1} display="flex" justifyContent="flex-end">
        {level !== 0 || user.isLoginUser || user.isSuperAdmin || !hasFullOrgUsersPerm ? null : (
          <UserAction
            isActive={user.isActive}
            onChangeRole={handleChangeRole}
            onRemoveUser={handleRemoveUser}
            onSetSuperAdmin={onSetSuperAdmin ? handleSetSuperAdmin : undefined}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default UserRow
