import { Grid, Typography } from '@opswat/react-ui'
import { useCallback } from 'react'
import ReactApexChart from 'react-apexcharts'
import { renderToString } from 'react-dom/server'
import { getSeriesPercentages } from '../../LicensedProductsPage/CustomerLicensesPage/sections/ChartSection/utils'

interface IProps {
  id: string
  title: string
  description: string
  centerTitle: string
  centerColor: string
  colors: string[]
  labels: string[]
  statistics: Record<string, any>
}
const AcademyDonutChart = ({
  id,
  title,
  description,
  centerTitle,
  centerColor,
  colors,
  labels,
  statistics
}: IProps) => {
  const handleRenderDonutChart = useCallback(() => {
    return (
      <ReactApexChart
        id={id}
        series={Object.values(statistics)}
        type="donut"
        width="200px"
        height="200px"
        options={{
          colors: colors,
          labels: labels,
          plotOptions: {
            pie: {
              donut: {
                size: '60%',
                labels: {
                  show: true,
                  name: {
                    show: false
                  },
                  value: {
                    show: true,
                    fontSize: '28px',
                    fontWeight: 500,
                    color: centerColor,
                    formatter: () => centerTitle
                  },
                  total: {
                    show: true,
                    fontSize: '28px',
                    fontWeight: 500,
                    color: centerColor,
                    formatter: () => centerTitle
                  }
                }
              }
            }
          },
          dataLabels: { enabled: false },
          legend: { show: false },
          tooltip: {
            custom: ({ series, seriesIndex }) => {
              const percentages = getSeriesPercentages(series, 0)
              return renderToString(
                <div className="custom-chart-tooltip">
                  <span className="custom-chart-tooltip-title">{percentages[seriesIndex]}%</span>
                  <span>{labels[seriesIndex]}</span>
                  <span className="custom-chart-tooltip-content">
                    {series[seriesIndex]} Course{series[seriesIndex] > 1 && 's'}
                  </span>
                </div>
              )
            }
          },
          states: {
            active: {
              filter: {
                type: 'none'
              }
            },
            hover: {
              filter: {
                type: 'lighten',
                value: 0.1
              }
            }
          },
          responsive: [
            {
              breakpoint: 1300,
              options: { chart: { width: 180, height: 180 } }
            },
            {
              breakpoint: 1200,
              options: { chart: { width: 170, height: 170 } }
            },
            {
              breakpoint: 1100,
              options: { chart: { width: 150, height: 150 } }
            },
            {
              breakpoint: 1024,
              options: { chart: { width: 140, height: 140 } }
            },
            {
              breakpoint: 800,
              options: {
                chart: { width: 128, height: 128 },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        value: {
                          fontSize: '20px',
                          offsetY: 8
                        },
                        total: {
                          fontSize: '20px'
                        }
                      }
                    }
                  }
                }
              }
            }
          ]
        }}
      />
    )
  }, [id, statistics, colors, labels])

  return (
    <Grid container justifyContent="center" alignItems="center" flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
      <Grid item>{handleRenderDonutChart()}</Grid>
      <Grid
        item
        alignSelf={{
          xs: 'center',
          md: 'flex-start'
        }}
        textAlign={{ xs: 'center', md: 'left' }}
      >
        <Typography variant="subtitle1" textTransform="uppercase">
          {title}
        </Typography>
        <Typography fontSize="12px" fontWeight={400} whiteSpace="pre-wrap" component="div">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AcademyDonutChart
