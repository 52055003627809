import { useModal } from '@ebay/nice-modal-react'
import { TemplateSection } from '@opswat/react-ui'
import { useCallback, useEffect, useState } from 'react'
import { useLazyPortalRolesQuery } from '../../../../../api/role'
import {
  IPageInfoInput,
  IPortalRole,
  IPortalRolesFiltersInput,
  ISortInfoInput,
  Order
} from '../../../../../api/role/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from '../../../../../constants'
import BoxRolesFilter from '../../BoxRolesFilter'
import BoxRolesTable from '../../BoxRolesTable'
import CreateUpdateRoleModal from '../../CreateUpdateRole/CreateUpdateRoleModal'
import DeleteRoleModal from '../../DeleteRole/DeleteRoleModal'

interface IProps {
  permissions: string[]
  organization: any
}

const defaultRolesFilter = { q: '', excludeSuperAdmin: false }
const defaultSortInfo = {
  order: 'desc' as Order,
  orderBy: 'updatedAt'
}
const defaultPageInfo = {
  page: PAGE_DEFAULT,
  pageSize: PAGE_SIZE_DEFAULT
}

const SingleOrgPortalRoleView = ({ permissions, organization }: IProps) => {
  const [rolesFilter, setRolesFilter] = useState<IPortalRolesFiltersInput>(defaultRolesFilter)
  const [sortInfo, setSortInfo] = useState<ISortInfoInput>(defaultSortInfo)
  const [pageInfo, setPageInfo] = useState<IPageInfoInput>(defaultPageInfo)

  const [getPortalRoles, { data, isFetching }] = useLazyPortalRolesQuery()

  const updateState = useCallback(
    <T,>(setter: React.Dispatch<React.SetStateAction<T>>) =>
      (newState: T | ((prevState: T) => T)) => {
        setter(prev => {
          const nextState = typeof newState === 'function'
            ? (newState as (prevState: T) => T)(prev)
            : newState
          return JSON.stringify(prev) !== JSON.stringify(nextState) ? nextState : prev
        })
      },
    []
  )

  const updateRolesFilter = updateState(setRolesFilter)
  const updateSortInfo = updateState(setSortInfo)
  const updatePageInfo = updateState(setPageInfo)

  useEffect(() => {
    getPortalRoles({ filters: rolesFilter, sortInfo, pageInfo })
  }, [rolesFilter, sortInfo, pageInfo, getPortalRoles])

  // Modals
  const createUpdateRoleModal = useModal(CreateUpdateRoleModal)
  const deleteRoleModal = useModal(DeleteRoleModal)

  const handleClickNew = useCallback(() => {
    createUpdateRoleModal.show({ organization, isSingleOrg: true }).then(() => {
      getPortalRoles({ filters: rolesFilter, sortInfo, pageInfo })
    })
  }, [createUpdateRoleModal, organization, rolesFilter, sortInfo, pageInfo, getPortalRoles])

  const handleClickEdit = useCallback(
    (selectedPortalRole: IPortalRole) => {
      createUpdateRoleModal
        .show({ selectedPortalRoleId: selectedPortalRole.id, organization, isSingleOrg: true })
        .then(() => {
          getPortalRoles({ filters: rolesFilter, sortInfo, pageInfo })
        })
    },
    [createUpdateRoleModal, organization, rolesFilter, sortInfo, pageInfo, getPortalRoles]
  )

  const handleClickDetail = useCallback(
    (selectedPortalRole: IPortalRole) => {
      createUpdateRoleModal
        .show({ selectedPortalRoleId: selectedPortalRole.id, viewOnly: true, organization, isSingleOrg: true })
        .then(() => {
          getPortalRoles({ filters: rolesFilter, sortInfo, pageInfo })
        })
    },
    [createUpdateRoleModal, organization, rolesFilter, sortInfo, pageInfo, getPortalRoles]
  )

  const handleClickDelete = useCallback(
    (selectedPortalRole: IPortalRole) => {
      if (data?.organizationPortalRoles) {
        deleteRoleModal.show({ selectedPortalRole }).then(() => {
          getPortalRoles({ filters: rolesFilter, sortInfo, pageInfo })
        })
      }
    },
    [deleteRoleModal, data, rolesFilter, sortInfo, pageInfo, getPortalRoles]
  )

  return (
    <TemplateSection spacing={2}>
      <BoxRolesFilter setRolesFilter={updateRolesFilter} onClickNew={handleClickNew} />
      <BoxRolesTable
        portalRolesData={data}
        isFetchingRoles={isFetching}
        setSortInfo={updateSortInfo}
        sortInfo={sortInfo}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
        onClickDetail={handleClickDetail}
        setPageInfo={updatePageInfo}
        pageInfo={pageInfo}
        permissions={permissions}
      />
    </TemplateSection>
  )
}

export default SingleOrgPortalRoleView
