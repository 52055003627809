import { Box, Button, Tooltip, Typography } from '@opswat/react-ui'
import _random from 'lodash/random'
import { FC, ReactNode, useMemo } from 'react'

interface IProps {
  data: any[]
  containerTitle?: string
  containerSx?: any
  onRenderContainerBody?: () => ReactNode
  barHeight?: number
  maxCategory?: number
  onRenderTooltip?: (name: string, count: number) => ReactNode
  chartTitle?: string | null
  actionBtnTitle?: string
  hint?: string
  onClickActionBtn?: () => void
  colors?: string[]
  highlightBarIndex?: number
}

const NO_DATA_HINT = 'No data available'
const defaultColors = ['#e5632a', '#7f2cd7', '#ae2a7f', '#308592']
const CONTAINER_DEFAULT_MAX_WIDTH = 300

const StackBarChart: FC<IProps> = ({
  data,
  containerTitle,
  containerSx,
  barHeight = 10,
  colors = defaultColors,
  maxCategory = 4,
  onRenderContainerBody,
  onRenderTooltip,
  chartTitle = 'By organizations',
  actionBtnTitle = 'View all',
  hint,
  onClickActionBtn,
  highlightBarIndex
}) => {
  const dataNotEmpty = data != null && data.length > 0
  containerSx = { maxWidth: CONTAINER_DEFAULT_MAX_WIDTH, ...containerSx }
  const totalCount = useMemo(() => {
    if (dataNotEmpty) {
      return data.reduce((prev, current) => prev + current.count, 0)
    }
    return 0
  }, [data])

  if (!hint || totalCount <= 0) hint = NO_DATA_HINT

  const calculateWidth = (count: number) => {
    if (count / totalCount < 0.1) return 0.1 * containerSx.maxWidth
    else return (count / totalCount) * containerSx.maxWidth
  }

  const mapData: any[] = []

  if (dataNotEmpty) {
    for (let i = 0; i < data.length; i++) {
      if (i < maxCategory - 1) {
        mapData.push(data[i])
      } else {
        mapData.push({
          name: 'Other',
          count: data.slice(i, data.length).reduce((prev, current) => prev + current.count, 0)
        })
        break
      }
    }
  }

  const renderTooltipTitle = (name: string, count: number) => {
    if (onRenderTooltip) return onRenderTooltip(name, count)
    else {
      return (
        <Box
          sx={{
            padding: '4px 8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px'
          }}
        >
          <Typography fontSize="12px" fontWeight={500} color="#1B273C" lineHeight="16px" textAlign="center">
            {name}
          </Typography>
          <Typography fontSize="12px" fontWeight={400} color="#1B273C" lineHeight="16px" textAlign="center">
            Total no. of near-caps
            <Typography
              component="span"
              fontSize="12px"
              fontWeight="400"
              lineHeight="16px"
              textAlign="center"
              color="#ED6706"
              marginLeft="8px"
            >
              {count}
            </Typography>
          </Typography>
        </Box>
      )
    }
  }

  const barColor = (index: number) => {
    if (highlightBarIndex !== undefined) {
      return highlightBarIndex === index ? colors[index] : '#E9EAEB'
    } else {
      return colors[index]
        ? colors[index]
        : '#000000'.replace(/0/g, function () {
            return (~~(_random(0.1, 0.9, true) * 16)).toString(16)
          })
    }
  }

  const renderIfHaveData = (data: any[]) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-start' }}>
        {chartTitle && (
          <Typography fontSize="12px" fontWeight={600} color="#707682" lineHeight="16px">
            {chartTitle}
          </Typography>
        )}
        <Box display="flex" sx={{ width: '100%' }}>
          {data.map((d, index) => {
            return (
              <Tooltip key={crypto.randomUUID()} title={renderTooltipTitle(d.name, d.count)}>
                <Box
                  sx={{
                    height: barHeight,
                    width: calculateWidth(d.count),
                    background: barColor(index)
                  }}
                />
              </Tooltip>
            )
          })}
        </Box>
        {hint && (
          <Typography fontSize="12px" fontWeight={400} color="#707682" lineHeight="16px">
            {hint}
          </Typography>
        )}
        {onClickActionBtn && (
          <Button sx={{ width: 'fit-content', textTransform: 'none' }} onClick={onClickActionBtn}>
            {actionBtnTitle}
          </Button>
        )}
      </Box>
    )
  }

  return (
    <Box sx={containerSx}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-start' }}>
        <Typography variant="subtitle1" color="#707682">
          {containerTitle}
        </Typography>
        {onRenderContainerBody?.()}
      </Box>
      {mapData.length > 0 && renderIfHaveData(mapData)}
      {mapData.length === 0 && (
        <Typography sx={{ mt: 2 }} fontSize="12px" color="#707682">
          No data available
        </Typography>
      )}
    </Box>
  )
}

export default StackBarChart
