import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import _get from 'lodash/get'
import _remove from 'lodash/remove'

import { handleUpdateObjectById } from '@myopswat/common'

import { BannerType } from 'myopswat-web/src/api/account/types'

import type { RootState } from '../../store'

type HomeState = {
  products: any[]
  interestedProducts: any[]
  latestNews: any[]
  productSubscriptionEntries: any[]
  dismissedBanners: BannerType[]
}

const initialState: HomeState = {
  products: [],
  interestedProducts: [],
  latestNews: [],
  productSubscriptionEntries: [],
  dismissedBanners: []
}

const home = createSlice({
  name: 'home',
  initialState,
  reducers: {
    saveProducts: (state, action: PayloadAction<any>) => {
      state.products = action.payload || []
    },
    saveInterestedProducts: (state, action: PayloadAction<any>) => {
      state.interestedProducts = action.payload || []
    },
    addInterestedProducts: (state, action: PayloadAction<any>) => {
      const newData = Object.assign({}, action.payload, { interested: true })
      state.interestedProducts.unshift(newData)
      state.products = handleUpdateObjectById(state.products, newData)
    },
    removeInterestedProducts: (state, action: PayloadAction<any>) => {
      const newData = Object.assign({}, action.payload, { interested: false })
      state.interestedProducts = _remove(state.interestedProducts, (item: any) => item.id !== _get(newData, 'id', ''))
      state.products = handleUpdateObjectById(state.products, newData)
    },
    saveLatestNews: (state, action: PayloadAction<any>) => {
      state.latestNews = action.payload || []
    },
    saveProductSubscriptionEntries: (state, action: PayloadAction<any>) => {
      state.productSubscriptionEntries = action.payload || []
    },
    addSeenEntries: (state, action: PayloadAction<any>) => {
      const newData = Object.assign({}, action.payload, { seen: true })
      state.latestNews = handleUpdateObjectById(state.latestNews, newData)
      state.productSubscriptionEntries = handleUpdateObjectById(state.productSubscriptionEntries, newData)
    },
    removeSeenEntries: (state, action: PayloadAction<any>) => {
      const newData = Object.assign({}, action.payload, { seen: false })
      state.latestNews = handleUpdateObjectById(state.latestNews, newData)
      state.productSubscriptionEntries = handleUpdateObjectById(state.productSubscriptionEntries, newData)
    },
    ignoreEntries: (state, action: PayloadAction<any>) => {
      state.latestNews = _remove(state.latestNews, (item: any) => item.id !== _get(action.payload, 'id', ''))
      state.productSubscriptionEntries = _remove(
        state.productSubscriptionEntries,
        (item: any) => item.id !== _get(action.payload, 'id', '')
      )
    },
    addDismissedBanners: (state, action: PayloadAction<any>) => {
      state.dismissedBanners = [...state.dismissedBanners, action.payload]
    }
  }
})

export const {
  saveProducts,
  saveInterestedProducts,
  addInterestedProducts,
  removeInterestedProducts,
  saveLatestNews,
  saveProductSubscriptionEntries,
  addSeenEntries,
  removeSeenEntries,
  ignoreEntries,
  addDismissedBanners
} = home.actions

export default home.reducer

export const selectProducts = (state: RootState) => state.home.products
export const selectInterestedProducts = (state: RootState) => state.home.interestedProducts
export const selectLatestNews = (state: RootState) => state.home.latestNews
export const selectProductSubscriptionEntries = (state: RootState) => state.home.productSubscriptionEntries
export const selectDismissedBanners = (state: RootState) => state.home.dismissedBanners
