import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { KEYS } from '@myopswat/common'
import { getCookie, getLocalStorage } from '@opswat/react-core'
import { PageLoading } from '@opswat/react-ui'

import { useLazySaml2LoginQuery } from 'myopswat-web/src/api/sso'
import imageBg from 'myopswat-web/src/assets/images/mfa-bg.png'
import { homePageURL } from 'myopswat-web/src/routes'

export default function LoadingContainer() {
  const navigate = useNavigate()

  const isToken = getCookie(KEYS.TOKEN_KEY) || getLocalStorage(KEYS.TOKEN_KEY)

  const [saml2Login] = useLazySaml2LoginQuery()

  useEffect(() => {
    if (isToken) {
      navigate(homePageURL)
    } else {
      saml2Login()
        .unwrap()
        .then((response: any) => {
          window.location.href = response.url
        })
    }
  }, [isToken, navigate])

  return <PageLoading background={imageBg} color="primary" />
}
