import { AllowIcon } from '@opswat/react-icon'
import { useContext } from 'react'

import { AcademyNoData, AcademySection, EnrollCourses } from '../../components'

import { AcademyContext } from '../../context'

const RecommendedCoursesSection = () => {
  const { academyProfile, ctaConfig } = useContext(AcademyContext)

  const { recommendCourses } = academyProfile
  const isEmptyData = recommendCourses.length === 0

  return (
    <AcademySection title="Recommended Courses">
      {isEmptyData ? (
        <AcademyNoData
          icon={<AllowIcon />}
          content={
            <>
              Congratulations on completing all our recommended courses!
              <br />
              You've achieved a great milestone. Looking for more learning opportunities? Explore our full catalog to
              discover other courses that can help you continue your journey of growth and learning.
            </>
          }
          buttonText={ctaConfig.recommended.label}
          buttonLink={ctaConfig.recommended.url}
        />
      ) : (
        <EnrollCourses courses={recommendCourses} />
      )}
    </AcademySection>
  )
}

export default RecommendedCoursesSection
