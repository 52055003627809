export enum PRODUCT_IDS {
  MD_DRIVE_TOOLKIT = '36fb37e5-bc6a-41a7-bce2-51abb2adbd10',
  MD_DRIVE = 'b071e5e5-461c-4350-869e-a2e1860ef31d',
  SECURE_STORAGE = '759e3868-1bcf-4a55-b051-964269c67dca',
  EMAIL_SECURITY = 'd7ce1505-7ae1-4006-af25-1e6608dabf33',
  EMAIL_SECURITY_STANDALONE = 'bea46752-937d-4699-9d47-d0bbd5c95a0a',
  EMAIL_SECURITY_BUNDLE = 'dd53b3ab-e840-494d-aece-a4b72b8e06b1',
  MD_ICAP = '3304921b-f8de-4c3c-a967-47a8864b8678',
  MD_CORE = 'a898c3fe-cbdf-4a00-a2d8-ced21edba261',
  MD_IT_ACCESS = '95e8f6e9-80de-4ead-85a0-b6f1cdaed099',
  MD_CLOUD = '1502cf67-f9df-45b6-99f8-6d0afafda8b3',
  MD_INSIGHTS = '7107061b-c161-4dc8-b3b5-5ee0225ef27b',
  MD_NDR = '1b637be2-85e3-4c6d-954a-1b8bee8bf850',

  ENDPOINT_SECURITY_SDK = '4cdbfe63-bae7-4a3c-823f-d8308586c54a',
  DEVICE_COMPLIANCE = 'd6618281-c79d-46e3-8f78-e071643b3746',
  VULNERABILITY = '139da0b2-4824-4ce5-82fe-1f30ccdf32b8',
  PATCH_MANAGEMENT = '165de0ac-700f-4ed4-96ad-f7a48191c2f5',
  APPREMOVER_MODULE = 'abeea4c1-0c73-42e0-9528-3b90b2a78c35',
  ANTI_SCREEN_CAPTURE = 'da6710b6-a9c4-4280-8b3c-8e40c429f154',
  ANTI_KEYLOGGER = '499c6958-25e7-4e5f-ab5e-7c22cf9e08f4',
  REMOVABLE_MEDIA_PROTECTION = '64b4e68a-c7ae-4ea6-9c95-550840443512',
  ADVANCED_MALWARE_PROTECTION = '221cf881-63e6-4031-b249-b779662dd0fb',
  V3V4_ADAPTER = '69c74939-1c74-49ab-88ee-455df18915f5',
  VMOD_SOURCE = '03265a0b-345d-4955-bfc6-a44b60cc9697',

  OPSWAT_NETWALL = 'a2b5fd00-43fe-4196-831c-b8b9c93607fb',
  UNIDIRECTIONAL_SECURITY_BLUE_GATEWAY = '0545cc1b-9524-4d76-a131-51490009c731',
  UNIDIRECTIONAL_SECURITY_RED_GATEWAY = '130a505f-780d-4c8d-9879-6afc0f6df4a1',
  BILATERAL_SECURITY_BLUE_GATEWAY = '64580160-1c41-4a3d-89b1-5216348c14e0',
  BILATERAL_SECURITY_RED_GATEWAY = 'e7e95f40-b62f-48f9-aa19-b0331b30015e',
  THREAT_PROTECTION_BLUE = '3d1fe3b0-0c8c-48bc-8c28-717889614587',
  THREAT_PROTECTION_RED = '662204ea-e9f1-47e7-ba24-45acb249ca5f',
  OPTICAL_DIODE_BLUE = '779b53f5-8029-4041-b2f6-1a07e382af7b',
  OPTICAL_DIODE_RED = '9a8d7709-955a-4f64-8b5c-eb9a1f511d01',
  FEND_DATA_DIODES = 'fec2a468-6596-4a03-8cd0-c6c83d8d0a26',
  INQUEST_FILETAC = '1b637be2-85e3-4c6d-954a-1b8bee8bf851'
}

export const ENDPOINT_SECURITY_SDK_ORDER_ARRAY = [
  PRODUCT_IDS.DEVICE_COMPLIANCE,
  PRODUCT_IDS.VULNERABILITY,
  PRODUCT_IDS.PATCH_MANAGEMENT,
  PRODUCT_IDS.APPREMOVER_MODULE,
  PRODUCT_IDS.ANTI_SCREEN_CAPTURE,
  PRODUCT_IDS.ANTI_KEYLOGGER,
  PRODUCT_IDS.REMOVABLE_MEDIA_PROTECTION,
  PRODUCT_IDS.ADVANCED_MALWARE_PROTECTION,
  PRODUCT_IDS.V3V4_ADAPTER,
  PRODUCT_IDS.VMOD_SOURCE
]

export const OPSWAT_NETWALL_ORDER_ARRAY = [
  PRODUCT_IDS.UNIDIRECTIONAL_SECURITY_BLUE_GATEWAY,
  PRODUCT_IDS.UNIDIRECTIONAL_SECURITY_RED_GATEWAY,
  PRODUCT_IDS.BILATERAL_SECURITY_BLUE_GATEWAY,
  PRODUCT_IDS.BILATERAL_SECURITY_RED_GATEWAY,
  PRODUCT_IDS.THREAT_PROTECTION_BLUE,
  PRODUCT_IDS.THREAT_PROTECTION_RED,
  PRODUCT_IDS.OPTICAL_DIODE_BLUE,
  PRODUCT_IDS.OPTICAL_DIODE_RED
]

export const PRODUCT_LINKS: Record<string, string> = {
  [PRODUCT_IDS.FEND_DATA_DIODES]: 'https://app.fend.tech'
}

export const LEARN_MORE_PRODUCT_IDS = [PRODUCT_IDS.MD_INSIGHTS, PRODUCT_IDS.MD_NDR, PRODUCT_IDS.INQUEST_FILETAC]
