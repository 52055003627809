import { FC, useCallback, useEffect, useState } from 'react'

import _get from 'lodash/get'
import _includes from 'lodash/includes'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'

import { handleCheckLogin, handleForceLogout, KEYS } from '@myopswat/common'
import { getCookie, getLocalStorage } from '@opswat/react-core'
import { PageLoading } from '@opswat/react-ui'

import { useLazyProfileQuery } from 'myopswat-web/src/api/account'
import { CHAT_BOT_INIT_URL, CHAT_BOT_INJECT_URL_V2 } from 'myopswat-web/src/constants'
import { useGetChatbotUser } from 'myopswat-web/src/hooks/useGetChatbotUser'
import LayoutPage from 'myopswat-web/src/pages/LayoutPage'
import { mfaPageURL } from 'myopswat-web/src/routes'

import './chatbot.css'

const LayoutContainer: FC<unknown> = () => {
  const isInIframe = typeof window !== 'undefined' && window.self !== window.top

  const { t: translate } = useTranslation()
  const location = useLocation()
  const isToken = handleCheckLogin()

  const { enqueueSnackbar } = useSnackbar()

  const [isRender, setIsRender] = useState<boolean>(false)

  const [getProfile, { data: profileData }] = useLazyProfileQuery()

  const handleCheckNotLayout = () => {
    if (_includes(location.pathname, mfaPageURL)) return true
    return false
  }

  const initChatbot = useCallback((profile: any) => {
    // botpress init configuration script
    const initScript = document.createElement('script')
    initScript.src = CHAT_BOT_INIT_URL
    initScript.async = true
    document.body.appendChild(initScript)
    initScript.onload = async () => {
      let isProcessed = false
      while (!isProcessed) {
        if (window.botpress.initialized) {
          // update user data
          const userData = useGetChatbotUser(profile)
          await window.botpress.updateUser({
            data: userData
          })

          isProcessed = true
        }
      }
    }
  }, [])

  const loadChatbot = useCallback((profile: any) => {
    // botpress inject script
    const injectScript = document.createElement('script')
    injectScript.src = CHAT_BOT_INJECT_URL_V2
    injectScript.async = true
    document.body.appendChild(injectScript)
    injectScript.onload = () => {
      initChatbot(profile)
    }
  }, [])

  useEffect(() => {
    if (getCookie(KEYS.TOKEN_KEY) || getLocalStorage(KEYS.TOKEN_KEY)) {
      getProfile()
        ?.unwrap()
        .then(response => {
          setIsRender(true)

          if (_get(response, 'errors.0.message')) {
            enqueueSnackbar(_get(response, 'errors.0.message', translate('getProfileFail')), {
              variant: 'error'
            })
          } else {
            // Set user_id to Matomo
            window._paq.push(['setUserId', response.id])
          }
        })
        .catch(error => {
          console.error(error)
          setIsRender(true)
        })
    } else if (isInIframe && !getLocalStorage('directLogout')) {
      // post a message for the iframe parent to show the session expired dialog
      window.parent.postMessage({ event: KEYS.IFRAME_USER_SESSION_EXPIRED }, process.env.REACT_APP_FRONT_END_HOST ?? '')
    } else {
      handleForceLogout()
    }
  }, [isToken, isInIframe])

  useEffect(() => {
    if (profileData?.chatbotEnable && CHAT_BOT_INIT_URL && !window.botpress?.initialized) {
      loadChatbot(profileData)
    }
    if (window.botpress?.initialized) {
      window.botpress.close()
    }
  }, [window.location.pathname, profileData])

  if (isRender && handleCheckNotLayout()) return <Outlet />
  if (isRender)
    return (
      <>
        <LayoutPage />
      </>
    )
  return <PageLoading background="white" color="primary" />
}

export default LayoutContainer
