export const US_PLATINUM = 'US In-Country Platinum'

export enum SUPPORT_LEVEL {
  STANDARD = 'Standard',
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
  US_PLATINUM = 'US In-Country Platinum',
  EMERALD = 'Emerald'
}

export const PRODUCTS_OS_MAP = {
  'MetaDefender Core': ['Linux', 'Windows'],
  'MetaDefender ICAP Server': ['Linux', 'Windows'],
  'MetaDefender Kiosk': ['Linux', 'Windows'],
  'MetaDefender Storage Security': ['Linux', 'Windows'],
  'MetaDefender Endpoint Security SDK': ['Linux', 'Windows'],
  'MetaDefender IT-OT Access NAC': ['Linux', 'Windows']
}

export const SINGLE_PHONE_SUPPORT_MAP = {
  [SUPPORT_LEVEL.US_PLATINUM]: {
    label: 'US',
    value: '+1 855 677 9281'
  }
}

export const SEVERITY_KEYS = ['severity_1', 'severity_2', 'severity_3', 'severity_4']

export const REQUIRED_ETA_CONFIG_KEYS = ['opswat_support_plan_url', 'chatbot_efficiency_level']

export const OPEN_SUPPORT_PLAN_URL = 'https://www.opswat.com/support'

export const CHATBOT_EFFICIENCY_LEVEL = 'chat with OPSWAT AI Assistant to get a solution 93% faster!'
