import { getCookie, getLocalStorage } from '@opswat/react-core'
import React, { FC, useEffect, useMemo, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { DIALOGS, handleClearCookie, KEYS } from '@myopswat/common'
import { NotificationIcon } from '@opswat/react-icon'
import { Badge, Dropdown, IconButton, useMediaQuery } from '@opswat/react-ui'

import DialogRemovedFromOrganization from 'myopswat-web/src/components/Dialog/DialogRemovedFromOrganization'
import {
  NOTIFICATION_BROADCAST_GROUP,
  NotificationTopic,
  NotificationType
} from 'myopswat-web/src/constants/notification'
import { toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import NotificationDropdown from './NotificationDropdown'

enum INotificationType {
  messages = 'messages',
  releases = 'releases'
}

const NotificationRoot: FC<unknown> = () => {
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery('(max-width:1000px)')
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const accessToken =
    process.env.REACT_APP_TOKEN_STORAGE === KEYS.COOKIE ? getCookie(KEYS.TOKEN_KEY) : getLocalStorage(KEYS.TOKEN_KEY)

  console.log(_get(profileData, 'id'))

  const websocketUrl = useMemo(() => {
    if (_get(profileData, 'id'))
      return `${process.env.REACT_APP_WS_NOTIFICATION_ENDPOINT}/${_get(profileData, 'id')}?token=${accessToken}`
    return `${process.env.REACT_APP_WS_NOTIFICATION_ENDPOINT}/${NOTIFICATION_BROADCAST_GROUP}/`
  }, [profileData])

  const { lastJsonMessage: notificationWSLastJsonMessage, readyState: notificationWSReadyState } =
    useWebSocket(websocketUrl)

  const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(false)

  const [openNotificationPopup, setOpenNotificationPopup] = useState<boolean>(false)

  const AccessibleBadges = () => {
    return (
      <IconButton size="small">
        <Badge variant="dot" color="error" invisible={!hasNewNotifications}>
          <NotificationIcon />
        </Badge>
      </IconButton>
    )
  }

  useEffect(() => {
    if (
      notificationWSReadyState === ReadyState.OPEN &&
      _get(notificationWSLastJsonMessage, 'topic') === NotificationTopic.NOTIFICATION_MESSAGE
    ) {
      const messageType = _get(notificationWSLastJsonMessage, 'message.type')
      if (messageType === NotificationType.REMOVAL) {
        dispatch(
          toggleDialogs({
            [DIALOGS.REMOVE_ORGANIZATION]: true
          })
        )
        handleClearCookie()
      }
    }
  }, [notificationWSReadyState, notificationWSLastJsonMessage])

  useEffect(() => {
    setHasNewNotifications(!_isEmpty(notificationWSLastJsonMessage))
  }, [notificationWSLastJsonMessage])

  return (
    <>
      <DialogRemovedFromOrganization
        organizationName={_get(notificationWSLastJsonMessage, 'message.organizationName') || ''}
      />
      <Dropdown
        hasAction
        button={<AccessibleBadges />}
        content={<NotificationDropdown />}
        propsPaper={{
          sx: {
            width: isMobile ? '250px' : '650px'
          }
        }}
        position={isMobile ? 'bottom' : 'bottom-end'}
        onOpenClose={() => {
          setOpenNotificationPopup(!openNotificationPopup)
          setHasNewNotifications(false)
        }}
      />
    </>
  )
}

export default React.memo(NotificationRoot)
