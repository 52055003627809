import { useContext } from 'react'

import { Grid } from '@opswat/react-ui'

import { AcademyContext } from '../context'
import { Courses, EnterpriseSubscription, LevelUp, Statistic, Welcome } from './sections'

const AcademyGeneralView = () => {
  const { academyProfile, ctaConfig } = useContext(AcademyContext)
  const { academyAnalytics, recommendCourses } = academyProfile

  return (
    <Grid container item xs={12} rowSpacing={2.5}>
      <Grid item xs={12}>
        <Welcome url={ctaConfig.learning.url} label={ctaConfig.learning.label} />
      </Grid>
      <Grid item xs={12}>
        <LevelUp />
      </Grid>
      <Grid item xs={12}>
        <EnterpriseSubscription />
      </Grid>
      <Grid item xs={12}>
        <Statistic analytics={academyAnalytics} />
      </Grid>
      <Grid item xs={12}>
        <Courses courses={recommendCourses} />
      </Grid>
    </Grid>
  )
}

export default AcademyGeneralView
