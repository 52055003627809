import { FC, useEffect } from 'react'

import _get from 'lodash/get'

import { DIALOGS, handleClearCookie } from '@myopswat/common'
import { DialogAdvanced } from '@opswat/react-ui'

import { useLazySaml2LoginQuery } from 'myopswat-web/src/api/sso'
import { selectDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useTypedSelector } from 'myopswat-web/src/store'

import SessionTimeoutForm from '../Form/SessionTimeoutForm'
import { getLocalStorage } from '@opswat/react-core'

const DialogSessionTimeout: FC<unknown> = () => {
  const dialogType = useTypedSelector(selectDialogs)
  const directLogout = getLocalStorage('directLogout')

  const [saml2Login] = useLazySaml2LoginQuery()

  const openDialog = _get(dialogType, DIALOGS.SESSION_TIMEOUT, false)

  const handleLoginSSO = () => {
    handleClearCookie()
    saml2Login()
      .unwrap()
      .then((response: any) => {
        window.location.href = response.url
      })
  }

  useEffect(() => {
    if (openDialog && directLogout === "true") {
      handleLoginSSO()
    }
  }, [directLogout, openDialog])

  return (
    <DialogAdvanced
      open={openDialog && !directLogout}
      disableBackdropClick={false}
      content={<SessionTimeoutForm />}
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogSessionTimeout
