import { Grid, Typography } from '@mui/material'
import { formatDatetime } from '@opswat/react-core'
import { OptionIcon } from '@opswat/react-icon'
import { Dropdown, IconButton, List, ListItem, ListItemButton, TypographyLineClamp } from '@opswat/react-ui'
import _get from 'lodash/get'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import useHasPermissions from '../../../../../hooks/useHasPermissions'
import { myOrganizationUsersPageURL } from '../../../../../routes'
import { RoleActionProps } from './types'

interface RoleRowProps extends RoleActionProps {
  role: any
  level: number
  organization: any
}

const RoleRow = ({ role, level, onDelete, onDetail, onEdit, permissions, organization }: RoleRowProps) => {
  const hasViewOrgRolePerm = useHasPermissions({
    targetPerms: ['view_my_organizations_role', 'full_my_organizations_role'],
    userPerms: permissions
  })
  const hasFullOrgRolePerm = useHasPermissions({ targetPerms: ['full_my_organizations_role'], userPerms: permissions })

  const navigate = useNavigate()
  const handleClickNumberOfUser = useCallback((id: string) => {
    navigate(myOrganizationUsersPageURL, {
      state: { portalRoles: [id], selectedOrgIds: [{ value: organization.id, label: organization.name }] }
    })
  }, [])

  return (
    <Grid
      container
      sx={{
        padding: '4px',
        alignItems: 'center',
        borderBottom: '1px solid #E9EAEB',
        minHeight: '40px'
      }}
    >
      <Grid item xs={2.5} sx={{ paddingLeft: `${level * 20 + 20}px` }}>
        <TypographyLineClamp
          line={1}
          variant="body2"
          tooltipValue={role.name}
          tooltipPlacement="top"
        >
          {role.name}
        </TypographyLineClamp>
      </Grid>
      <Grid item xs>
        <TypographyLineClamp
          line={4}
          variant="body2"
          tooltipValue={role.description}
          tooltipPlacement="top"
          style={{
            wordBreak: 'break-word'
          }}
        >
          {role.description}
        </TypographyLineClamp>
      </Grid>
      <Grid item xs={1.5} display="flex" justifyContent="center" alignItems="center">
        <Typography
          variant="body2"
          color={role.number_of_users > 0 ? 'primary' : 'textSecondary'}
          sx={{
            cursor: role.number_of_users > 0 ? 'pointer' : 'default',
            '&:hover': {
              textDecoration: 'none'
            }
          }}
          onClick={() => {
            if (role.number_of_users > 0) {
              handleClickNumberOfUser(role.role_id)
            }
          }}
        >
          {role.number_of_users > 0 ? role.number_of_users : '--'}
        </Typography>
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="left" alignItems="center">
        <TypographyLineClamp
          line={1}
          variant="body2"
          tooltipValue={role.updated_by}
          tooltipPlacement="top"
        >
          {role.updated_by}
        </TypographyLineClamp>
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="left" alignItems="center">
        <TypographyLineClamp
          line={1}
          variant="body2"
          tooltipValue={formatDatetime(_get(role, 'last_updated_at', ''))}
          tooltipPlacement="top"
        >
          {formatDatetime(_get(role, 'last_updated_at', ''))}
        </TypographyLineClamp>
      </Grid>
      <Grid item xs={0.5} display="flex" justifyContent="flex-end">
        <Dropdown
          button={
            <IconButton>
              <OptionIcon />
            </IconButton>
          }
          content={
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => onDetail(role.role_id, organization)} disabled={!hasViewOrgRolePerm}>
                  Detail
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  disabled={role.is_default || !hasFullOrgRolePerm || level !== 0}
                  onClick={() => onEdit(role.role_id)}
                >
                  Edit
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  disabled={role.is_default || !hasFullOrgRolePerm || level !== 0}
                  onClick={() =>
                    onDelete({
                      id: role.role_id,
                      name: role.name,
                      numberOfUsers: role.number_of_users
                    })
                  }
                >
                  Delete
                </ListItemButton>
              </ListItem>
            </List>
          }
        />
      </Grid>
    </Grid>
  )
}

export default RoleRow
