import { useContext } from 'react'

import { FastBenefitsIcon } from '@opswat/react-icon'

import { AcademyCompletedCourse, AcademyNoData, AcademySection, AcademySwiper } from '../../components'
import { AcademyContext } from '../../context'

const CompletedCoursesSection = () => {
  const { academyProfile, ctaConfig } = useContext(AcademyContext)
  const { completedCourses } = academyProfile
  const isEmptyData = completedCourses.length === 0

  return (
    <AcademySection title="Completed Courses">
      {isEmptyData ? (
        <AcademyNoData
          icon={<FastBenefitsIcon size={30} color="#707682" />}
          title={'Your Cybersecurity Journey Awaits Completion!'}
          content={
            <>
              Your training is still active, and every lesson brings you closer to expertise. Pick up where you left off
              and ensure you’re prepared to tackle cybersecurity challenges with confidence!
            </>
          }
          buttonText={ctaConfig.completed.label}
          buttonLink={ctaConfig.completed.url}
        />
      ) : (
        <AcademySwiper slidesToShow={3} data={completedCourses} component={AcademyCompletedCourse} />
      )}
    </AcademySection>
  )
}

export default CompletedCoursesSection
