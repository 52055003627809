import { Grid } from '@opswat/react-ui'
import { AcademySection } from '.'

interface BaseLearningProgressProps {
  children: React.ReactNode
}

const BaseLearningProgress = ({ children }: BaseLearningProgressProps) => {
  return (
    <AcademySection title="Learning Progress">
      <Grid
        container
        sx={{
          backgroundColor: '#EFF4FF',
          padding: {
            xs: '8px 12px',
            sm: '12px  16px',
            lg: '30px 40px'
          }
        }}
      >
        {children}
      </Grid>
    </AcademySection>
  )
}

export default BaseLearningProgress
