import _get from 'lodash/get'

import { Link } from 'react-router-dom'

import { NotificationPositiveIcon } from '@opswat/react-icon'
import { Box, ListItem, Typography, TypographyLineClamp, useMediaQuery, useTheme } from '@opswat/react-ui'

interface IProps {
  propsData: any
}

const AcademyCompletedCourse = ({ propsData }: IProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <ListItem
      component={Link}
      to={_get(propsData, 'courseUrl')}
      target="_blank"
      sx={{
        width: '100%',
        height: '100%',
        transition: 'transform 0.75s',
        padding: {
          xs: '8px 16px',
          sm: '10px 20px'
        },
        background: '#F4F4F5',
        boxShadow: '0px 2px 8px 0px rgba(10, 36, 86, 0.15)',
        display: 'flex',
        alignItems: 'center',
        gap: '20px'
      }}
    >
      <Box
        component="img"
        src={_get(propsData, 'courseIconBadgeUrl')}
        alt="academy course badge"
        sx={{
          width: { xs: '96px', sm: '106px' },
          height: { xs: '96px', sm: '106px' },
          objectFit: 'contain'
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '8px',
          height: '100%'
        }}
      >
        {isMobile ? (
          <Typography
            variant="h3"
            lineHeight="20px"
            color="#141E2F"
            sx={{
              minHeight: '40px',
              wordBreak: 'keep-all'
            }}
          >
            {_get(propsData, 'courseName')}
          </Typography>
        ) : (
          <TypographyLineClamp
            line={2}
            tooltipValue={_get(propsData, 'courseName')}
            tooltipPlacement="top"
            color="#141E2F"
            style={{
              minHeight: '40px',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '20px',
              wordBreak: 'keep-all'
            }}
          >
            {_get(propsData, 'courseName')}
          </TypographyLineClamp>
        )}

        <Typography variant="subtitle2" color="#485161">
          Expiry Date: {_get(propsData, 'expiresAt')}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            alignItems: 'center'
          }}
        >
          <NotificationPositiveIcon size={16} />
          <Typography variant="subtitle2" color="#485161">
            Completed
          </Typography>
        </Box>
        <Box sx={{ height: '4px', background: '#1D6BFC', width: '100%' }} />
      </Box>
    </ListItem>
  )
}

export default AcademyCompletedCourse
