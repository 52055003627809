import _get from 'lodash/get'

import { DIALOGS } from '@myopswat/common'
import { Box, Button, MessageDialog, Typography } from '@opswat/react-ui'
import { selectDialogs, toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { loginPageURL } from 'myopswat-web/src/routes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import { useNavigate } from 'react-router-dom'

interface IProps {
  organizationName: string
}

const DialogRemovedFromOrganization = ({ organizationName }: IProps) => {
  const dialogType = useTypedSelector(selectDialogs)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.REMOVE_ORGANIZATION]: false
      })
    )
    // Go to login page
    navigate(loginPageURL)
  }

  return (
    <MessageDialog
      maxWidth={false}
      minHeight={200}
      open={_get(dialogType, DIALOGS.REMOVE_ORGANIZATION, false)}
      fullWidth={false}
      title={`Your account has been removed from ${organizationName}.`}
      content={
        <Typography sx={{ width: '100%' }}>
          Please log in again to continue working on My OPSWAT.
          <br />
          Support and additional information can be found on OPSWAT.com
        </Typography>
      }
      action={
        <Box sx={{ display: 'flex', gap: 1, width: '100%', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={onClose} color="primary">
            Go to Login page
          </Button>
        </Box>
      }
    />
  )
}

export default DialogRemovedFromOrganization
