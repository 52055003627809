import React, { FC, useCallback, useEffect, useState } from 'react'

import _map from 'lodash/map'
import _range from 'lodash/range'

import _parseISO from 'date-fns/parseISO'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { CloseIcon } from '@opswat/react-icon'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'

import {
  useLazyNotificationMessagesQuery,
  useMultipleNotificationsDismissMutation,
  useMultipleNotificationsReadMutation,
  useNotificationDismissMutation
} from 'myopswat-web/src/api/notification'
import NotificationSkeleton from 'myopswat-web/src/components/Skeleton/NotificationSkeleton'

interface INotificationMessage {
  id: string
  type: string
  content: string
  dismissed: boolean
  createdAt: string
  read: boolean
}

const NotificationDirect: FC<any> = () => {
  const loadingNotificationArray = _range(3)

  const [currentPage, setCurrentPage] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [notificationMessageList, setNotificationMessageList] = useState<INotificationMessage[]>([])
  const [dismissNotification] = useNotificationDismissMutation()
  const [dismissMultipleNotifications] = useMultipleNotificationsDismissMutation()
  const [readMultipleNotifications] = useMultipleNotificationsReadMutation()
  const [getNotificationMessages, { isFetching: isNotificationMessagesLoading }] = useLazyNotificationMessagesQuery()

  const handleDismissNotification = useCallback(
    async (notificationMessage: INotificationMessage) => {
      dismissNotification({ notificationId: notificationMessage?.id }).then(async () => {
        await handleGetNotifications(true)
      })
    },
    [notificationMessageList]
  )

  const handleDismissAll = useCallback(async () => {
    const notificationIds = _map(notificationMessageList, (item: INotificationMessage) => item.id)
    await dismissMultipleNotifications({ notificationIds }).then(async () => {
      await handleGetNotifications(true)
    })
  }, [notificationMessageList])

  const handleReadMultiple = useCallback((data: INotificationMessage[]) => {
    const notificationIds = _map(data, (item: INotificationMessage) => item.id)
    readMultipleNotifications({ notificationIds })
  }, [])

  const handleGetNotifications = useCallback(
    async (isReset?: boolean) => {
      await getNotificationMessages({ page: isReset ? 0 : currentPage, pageSize: 5 })
        .unwrap()
        .then((response: any) => {
          setNotificationMessageList(prev => (isReset ? response.results : [...prev, ...response.results]))
          setTotalCount(response.totalCount)
          handleReadMultiple(response.results)
        })
        .catch(error => {
          console.error(error)
        })
    },
    [currentPage]
  )

  useEffect(() => {
    handleGetNotifications()
  }, [currentPage])

  return (
    <Box sx={{ marginTop: '-18px' }}>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {notificationMessageList.length > 0 && (
          <Button onClick={handleDismissAll} disabled={notificationMessageList.length === 0}>
            <Typography variant="subtitle2" color="textSecondary">
              Dismiss
            </Typography>
          </Button>
        )}
      </Box>

      <Divider sx={{ width: '100%', margin: '0 auto' }} />

      <Box
        sx={{ maxHeight: '50vh', minHeight: notificationMessageList.length <= 5 ? 'auto' : '50vh', overflow: 'auto' }}
      >
        {notificationMessageList.length === 0 &&
          (isNotificationMessagesLoading ? (
            _map(loadingNotificationArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <ListItem>
                  <NotificationSkeleton />
                </ListItem>
                <Divider sx={{ width: '90%', margin: '0 auto' }} />
              </React.Fragment>
            ))
          ) : (
            <Box p={2}>
              <Typography variant="subtitle2" color="textSecondary">
                No notifications.
              </Typography>
            </Box>
          ))}
        {_map(notificationMessageList, (notificationMessage: INotificationMessage) => (
          <ListItem disablePadding key={notificationMessage?.id?.toString()}>
            <ListItemButton disableGutters sx={{ px: 2, py: 1.5, cursor: 'default' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle1" fontWeight={notificationMessage.read ? 500 : 700} color="#1B273C">
                      {notificationMessage?.type}
                    </Typography>
                    <Box>
                      {!notificationMessage.read && (
                        <svg height="8" width="8" xmlns="http://www.w3.org/2000/svg">
                          <circle r="4" cx="4" cy="4" fill="#1D6BFC" />
                        </svg>
                      )}
                      <IconButton
                        size="small"
                        onClick={() => handleDismissNotification(notificationMessage)}
                        sx={{ paddingY: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TypographyLineClamp line={2} variant="subtitle2" fontWeight={notificationMessage.read ? 400 : 500}>
                    {notificationMessage?.content}
                  </TypographyLineClamp>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary" fontWeight={notificationMessage.read ? 400 : 500}>
                    {formatDatetime(_parseISO(notificationMessage?.createdAt), DATE_FORMATS.DATE_TIME)}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemButton>
          </ListItem>
        ))}
      </Box>

      {notificationMessageList.length > 0 && notificationMessageList.length < totalCount && (
        <ListItemButton
          disableGutters
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
            borderTop: '1px solid #e7e9ee',
            position: 'sticky',
            bottom: 0,
            p: 1,
            gap: 1
          }}
          onClick={() => {
            setCurrentPage(currentPage + 1)
          }}
        >
          <Typography variant="subtitle2" color="textSecondary" textTransform="none">
            Show more
          </Typography>
        </ListItemButton>
      )}
    </Box>
  )
}

export default React.memo(NotificationDirect)
