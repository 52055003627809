import { KEYS } from '@myopswat/common'
import { useEffect, useRef } from 'react'

const useSyncIframeRoute = () => {
  const lastUrlRef = useRef<string>(window.location.href)

  useEffect(() => {
    const postRoute = () => {
      const currentUrl = window.location.href
      if (currentUrl !== lastUrlRef.current) {
        lastUrlRef.current = currentUrl
        window.parent.postMessage(
          { event: KEYS.IFRAME_UPDATE_ROUTE, iframeId: 'MY_OPSWAT', fullUrl: currentUrl },
          process.env.REACT_APP_FRONT_END_HOST ?? ''
        )
      }
    }

    const observer = new MutationObserver(postRoute)
    const config = { subtree: true, childList: true }

    observer.observe(document.body, config)

    const interval = setInterval(postRoute, 100)

    window.addEventListener('popstate', postRoute)
    window.addEventListener('hashchange', postRoute)

    postRoute()

    return () => {
      observer.disconnect()
      clearInterval(interval)
      window.removeEventListener('popstate', postRoute)
      window.removeEventListener('hashchange', postRoute)
    }
  }, [])
}

export default useSyncIframeRoute
