import React, { FC } from 'react'
import { Box, TableCell, Tooltip, Typography, TypographyLink } from '@opswat/react-ui'
import _get from 'lodash/get'

interface DownloadCountCellProps {
  data: any
  onSelect: () => void
}

export const DownloadCountCell: FC<DownloadCountCellProps> = ({ data, onSelect }) => (
  <TableCell sx={{ p: 0.25, width: 150, textAlign: 'center' }}>
    {!_get(data, 'downloadedCount', 0) ? (
      <Typography variant="body2">{_get(data, 'downloadedCount', 0) || '--'}</Typography>
    ) : (
      <Tooltip title="Click to see Download History">
        <Box component="span">
          <TypographyLink
            variant="body2"
            color="primary"
            sx={{
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
              }
            }}
            onClick={onSelect}
          >
            {_get(data, 'downloadedCount', 0)}
          </TypographyLink>
        </Box>
      </Tooltip>
    )}
  </TableCell>
)
