import React, { FC, useEffect, useMemo } from 'react'

import _get from 'lodash/get'

import { Badge, Box, Divider, TabAdvanced, Typography } from '@opswat/react-ui'

import { useLazyCheckAllNotificationsQuery } from 'myopswat-web/src/api/notification'

import NotificationDirect from './NotificationDirect'
import NotificationProduct from './NotificationProduct'

enum NotificationType {
  messages = 'messages',
  releases = 'releases'
}

const NotificationDropdown: FC<unknown> = () => {
  const [checkAllNewNotification, { data: checkAllNewNotificationData }] = useLazyCheckAllNotificationsQuery()

  const hasNewMessageNotifications = useMemo(() => {
    return _get(checkAllNewNotificationData, NotificationType.messages)
  }, [checkAllNewNotificationData])

  const hasNewReleaseNotifications = useMemo(() => {
    return _get(checkAllNewNotificationData, NotificationType.releases)
  }, [checkAllNewNotificationData])

  const tabArray = [
    {
      label: hasNewReleaseNotifications ? (
        <Badge variant="dot" color="error" invisible={!hasNewMessageNotifications}>
          <Typography sx={{ paddingRight: 'calc(1ch / 2)' }}>Direct</Typography>
        </Badge>
      ) : (
        <Typography>Direct</Typography>
      ),
      content: <NotificationDirect checkNewNotification={checkAllNewNotification} />
    },

    {
      label: hasNewReleaseNotifications ? (
        <Badge variant="dot" color="error" invisible={!hasNewReleaseNotifications}>
          <Typography sx={{ paddingRight: 'calc(1ch / 2)' }}>Product Releases</Typography>
        </Badge>
      ) : (
        <Typography>Product Releases</Typography>
      ),
      content: <NotificationProduct />
    }
  ]

  useEffect(() => {
    checkAllNewNotification()
  }, [])

  return (
    <Box>
      <Typography sx={{ py: 1, px: 2 }}>Notifications</Typography>
      <Divider />
      <TabAdvanced tabs={tabArray} />
    </Box>
  )
}

export default React.memo(NotificationDropdown)
