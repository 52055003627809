import { useCallback, useEffect, useRef, useState } from 'react'

import { CopySimpleIcon } from '@opswat/react-icon'
import { Box, Tooltip, Typography } from '@opswat/react-ui'

interface LicenseKeyCellProps {
  value: string
  width?: string
}

const LicenseKeyCell: React.FC<LicenseKeyCellProps> = ({ value, width = '8vw' }) => {
  const [copied, setCopied] = useState(false)
  const [isOverflowed, setIsOverflowed] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkOverflow = () => {
      const { current } = textRef
      if (current) {
        setIsOverflowed(current.scrollWidth > current.clientWidth)
      }
    }

    checkOverflow()

    const resizeObserver = new ResizeObserver(checkOverflow)
    if (textRef.current) {
      resizeObserver.observe(textRef.current)
    }

    return () => resizeObserver.disconnect()
  }, [value])

  const handleCopy = useCallback(() => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
      })
      .catch(err => {
        console.error('Failed to copy:', err)
      })
  }, [value])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minWidth: width,
        maxWidth: width,
        overflow: 'hidden'
      }}
    >
      {isOverflowed ? (
        <Tooltip title={value}>
          <Typography
            ref={textRef}
            variant="body2"
            sx={{
              maxWidth: 'calc(100% - 24px)',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'block'
            }}
          >
            {value}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          ref={textRef}
          variant="body2"
          sx={{
            maxWidth: 'calc(100% - 24px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block'
          }}
        >
          {value}
        </Typography>
      )}
      <Tooltip title={copied ? 'Copied' : 'Copy'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 16,
            marginLeft: 'auto',
            '&:hover': { cursor: 'pointer' }
          }}
          onClick={handleCopy}
        >
          <CopySimpleIcon size={16} color="#3D4A68" />
        </Box>
      </Tooltip>
    </Box>
  )
}

export default LicenseKeyCell
