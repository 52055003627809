import { useContext } from 'react'

import { useUserProfile } from 'myopswat-web/src/hooks'

import { EmptyLearningProgress, NonEmptyLearningProgress } from '../../components'
import { AcademyContext } from '../../context'

const LearningProgressSection = () => {
  const { academyProfile, ctaConfig } = useContext(AcademyContext)
  const { getProfileProperty } = useUserProfile()

  const { totalEnrolledCourses, totalCompletedCourses, totalAvailableCourses } = academyProfile

  const firstName = getProfileProperty('firstName', '')

  const isEmptyData = totalEnrolledCourses === 0 && totalCompletedCourses === 0

  if (isEmptyData) {
    return <EmptyLearningProgress firstName={firstName} />
  }

  const enrollmentStats = {
    enrolled: totalEnrolledCourses,
    notEnrolled: totalAvailableCourses - totalEnrolledCourses
  }

  const completionStats = {
    completed: totalCompletedCourses,
    notCompleted: totalEnrolledCourses - totalCompletedCourses
  }

  return (
    <NonEmptyLearningProgress
      firstName={firstName}
      ctaConfig={ctaConfig.learning}
      enrollmentStats={enrollmentStats}
      completionStats={completionStats}
      totalEnrolledCourses={totalEnrolledCourses}
      totalAvailableCourses={totalAvailableCourses}
      totalCompletedCourses={totalCompletedCourses}
    />
  )
}

export default LearningProgressSection
