import { Box, Paper, Skeleton, Typography, TypographyLineClamp } from '@opswat/react-ui'
import StackBarChart from 'myopswat-web/src/components/CustomCharts/StackBarChart'
import { FC, useEffect, useState } from 'react'
const DEFAULT_COLORS = ['#ED6706', '#7E32DD', '#B4307F', '#178594', '#15A87C']

interface IProps {
  data?: any[]
  isLoading: boolean
}

export const TopProduct: FC<IProps> = ({ data, isLoading }) => {
  const [highlightBarIndex, setHighlightBarIndex] = useState<number | undefined>(undefined)

  const renderChart = () => {
    if (isLoading)
      return <Skeleton animation="wave" variant="rectangular" width="100%" height={16} sx={{ marginBottom: 1 }} />
    if (!data || data.length === 0)
      return (
        <Box height={150} width="100%" display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body2">You haven’t opened any cases yet.</Typography>
        </Box>
      )
    return (
      <StackBarChart
        containerSx={{ maxWidth: 400 }}
        data={data}
        maxCategory={6}
        chartTitle={null}
        hint={' '}
        barHeight={30}
        colors={DEFAULT_COLORS}
        onRenderTooltip={() => undefined}
        highlightBarIndex={highlightBarIndex}
      />
    )
  }

  const handleMouseEnterLegend = (index: number) => {
    setHighlightBarIndex(index)
  }

  const handleMousemove = () => {
    // when move mouse very fast, sometimes, event onMouseLeave won't trigger
    // so we change to use mousemove event to clear
    // however, when we hover on the legends, both onMouseEnter and mousemove event will trigger, which will cause flickering color
    const hoverLegend = document.querySelector('.custom-legend:hover')
    if (!hoverLegend) setHighlightBarIndex(undefined)
  }

  useEffect(() => {
    window.addEventListener('mousemove', handleMousemove)
    const interval = setInterval(() => {
      handleMousemove()
      // just to be sure, we add this interval in case handleMousemove function is not trigger, 120ms is an acceptable time for human eye to react
    }, 120)
    return () => {
      window.removeEventListener('mousemove', handleMousemove)
      clearInterval(interval)
    }
  }, [])

  const renderLegends = () => {
    return (
      <Box>
        {Array.from(Array(5).keys()).map(v => {
          if (isLoading)
            return (
              <Skeleton
                key={crypto.randomUUID()}
                animation="wave"
                variant="rectangular"
                width="100%"
                height={12}
                sx={{ marginBottom: 1 }}
              />
            )
          if (!data || data.length === 0) return <></>
          if (data[v]) {
            return (
              <Box
                className="custom-legend"
                key={crypto.randomUUID()}
                display="flex"
                alignItems="center"
                onMouseEnter={() => handleMouseEnterLegend(v)}
              >
                <Box display="flex" flex={9} alignItems="center">
                  <Box paddingRight={1}>
                    <Box sx={{ background: DEFAULT_COLORS[v], borderRadius: '50%', height: 12, width: 12 }}></Box>
                  </Box>
                  <TypographyLineClamp line={1} tooltipValue={data[v]?.name} fontSize={12}>
                    {data[v].name}
                  </TypographyLineClamp>
                </Box>
                <Box fontSize={12} flex={3} display="flex" justifyContent="end">
                  {data[v].count}
                </Box>
              </Box>
            )
          } else {
            return <></>
          }
        })}
      </Box>
    )
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: '16px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          height: 66
        }}
      >
        <Typography variant="h3" color="#242424" fontSize="16px">
          Top Product by Ticket
        </Typography>
      </Box>
      <Typography variant="caption" color="#1B273C">
        Across all organizations last 12M
      </Typography>
      <Box sx={{ marginTop: '16px' }}>
        {renderChart()}
        {renderLegends()}
      </Box>
    </Paper>
  )
}
