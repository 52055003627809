import { ReliableBenefitsIcon } from '@opswat/react-icon'
import { AcademyNoData } from '.'
import { AcademyCTA } from '../context'

interface EmptyDataComponentProps {
  totalCompletedCourses: number
  totalEnrolledCourses: number
  ctaConfigs: AcademyCTA
}

const EmptyDataComponent = ({ totalCompletedCourses, totalEnrolledCourses, ctaConfigs }: EmptyDataComponentProps) => {
  const isCompleted = totalCompletedCourses === totalEnrolledCourses && totalEnrolledCourses > 0

  const content = isCompleted ? (
    <>
      All Courses Completed - What's Next?
      <br />
      You've accomplished a great feat by completing all your courses! Keep the momentum going by delving into more
      specialized areas or refreshing your existing knowledge with updated content.
    </>
  ) : (
    <>
      Oops! It looks like you haven't started your cybersecurity training yet!
      <br />
      Take the first step toward mastering cybersecurity and securing your digital future. Our expert-led courses at
      OPSWAT Academy are designed to equip you with the skills you need.
    </>
  )

  const buttonText = isCompleted ? ctaConfigs.completeOngoing.label : ctaConfigs.ongoing.label
  const buttonLink = isCompleted ? ctaConfigs.completeOngoing.url : ctaConfigs.ongoing.url

  return (
    <AcademyNoData
      icon={<ReliableBenefitsIcon size={30} color="#707682" />}
      content={content}
      buttonText={buttonText}
      buttonLink={buttonLink}
    />
  )
}

export default EmptyDataComponent
