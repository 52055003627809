export function redirectToIframePortal(redirectPathname?: boolean) {
  const params = new URLSearchParams()

  if (redirectPathname) {
    const url = new URL(window.location.href)
    const currentParams = new URLSearchParams(url.search)
    const redirectPath = window.location.pathname
    const redirectParams = currentParams.toString() ? `?${currentParams.toString()}` : ''
    params.set('redirect', `${redirectPath}${redirectParams}`)
  }

  const redirectQuery = redirectPathname ? `?${params.toString()}` : ''
  window.location.replace(`${process.env.REACT_APP_FRONT_END_HOST}/portal/home/${redirectQuery}`)
}
