import { FC, useCallback } from 'react'
import {
  Box,
  Chip,
  DialogAdvanced,
  Grid, OpswatCheckbox,
  TextField, TextFieldArea,
  TextGrid,
  Tooltip,
  Typography,
} from '@opswat/react-ui'
import { formatDatetime, humanFileSize } from '@opswat/react-core'
import { handleRenderStatus } from '../../../ReportFalseDetectionPage/utils'
import { FalseSubmissionCategoryEnum, FalseSubmissionFileOrigins } from '../../../../api/analyzer/falseSubmission/types'
import { DATE_FORMATS } from '@myopswat/common'
import { useTypedSelector } from '../../../../store'
import { useFalseSubmissionDetailQuery } from '../../../../api/analyzer/falseSubmission'

interface IProps {
  submissionId: string;
  openDialog: boolean
  onClose: () => void
}

const DialogFalseSubmissionDetails: FC<IProps> = ({ submissionId, openDialog, onClose}) => {
  const profileData: any = useTypedSelector((state: any) => state?.api?.queries?.['profile(undefined)']?.data)
  const { data: falseDetectionSubmission, isFetching } = useFalseSubmissionDetailQuery(submissionId, {
    refetchOnMountOrArgChange: true
  })

  const fileOrigin =
    FalseSubmissionFileOrigins.find(item => item.value === falseDetectionSubmission?.fileOrigin) || null
  const handleRenderFalseDetectionDetail = useCallback(() => {
    return (
      <>
        {falseDetectionSubmission && profileData && !isFetching && (
          <Grid container sx={{ padding: '25px', flex: 1 }} >
            <Grid xs={12} sx={{ paddingBottom: '20px' , pl:'12px'}}>
              <Typography fontSize={16}>False Detection Submission {falseDetectionSubmission.inputType === 'file'
                ? falseDetectionSubmission.filename
                : falseDetectionSubmission.hash}</Typography>
            </Grid>
            <Grid xs={12} >
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, ml:2 }}>
                <TextGrid
                  label={'File'}
                  value={
                    <>
                      <Typography
                        sx={{
                          overflowWrap: 'anywhere'
                        }}
                        variant="body2"
                      >
                        {falseDetectionSubmission.inputType === 'file'
                          ? falseDetectionSubmission.filename
                          : falseDetectionSubmission.hash}
                      </Typography>
                      {falseDetectionSubmission.inputType === 'file' && (
                        <>
                          <Chip label="File" variant="outlined" size="small" sx={{ mt: 0.5 }} />
                          <Chip
                            label={humanFileSize(falseDetectionSubmission.size)}
                            variant="outlined"
                            color="default"
                            size="small"
                            sx={{ mt: 0.5, ml: 1 }}
                          />
                        </>
                      )}
                      {falseDetectionSubmission.inputType === 'hash' && (
                        <Chip label="Hash" variant="outlined" color="error" size="small" sx={{ mt: 0.5 }} />
                      )}
                    </>
                  }
                />

                <TextGrid
                  label={'Status'}
                  valueColProps={{ display: 'flex', justifyContent: 'start' }}
                  value={handleRenderStatus(falseDetectionSubmission)}
                />

                <TextGrid
                  label={'Category'}
                  value={
                    <TextField
                      disabled
                      size="small"
                      fullWidth
                      value={
                        FalseSubmissionCategoryEnum[
                          falseDetectionSubmission.category as keyof typeof FalseSubmissionCategoryEnum
                          ]
                      }
                    />
                  }
                />

                <TextGrid
                  label={'Hash MD5'}
                  value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission.md5} />}
                />

                <TextGrid
                  label={'Hash SHA1'}
                  value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission.sha1} />}
                />

                <TextGrid
                  label={'Hash SHA256'}
                  value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission.sha256} />}
                />

                <TextGrid
                  label={'Products'}
                  value={
                    <Tooltip
                      title={
                        falseDetectionSubmission.productNames ? falseDetectionSubmission.productNames.join(', \n') : ''
                      }
                    >
                      <TextField
                        disabled
                        size="small"
                        fullWidth
                        value={
                          falseDetectionSubmission.productNames ? falseDetectionSubmission.productNames.join(', ') : ''
                        }
                      />
                    </Tooltip>
                  }
                />

                <TextGrid
                  label={'Antivirus engines'}
                  value={
                    <Tooltip title={falseDetectionSubmission.antivirusEngine}>
                      <TextField disabled size="small" fullWidth value={falseDetectionSubmission.antivirusEngine} />
                    </Tooltip>
                  }
                />

                <TextGrid
                  label={'AV detection/Threat name'}
                  value={<TextField disabled fullWidth size="small" value={falseDetectionSubmission.detectionName} />}
                />

                <TextGrid
                  label={'Image of Detection/Report Alert'}
                  value={
                    <Box sx={{ display: 'flex', fontSize: '14px' }}>
                      {falseDetectionSubmission.submissionScreenshotName ? (
                        <Typography>
                          <a target="_blank" href={falseDetectionSubmission.submissionScreenshotUrl}>
                            {falseDetectionSubmission.submissionScreenshotName}
                          </a>
                        </Typography>
                      ) : (
                        <Typography>{'--'}</Typography>
                      )}
                    </Box>
                  }
                />

                <TextGrid
                  label={'The origin of the file'}
                  value={<TextField disabled fullWidth size="small" value={fileOrigin?.name} />}
                />

                {fileOrigin?.value === 'INTERNET_DOWNLOAD' && (
                  <TextGrid
                    label={'Link/Source name'}
                    value={
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        value={falseDetectionSubmission.fileOriginDetail || 'None'}
                      />
                    }
                  />
                )}

                <TextGrid
                  label={'The purpose of the submitted file'}
                  value={<TextFieldArea disabled fullWidth size="small" value={falseDetectionSubmission.purpose} />}
                />

                <TextGrid
                  label={'Why this is a false detection?'}
                  value={<TextFieldArea disabled fullWidth size="small" value={falseDetectionSubmission.note} />}
                />

                <TextGrid
                  label={'Agree to share the files with AV vendors for further analysis'}
                  value={
                    <Box sx={{ display: 'flex', fontSize: '16px' }}>
                      <OpswatCheckbox label="" disabled checked={falseDetectionSubmission.userConsent} />
                    </Box>
                  }
                />

                <TextGrid
                  label={'Analysis Summary'}
                  value={<TextFieldArea disabled fullWidth size="small" value={falseDetectionSubmission.analystNote} />}
                />

                <TextGrid
                  label={'Submitted At'}
                  value={
                    <Box sx={{ display: 'flex', fontSize: '16px' }}>
                      <Typography>
                        {formatDatetime(falseDetectionSubmission.createdAt, DATE_FORMATS.DATE_TIME, profileData.timezone)}
                      </Typography>
                    </Box>
                  }
                />

                <TextGrid
                  label={'Submitted By'}
                  value={
                    <Box sx={{ display: 'flex', fontSize: '16px' }}>
                      <Typography>{falseDetectionSubmission.createdByEmail}</Typography>
                    </Box>
                  }
                />

                <TextGrid
                  label={'Last Updated'}
                  value={
                    <Box sx={{ display: 'flex', fontSize: '16px' }}>
                      <Typography>
                        {formatDatetime(falseDetectionSubmission.updatedAt, DATE_FORMATS.DATE_TIME, profileData.timezone)}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    )
  }, [falseDetectionSubmission, profileData, isFetching])

  return (
    <DialogAdvanced
      fullDialog
      open={openDialog}
      onClose={onClose}
      content={handleRenderFalseDetectionDetail()}
      disableBackdropClick={false}
      dialogProps={{
        sx: {
          '& .MuiPaper-root': {
            height: 'auto',
            maxWidth: '1140px'
          },
          '& .MuiDialogContent-root': {
            padding: 0
          }
        }
      }}
    />
  )

}

export default DialogFalseSubmissionDetails;