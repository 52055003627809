import { Box, Grid, Typography } from '@opswat/react-ui'

import { BaseLearningProgress } from '.'

interface EmptyLearningProgressProps {
  firstName: string
}

const EmptyLearningProgress = ({ firstName }: EmptyLearningProgressProps) => {
  return (
    <BaseLearningProgress>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px'
        }}
      >
        <Box>
          <Typography
            color="#1B273C"
            sx={{
              fontSize: '20px',
              lineHeight: '24px',
              textAlign: 'center',
              fontWeight: 500
            }}
          >
            Welcome <span style={{ textTransform: 'capitalize' }}>{firstName}</span>
          </Typography>
        </Box>
      </Grid>
    </BaseLearningProgress>
  )
}

export default EmptyLearningProgress
