import { Box, Button, Grid, Typography } from '@opswat/react-ui'

import _template from 'lodash/template'

import { getSeriesPercentages } from 'myopswat-web/src/pages/LicensedProductsPage/CustomerLicensesPage/sections/ChartSection/utils'

import { AcademyDonutChart, BaseLearningProgress } from '.'
import { CTAConfigItem } from '../context'

interface EnrollmentStats {
  enrolled: number
  notEnrolled: number
}

interface CompletionStats {
  completed: number
  notCompleted: number
}

interface NonEmptyLearningProgressProps {
  firstName: string
  ctaConfig: CTAConfigItem
  enrollmentStats: EnrollmentStats
  completionStats: CompletionStats
  totalEnrolledCourses: number
  totalAvailableCourses: number
  totalCompletedCourses: number
}

const NonEmptyLearningProgress = ({
  firstName,
  ctaConfig,
  enrollmentStats,
  completionStats,
  totalEnrolledCourses,
  totalAvailableCourses,
  totalCompletedCourses
}: NonEmptyLearningProgressProps) => {
  const handleRenderDescription = (count: number, totalCount: number, description: string) => {
    const text = `${count}/${totalCount}`
    const template = _template(description)
    return template({ statistics: `<strong>${text}</strong>` })
  }

  return (
    <BaseLearningProgress>
      <Grid
        item
        container
        xs={12}
        sm={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          alignItems: {
            xs: 'center',
            sm: 'flex-start'
          },
          marginBottom: '30px'
        }}
      >
        <Box>
          <Typography
            color="#1B273C"
            sx={{
              fontSize: '14px',
              lineHeight: '20px',
              textAlign: {
                xs: 'center',
                sm: 'left'
              },
              fontWeight: 500
            }}
          >
            Welcome <span style={{ textTransform: 'capitalize' }}>{firstName}</span>
          </Typography>

          <Typography variant="body2" color="#485161">
            Tracking your learning progress
          </Typography>
        </Box>

        <Button
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            width: 'fit-content'
          }}
          href={ctaConfig.url}
        >
          {ctaConfig.label}
        </Button>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={9}
        gap={{
          xs: '8px',
          sm: '12px'
        }}
      >
        <Grid item xs>
          <AcademyDonutChart
            id="academy-enrollment-chart"
            title={'Course Enrollment'}
            labels={['Enrolled', 'Not Enrolled']}
            colors={['#1D6BFC', '#8E939B']}
            statistics={enrollmentStats}
            description={handleRenderDescription(
              totalEnrolledCourses,
              totalAvailableCourses,
              'You have enrolled in <%= statistics %> courses'
            )}
            centerTitle={`${getSeriesPercentages(Object.values(enrollmentStats), 0)[0]}%`}
            centerColor="#707682"
          />
        </Grid>

        <Grid item xs>
          <AcademyDonutChart
            id="academy-completion-chart"
            title={'Course Completion'}
            labels={['Completed', 'Not Completed']}
            colors={['#15a87c', '#8E939B']}
            statistics={completionStats}
            description={handleRenderDescription(
              totalCompletedCourses,
              totalEnrolledCourses,
              'You have completed <%= statistics %> courses'
            )}
            centerTitle={`${getSeriesPercentages(Object.values(completionStats), 0)[0]}%`}
            centerColor="#15a87c"
          />
        </Grid>
      </Grid>
    </BaseLearningProgress>
  )
}

export default NonEmptyLearningProgress
